import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SectionPref } from '../preference-card/section-pref';

@Component({
  selector: 'app-preference-list',
  templateUrl: './preference-list.component.html',
  styleUrls: ['./preference-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PreferenceListComponent implements OnChanges {

  @Input() sectionPrefs: SectionPref[] = [];
  @Output() prefChanged = new EventEmitter<SectionPref>();

  _sectionPrefs: SectionPref[] = [];

  constructor() { }
  
  ngOnChanges() {
    this._sectionPrefs = this.sectionPrefs.filter(sectionPref => !sectionPref.deletedAt);
  }

  prefClicked(e: Event, sectionPref: SectionPref) {
    (e.currentTarget as HTMLElement).blur();
    sectionPref.hasPref = !sectionPref.hasPref;
    this.prefChanged.emit(sectionPref);
  }

}
