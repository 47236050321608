// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.syzl-star-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
div.syzl-star-bar.horizontal {
  flex-direction: row;
}
div.syzl-star-bar.vertical {
  flex-direction: column;
}
div.syzl-star-bar.interactive app-syzl-star {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/syzl-star-bar/syzl-star-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;AACJ;AACI;EACI,mBAAA;AACR;AAEI;EACI,sBAAA;AAAR;AAIQ;EACI,eAAA;AAFZ","sourcesContent":["div.syzl-star-bar {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n\n    &.horizontal {\n        flex-direction: row;\n    }\n\n    &.vertical {\n        flex-direction: column;\n    }\n\n    &.interactive {\n        & app-syzl-star {\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
