import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * SyzlIconService
 * - Reads and stores the icons.json file
 * - Gets icons in the Export folder based on their name / type / number
 */
@Injectable({
  providedIn: 'root',
})
export class SyzlIconService {

  static readonly NO_NUMBER  = 999999;
  static readonly TYPE_COLOR = 'color';
  static readonly TYPE_LINE  = 'line';

  /**
   * The contents of the icons.json file
   */
  private _iconRepository: any = {};
  /**
   * A behaviour subject to emit a true event when the json file is loaded
   */
  private _ready = new BehaviorSubject<boolean>(false);

  constructor(
    private _http: HttpClient,
  ) {
    // Load the JSON file
    this._http
      .get('./assets/syzl-icons/icons.json')
      .subscribe({
        next: (data) => {
          this._iconRepository = data;
          this._ready.next(true);
        },
      });
  }

  /**
   * Getter for the _ready BehaviourSubject so the Directive knows when it has been loaded
   */
  get ready() {
    return this._ready;
  }

  /**
   * Returns the icon path and alt text based on the name / type / number
   * @param baseName
   * @param type
   * @param num
   * @returns
   */
  getIcon(baseName: string, type: string, num: number = SyzlIconService.NO_NUMBER) {
    try{
      const base  = this._iconRepository[baseName];
      const items = base[type];
      const entry = this._getEntry(items, num);
  
      const src = './assets/syzl-icons/' + entry.entryName;
      const alt = 'Syzl icon - ' + entry.name;
      return {
        src,
        alt,
      };
    } catch (error) {
      console.error(error);
    }
    return { src: '', alt: '' };
  }

  /**
   * Returns the matching entry object from the JSON file
   * @param items 
   * @param num 
   * @returns 
   */
  private _getEntry(items: any[], num: number) {
    if (num === SyzlIconService.NO_NUMBER || items.length === 1) {
      return items[0].entry;
    } else {
      for (const item of items) {
        if (item.number === num) {
          return item.entry;
        }
      }
    }
  }
}
