import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SyzlLogoComponent } from '../syzl-logo/syzl-logo.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    SyzlLogoComponent,
  ],
  selector: 'app-placeholder',
  template: `
    <ion-content [scrollY]="false">
      <div class="h-full flex flex-col justify-center items-center text-center">
        <app-syzl-logo logoType="color" class="w-36 p-2 m-2"></app-syzl-logo>
        <ion-text class="text-lg">
          <p>Our app is currently under maintenance.</p>
          <p>Please come by again later. Thank you!</p>
        </ion-text>
      </div>
    </ion-content>
  `,
})
export class PlaceholderPage {
  constructor() { }
}
