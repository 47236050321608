import {
  Injectable,
  ApplicationRef,
  ComponentRef,
  ElementRef,
  Injector,
  createComponent,
} from '@angular/core';

import { LightboxComponent } from '../components/lightbox/lightbox.component';

@Injectable({ providedIn: 'root' })
export class LightboxService {
  private _lightboxElement: ElementRef;
  private _lightboxRef: ComponentRef<LightboxComponent>;

  constructor(
    private _applicationRef: ApplicationRef,
    private _injector: Injector,
  ) {
    // Use createComponent utility function instead
    this._lightboxRef = createComponent(LightboxComponent, {
      environmentInjector: this._applicationRef.injector,
      elementInjector: this._injector,
    });
    
    this._lightboxElement = this._lightboxRef.instance.element;
    this._applicationRef.attachView(this._lightboxRef.hostView);
    document.body.appendChild(this._lightboxRef.location.nativeElement);
  }

  getLightboxElement(): HTMLElement {
    return this._lightboxElement.nativeElement as HTMLElement;
  }
}