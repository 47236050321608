import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatusPill } from '../../components-standalone/booking-preview-card/booking-preview.model';

@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StatusPillComponent {
  @Input() statusPill!: StatusPill;
}
