import { Component, OnInit, Input, Injector } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IModalButton } from 'src/app/modals/i-modal-button';
import { BasePageModal } from '../base-page-modal/base-page-modal';
import { ModalStatics } from 'src/app/services/modal.service';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.page.html',
  styleUrls: ['./content-modal.page.scss'],
  standalone: false,
})
export class ContentModalPage extends BasePageModal implements OnInit {

  @Input() content!: string;
  @Input() button!: IModalButton;

  public safeContent: SafeHtml | null = null;

  constructor(
    injector: Injector,
    private _sanitizer: DomSanitizer,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.safeContent = this._sanitizer.bypassSecurityTrustHtml(this.content);
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onButtonClick() {
    this.modalCtrl.dismiss(ModalStatics.CONFIRM);
  }
}
