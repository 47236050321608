// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-grid {
  height: 100%;
  min-height: 100%;
}
:host ion-grid ion-row {
  height: 100%;
}
:host ion-grid ion-row > div {
  display: block;
  padding: 0 32px 64px;
  text-align: center;
}
:host ion-grid ion-row > div ion-img {
  margin: 0 auto;
}
:host ion-grid ion-row > div ion-text {
  display: block;
}
:host ion-grid ion-row > div ion-button {
  display: inline-block;
}

ion-grid.modal {
  height: 395px;
  max-height: 395px;
  min-height: 395px;
  width: 350px;
  background-color: white;
  border-radius: 4px;
  position: relative;
  top: 254px;
}
ion-grid.modal ion-row > div {
  padding: 48px 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/enhanced-empty-view/enhanced-empty-view.component.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,gBAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAEY;EACI,cAAA;EACA,oBAAA;EACA,kBAAA;AAAhB;AAEgB;EACI,cAAA;AAApB;AAGgB;EACI,cAAA;AADpB;AAIgB;EACI,qBAAA;AAFpB;;AASA;EACI,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;AANJ;AAQI;EACI,kBAAA;AANR","sourcesContent":[":host {\n    & ion-grid {\n        height: 100%;\n        min-height: 100%;\n\n        & ion-row {\n            height: 100%;\n\n            & > div {\n                display: block;\n                padding: 0 32px 64px;\n                text-align: center;\n        \n                & ion-img {\n                    margin: 0 auto;\n                }\n        \n                & ion-text {\n                    display: block;\n                }\n        \n                & ion-button {\n                    display: inline-block;\n                }\n            }\n        }\n    }\n}\n\nion-grid.modal {\n    height: 395px;\n    max-height: 395px;\n    min-height: 395px;\n    width: 350px;\n    background-color: white;\n    border-radius: 4px;\n    position: relative;\n    top: 254px;\n\n    & ion-row > div {\n        padding: 48px 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
