import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { IModalDetails } from '../i-modal-details';

@Component({
  selector: 'app-modal-html-loader',
  templateUrl: './modal-html-loader.component.html',
  styleUrls: ['./modal-html-loader.component.scss'],
  standalone: false,
})
export class ModalHtmlLoaderComponent extends BasePageModal implements OnInit {

  @Input() modalDetails!: IModalDetails;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {}

  buttonClick(action: string) {
    this.modalCtrl.dismiss({ action });
  }
}
