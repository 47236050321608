// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-input {
  --color: var(--ion-color-dark);
}

span {
  visibility: hidden;
  opacity: 0;
}
span.showError {
  color: var(--ion-color-danger);
  visibility: visible;
  opacity: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-content-with-input/modal-content-with-input.page.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;AACJ;AACI;EACI,8BAAA;EACA,mBAAA;EACA,aAAA;AACR","sourcesContent":["ion-input {\n    --color: var(--ion-color-dark);\n}\n\nspan {\n    visibility: hidden;\n    opacity: 0;\n    \n    &.showError {\n        color: var(--ion-color-danger);\n        visibility: visible;\n        opacity: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
