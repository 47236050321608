// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.outer {
  background-color: var(--ion-color-red-100);
  border-bottom: 1px solid var(--ion-color-red-300);
  color: var(--ion-color-red-700);
}

p {
  font-size: 14px;
  margin: 0;
  text-align: center;
}

ion-button {
  --padding-start: 0;
  --padding-end: 0;
  width: 48px;
}`, "",{"version":3,"sources":["webpack://./src/app/components-standalone/one-signal-failed/one-signal-failed.component.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,iDAAA;EACA,+BAAA;AACF;;AAEA;EACE,eAAA;EACA,SAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;AACF","sourcesContent":["div.outer {\n  background-color: var(--ion-color-red-100);\n  border-bottom: 1px solid var(--ion-color-red-300);\n  color: var(--ion-color-red-700);\n}\n\np {\n  font-size: 14px;\n  margin: 0;\n  text-align: center;\n}\n\nion-button {\n  --padding-start: 0;\n  --padding-end: 0;\n  width: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
