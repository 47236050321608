import { Injectable } from '@angular/core';
import {
  GetFullListingGQL,
  GetFullListingQueryVariables,
  PlaceListingFragment,
  TrackCallPlaceGQL,
  TrackViewPlaceGQL,
  TrackStartBookingGQL,
  TrackRequestBookingGQL,
  TrackCompleteBookingGQL,
  Scalars,
  GetPlaceSlugGQL,
} from 'src/generated/graphql';
import * as Parse from 'parse';
import { Apollo, gql } from 'apollo-angular';
import { TabService } from './tab.service';

export interface ISectionItemInfo {
  icon: string;
  title: string;
  description: string;
  available: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PlaceGqlService extends Parse.Object {

  static readonly DIETARY   = 'dietaryRestrictions';
  static readonly EQUIPMENT = 'equipmentCategories';
  static readonly FEATURES  = 'features';

  private _featureLike = `
    objectId
    icon
    title
  `;

  private _dietary = gql`
    query {
      dietaryRestrictions (where:{
        status:{
          equalTo:"Active"
        }
      }) {
        edges {
          node {
            ${this._featureLike}
            description
          }
        }
      }
    }
  `;

  private _equipment = gql`
    query {
      equipmentCategories(where:{
        status:{
          equalTo:"Active"
        }
      }) {
        edges {
          node {
            ${this._featureLike}
          }
        }
      }
    }
  `;

  private _features = gql`
    query {
      features (where:{
        status:{
          equalTo:"Active"
        }
      }) {
        edges {
          node {
            ${this._featureLike}
            description
          }
        }
      }
    }
  `;

  constructor(
    private _apollo: Apollo,
    private _getPlace: GetFullListingGQL,
    private _getSlug: GetPlaceSlugGQL,
    private _trackView: TrackViewPlaceGQL,
    private _trackCall: TrackCallPlaceGQL,
    private _trackStartBooking: TrackStartBookingGQL,
    private _trackRequestBooking: TrackRequestBookingGQL,
    private _trackCompleteBooking: TrackCompleteBookingGQL,
    private _tabService: TabService,
  ) {
    super('Place');
  }

  getFeatureLikeList(whichList: string) {
    const query = whichList === PlaceGqlService.DIETARY
      ? this._dietary
      : whichList === PlaceGqlService.EQUIPMENT
        ? this._equipment
        : this._features;

    return this._apollo.query({ query });
  }

  getFullListing(placeId: GetFullListingQueryVariables): Promise<PlaceListingFragment> {
    return new Promise((resolve, reject) => {
      try {
        this._getPlace.watch(placeId).valueChanges.subscribe((resp) => {
          resolve(resp.data.place);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  loadSlug(placeId: Scalars['ID']): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        this._getSlug.fetch({ placeId }).subscribe(rsp => resolve(rsp.data.place.slug || ''));
      } catch (error) {
        reject(error);
      }
    });
  }

  distance(location: any, unit: string = 'km') {

    if (!location) {return null;}

    const geoPoint = new Parse.GeoPoint({
      latitude: location.latitude,
      longitude: location.longitude,
    });
    this.location = {
      lat: location.latitude,
      lng: location.longitude,
    };

    if (unit === 'km') {
      return this.location.kilometersTo(geoPoint).toFixed(1) + ' ' + unit;
    } else {
      return this.location.milesTo(geoPoint).toFixed(1) + ' ' + unit;
    }
  }

  trackView(placeId: string) {
    this._trackView.mutate({ placeId }).subscribe({
      next: (_) => {},
      error: (err) => {
        console.error(err);
      },
    });
  }

  trackCall(placeId: string) {
    this._trackCall.mutate({ placeId }).subscribe({
      next: (_) => {},
      error: (err) => {
        console.error(err);
      },
    });
  }

  trackStartBooking(placeId: Scalars['ID']) {
    this._trackStartBooking.mutate({ placeId }).subscribe({
      next: (_) => {},
      error: (err) => {
        console.error(err);
      },
    });
  }

  trackRequestBooking(placeId: Scalars['ID']) {
    this._trackRequestBooking.mutate({ placeId }).subscribe({
      next: (_) => {},
      error: (err) => {
        console.error(err);
      },
    });
  }

  trackCompleteBooking(placeId: Scalars['ID']) {
    this._trackCompleteBooking.mutate({ placeId }).subscribe({
      next: (_) => {},
      error: (err) => {
        console.error(err);
      },
    });
  }

  getSlug(id: Scalars['ID']): string {
    let slug = `${this._tabService.currentTabUrl}/places/${id}`;

    if (this.slug) {
      slug += '/' + this.slug;
    }

    return slug;
  }
  
  get slug() {
    return this.get('slug');
  }

  get url() {
    return this.get('url');
  }

  get location() {
    return this.get('location');
  }

  set location(val) {
    const geoPoint = new Parse.GeoPoint({
      latitude: val.lat,
      longitude: val.lng,
    });
    this.set('location', geoPoint);
  }
}
