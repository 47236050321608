import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root',
})
export class PublicProfileService {

  constructor() { }

  async getUserPublicProfile(profileUserId: string) {
    return await Parse.Cloud.run('getUserPublicProfile', { profileUserId });
  }

  async getIdByUsername(username: string) {
    return await Parse.Cloud.run('getIdByUsername', { username });
  }
}
