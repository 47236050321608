import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceName',
  standalone: false,
})
export class InvoiceNamePipe implements PipeTransform {

  transform(invoiceName: string, withIdAndExtension: boolean = false, userType: string = ''): string {
    const invoiceNameSplit = invoiceName.split('_');
    const invoiceType      = invoiceNameSplit[0];
    const invoiceDate      = invoiceNameSplit[invoiceNameSplit.length - 1].substring(0, 10);
    const invoiceId        = invoiceNameSplit[1];
    
    return invoiceType + '_' + invoiceDate + (withIdAndExtension ? '_' + invoiceId + '_' + userType + '.pdf' : '');
  }
}
