import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
})
export class LoadingOverlayComponent implements OnInit {
  @Input() text = '';
  loadingFact   = '';


  constructor(
    private _translateService: TranslateService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.displayLoadingFacts();
  }

  async displayLoadingFacts() {
    const trans = await lastValueFrom(this._translateService.get(['MAKER_FACTS']));
    const { FACT_1, FACT_2 } = trans.MAKER_FACTS;
    const facts              = [FACT_1, FACT_2];
    const factNum = Math.floor(Math.random() * facts.length);
    this.loadingFact = facts[factNum];
  }
}
