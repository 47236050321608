// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  cursor: default;
}
ion-card ion-icon {
  margin-left: auto;
  cursor: pointer;
}
ion-card > p {
  color: var(--ion-color-dark);
  align-self: flex-start;
  cursor: text;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-kitchen-experience/modal-kitchen-experience.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACI;EACI,iBAAA;EACA,eAAA;AACR;AAEI;EACI,4BAAA;EACA,sBAAA;EACA,YAAA;AAAR","sourcesContent":["ion-card {\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    max-width: 320px;\n    cursor: default;\n\n    ion-icon {\n        margin-left: auto;\n        cursor: pointer;\n    }\n\n    > p {\n        color: var(--ion-color-dark);\n        align-self: flex-start;\n        cursor: text;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
