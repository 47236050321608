// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-title {
  font-weight: 700 !important;
}

ion-img {
  margin: 0 auto;
}

:host ion-grid {
  height: 336px;
  max-height: 336px;
  width: 100%;
  max-width: 334px;
  background-color: white;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-share-my-location/modal-share-my-location.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAGI;EACI,aAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,uBAAA;EACA,kBAAA;AAAR","sourcesContent":["ion-title {\n    font-weight: 700 !important;\n}\n\nion-img {\n    margin: 0 auto;\n}\n\n:host {\n    & ion-grid {\n        height: 336px;\n        max-height: 336px;\n        width: 100%;\n        max-width: 334px;\n        background-color: white;\n        border-radius: 4px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
