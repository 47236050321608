import { Injectable } from '@angular/core';
import { interval, ReplaySubject, Subscription } from 'rxjs';
import { GetIdentityStatusGQL, GetIdentityStatusQuery } from 'src/generated/graphql';
import * as Parse from 'parse';
import { User } from './user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IdentityService {

  static readonly STATUS_PENDING     = 'PENDING';
  static readonly STATUS_SUBMITTED   = 'SUBMITTED';
  static readonly STATUS_FAILED      = 'FAIL'; // Auto-declined
  static readonly STATUS_PASSED      = 'PASS'; // Auto-approved

  static readonly TYPE_IDENTITY    = 'identity';
  static readonly TYPE_INSURANCE   = 'insurance';
  static readonly TYPE_FOOD_SAFETY = 'foodSafety';

  private readonly _ONE_MINUTE = 1000 * 60;

  private readonly _identityChange = new ReplaySubject<User>(1);
  private _interval                = Subscription.EMPTY;
  private _userChecked: { [key: string]: boolean } = {};

  constructor(
    private _idenittyCheck: GetIdentityStatusGQL,
    private _http: HttpClient,
  ) { }

  get identityChange() {
    return this._identityChange;
  }

  get inquiryId() {
    return this._user.identityReportId && this._user.identityReportId !== '' ? this._user.identityReportId : null;
  }

  get started() {
    return this.created || this.failed || this.passed || this.submitted;
  }

  get created() {
    return this._userMatchesStatus(IdentityService.STATUS_PENDING);
  }

  get submitted() {
    return this._userMatchesStatus(IdentityService.STATUS_SUBMITTED);
  }

  get failed() {
    return this._userMatchesStatus(IdentityService.STATUS_FAILED);
  }

  get passed() {
    return this._userMatchesStatus(IdentityService.STATUS_PASSED);
  }

  get completed() {
    return this.failed || this.passed || this.submitted;
  }

  get notStarted() {
    return !this._userHasStatus();
  }

  private get _user() {
    return User.getCurrent();
  }

  private get _identityStatus() {
    return this._user?.attributes.identityStatus;
  }

  async verifyInquiryStatus() {
    /*
    if (!this.inquiryId && !this._userChecked[this.user.id]) {
      this._userChecked[this.user.id] = true;
      const fetchUser = await Parse.Cloud.run('inquiryEnsureUnique');
      if (fetchUser) {
        await User.getCurrent().fetch();
      }
    }
    */
  }

  /**
   * Check the user record each minute until Passbase returns a result to the webhook
   */
  initAutoRefresh() {
    if (this._interval.closed
     && this.started
     && !this.passed
     && !this.failed
    ) {
      this._interval = interval(this._ONE_MINUTE).subscribe({
        next: async () => {
          const data = await this._checkIdentityStatus();
          if (this._gqlStatusIsComplete(data.user.identityStatus || '')) {
            await User.getCurrent().fetch();
            this._identityChange.next(User.getCurrent());
            this._interval.unsubscribe();
          }
        },
      });
    }
  }

  /**
   * Called when the verification is complete
   * - Since we default to PASS, we can just to a next on the change
   */
  forceComplete() {
    this._identityChange.next(User.getCurrent());
    if (this._interval) {
      this._interval.unsubscribe();
    }
  }

  /**
   * Gets a new session token to continue a previously started inquiry
   * @param inquiryId 
   * @returns 
   */
  async getSessionToken(inquiryId: string): Promise<string> {
    return Parse.Cloud.run('identityGetSessionToken', { inquiryId });
  }

  /**
   * Generates a one-time viewable link for the Persona file
   * @param documentType 
   * @returns 
   */
  getPersonaOneTimeDocumentCode(documentType: string) {
    return Parse.Cloud.run('getPersonaOneTimeDocumentCode', { documentType });
  }

  /**
   * Formats the one-time URL to be used to fetch the document
   * @param oneTimeCode 
   * @returns 
   */
  formatDocumentUrl(oneTimeCode: string) {
    const url = environment.serverUrl.substring(0, environment.serverUrl.length - 4);
    return url + '/persona/document/' + oneTimeCode;
  }

  /**
   * Redacts any data upload with the previous inquiry attempt
   * - Also resets the user's record to allow them to start a new inquiry
   * @returns 
   */
  async retry(): Promise<boolean> {
    const isReset = await Parse.Cloud.run('identityRetry');
    if (isReset) {
      await User.getCurrent().fetch();
    }
    return isReset;
  }

  /**
   * Use GraphQL to fetch the current identity status for the user
   * @returns 
   */
  private _checkIdentityStatus(): Promise<GetIdentityStatusQuery> {
    return new Promise((resolve, reject) => {
      this._idenittyCheck
        .fetch({ userId: Parse.User.current()?.id || '' }, { fetchPolicy: 'no-cache' })
        .subscribe({
          next: response => resolve(response.data),
          error: error   => reject(error),
        });
    });
  }

  private _gqlStatusIsComplete(status: string) {
    return status && (
      status === IdentityService.STATUS_FAILED ||
      status === IdentityService.STATUS_PASSED
    );
  }

  private _userHasStatus() {
    const identityStatus = this._identityStatus;
    return identityStatus !== null && identityStatus !== undefined && identityStatus !== '' ? true : false;
  }

  private _userMatchesStatus(status: string) {
    return this._identityStatus === status;
  }
}
