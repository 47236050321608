// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** 

We've exposed the scope on this component 
in order to allow for styling of the swiper
arrows.

DO NOT ADD CODE OUTSIDE OF THE .images

*/
.swiper-images {
  position: relative;
}
.swiper-images ion-button {
  position: absolute;
  top: calc(50% - 24px);
  z-index: 10;
}
.swiper-images ion-button:first-of-type {
  left: 0;
}
.swiper-images ion-button:first-of-type ion-icon {
  position: relative;
}
.swiper-images ion-button:last-of-type {
  right: 0;
}
.swiper-images ion-button:last-of-type ion-icon {
  position: relative;
}
.swiper-images .swiper {
  border-radius: 10px;
  --bullet-background: var(--ion-color-light);
  --bullet-background-active: var(--ion-color-primary);
}
.swiper-images .swiper .swiper-pagination-fraction {
  background-color: rgba(23, 23, 23, 0.4392156863);
  width: 43px;
  border-radius: 4px;
  color: var(--ion-color-light);
  bottom: 38px;
  left: calc(100% - 51px);
}
.swiper-images .swiper .bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 448px;
  aspect-ratio: 16/9;
  border-radius: 10px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/swiper-carousel-and-lightbox/swiper-carousel-and-lightbox.component.scss"],"names":[],"mappings":"AAAA;;;;;;;;CAAA;AASA;EACI,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,qBAAA;EACA,WAAA;AACR;AAEI;EACI,OAAA;AAAR;AACQ;EACI,kBAAA;AACZ;AAII;EACI,QAAA;AAFR;AAGQ;EACI,kBAAA;AADZ;AAMI;EACI,mBAAA;EACA,2CAAA;EACA,oDAAA;AAJR;AAMQ;EACI,gDAAA;EACA,WAAA;EACA,kBAAA;EACA,6BAAA;EACA,YAAA;EACA,uBAAA;AAJZ;AAOQ;EACI,sBAAA;EACA,4BAAA;EACA,2BAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AALZ","sourcesContent":["/** \n\nWe've exposed the scope on this component \nin order to allow for styling of the swiper\narrows.\n\nDO NOT ADD CODE OUTSIDE OF THE .images\n\n*/\n.swiper-images {\n    position: relative;\n\n    ion-button {\n        position: absolute;\n        top: calc(50% - 24px);\n        z-index: 10;\n    }\n    \n    ion-button:first-of-type {\n        left: 0;\n        ion-icon {\n            position: relative;\n            \n        }\n    }\n    \n    ion-button:last-of-type {\n        right: 0;\n        ion-icon {\n            position: relative;\n            \n        }\n    }\n    \n    .swiper {\n        border-radius: 10px;\n        --bullet-background: var(--ion-color-light);\n        --bullet-background-active: var(--ion-color-primary);\n\n        & .swiper-pagination-fraction{\n            background-color: #17171770;\n            width: 43px;\n            border-radius: 4px;\n            color: var(--ion-color-light);\n            bottom: 38px;\n            left: calc(100% - 51px);\n        }\n    \n        .bg-img {\n            background-size: cover;\n            background-repeat: no-repeat;\n            background-position: center;\n            width: 100%;\n            height: 448px;\n            aspect-ratio: 16/9;\n            border-radius: 10px;\n            cursor: pointer;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
