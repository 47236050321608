// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
  --background: var(--ion-color-grey-200);
}`, "",{"version":3,"sources":["webpack://./src/app/components-standalone/phone-number/components/phone-number-country-selection/phone-number-country-selection.component.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;AACF","sourcesContent":[".selected {\n  --background: var(--ion-color-grey-200);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
