import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root',
})
export class NotificationsPreferencesService {
  private readonly _DEFAULT_EMAIL_OPTIONS = {
    bookings: false,
    cartAbandonment: true,
  };
  private _isPushEnabled = false;
 

  constructor() { }

  get isPushEnabled() {
    return this._isPushEnabled;
  }

  set isPushEnabled(val) {
    this._isPushEnabled = val;
  }

  async findById(id: string): Promise<any> {
    const query = new Parse.Query('UserNotificationPreference');
    const preferences = await query.get(id);
 
    if (typeof preferences.get('enabledEmail') !== 'object') {
      await preferences.save({ enabledEmail: this._DEFAULT_EMAIL_OPTIONS });
    }
    return preferences;
  }

  async updateNotificationPreference(payload: any) {
    return await Parse.Cloud.run('updateNotificationPreference', payload);
  }

  async createDefaultNotificationsPref() {
    return await Parse.Cloud.run('createDefaultNotificationPref');
  }


}


