import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PreferenceMatchCalculator } from 'src/app/helpers/preference-match-calculator';
import { PreferenceMatch } from '../section-preview-card/section-preview-card.model';

@Component({
  selector: 'app-preference-match',
  templateUrl: './preference-match.component.html',
  styleUrls: ['./preference-match.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PreferenceMatchComponent implements OnInit, OnChanges {

  readonly ALIGN_CENTER = 'center';

  @Input() matchAlign = this.ALIGN_CENTER;
  @Input() prefDetails: PreferenceMatchCalculator | null = null;
  @Input() prefMatches: PreferenceMatch[] = [];
  @Input() isClickable  = false;
  @Output() prefClicked = new EventEmitter<number>();

  isLeftAligned = false;
  percentTrue   = 0;

  constructor() { }

  ngOnInit() {
    this._setAlignment();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.prefDetails && this.prefDetails) {
      this.percentTrue = Math.round(this.prefDetails.matchesOverallWeighted * 100);
    }
    this._setAlignment();
  }

  /**
   * Handle the preference icon being clicked
   * @param e 
   * @param i 
   */
  onPrefClicked(e: MouseEvent, i: number) {
    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
    this.prefClicked.emit(i);
  }

  private _setAlignment() {
    this.isLeftAligned = this.matchAlign !== this.ALIGN_CENTER;


  }
}
