import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Subject } from 'rxjs';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import write_blob from 'capacitor-blob-writer';

interface IChildWindowOptions {
  url?:      string;
  blob?:     Blob;
  fileName?: string;
  isPdf?:    boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ChildWindowService {

  static readonly WINDOW_CLOSED = 'Window Closed';

  private _win: Window | null = null;
  private _messageSubject     = new Subject<string>();

  constructor(
    private _iab: InAppBrowser,
    private _ngZone: NgZone,
    private _platform: Platform,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this._win = this._document.defaultView;
  }

  get messageSubject() {
    return this._messageSubject;
  }

  private get _isCapacitor() {
    return this._platform.is('capacitor');
  }

  /**
   * Opens an InAppBrowser or ChildWindow
   * @param options 
   */
  async open(options: IChildWindowOptions): Promise<boolean> {
    const isPdfUrl = this._isPdf(options);
    if (this._isCapacitor) {
      if (options.url && isPdfUrl) {
        return await this._processPdf(options.url);
      } else if (options.url && !isPdfUrl) {
        return await this._openInAppBrowser(options.url);
      } else if (options.blob) {
        return await this._processPDFBlob(options.blob, options.fileName || 'file.pdf');
      }
    } else {
      if (options.url && isPdfUrl) {
        return await this._downloadPdf(options.url, options.fileName);
      } else if (options.url && !isPdfUrl) {
        return await this._openTab(options.url);
      } else if (options.blob) {
        return await this._openBlob(options.blob, options.fileName || 'file.pdf');
      }
    }
    return false;
  }

  /**
   * _isPdf
   * - Returns true when the file is a PDF (as opposed to an image)
   * - Images can be opened natively in the browser, but PDFs need to be opened using
   *   another app when in Capacitor
   * @param options 
   * @returns 
   */
  private _isPdf(options: IChildWindowOptions) {
    const PDF = '.pdf';
    return options.isPdf
        || options.url?.toLowerCase().endsWith(PDF)
        || options.fileName?.toLowerCase().endsWith(PDF);
  }

  /**
   * _processPDFBlob
   * - Used by Capacitor to save the Blob locally and then open it using the PDF app on the device
   * @param blob 
   * @param blobName 
   * @returns 
   */
  private async _processPDFBlob(blob: Blob, blobName: string) {
    try {
      // Ensure the user is able to download to the 
      const isOkToDownload = await this._checkPermissions();
      if (isOkToDownload) {
        await this._mkdir();

        const file = await write_blob({
          blob,
          directory: Directory.Cache,
          path:      '/tmp/' + blobName,
        });

        // Open the file
        await FileOpener.openFile({
          path:     file || '',
          mimeType: 'application/pdf',
        });

        this._deleteTmpFile(blobName);

        return true;
      }
    } catch (e) {
      console.warn(e);
    }
    return false;
  }

  /**
   * _processPdf
   * - Used by Capacitor to download a PDF form a URL, save it locally, and then open in using the PDF app on the device
   * @param url 
   * @returns 
   */
  private async _processPdf(url: string) {
    try {
      // Ensure the user is able to download to the 
      const isOkToDownload = await this._checkPermissions();
      if (isOkToDownload) {
        await this._mkdir();

        // Get the file name and download the file
        const parts = url.split('/');
        const part  = parts[parts.length - 1];
        const name  = part.substring(part.indexOf('_') + 1);
        const file  = await Filesystem.downloadFile({
          url,
          directory: Directory.Cache,
          path:      '/tmp/' + name,
        });
        
        // Open the file
        await FileOpener.openFile({
          path:     file.path || '',
          mimeType: 'application/pdf',
        });

        this._deleteTmpFile(name);

        return true;
      }
    } catch (e) {
      console.warn(e);
    }
    return false;
  }

  /**
   * _openInAppBrowser
   * - Used by Capacitor to open content in a WebView above the app
   * - The WebView is styled with Syzl colors and has a close button to return to the app
   * @param url 
   * @returns 
   */
  private async _openInAppBrowser(url: string) {
    try {
      const browser = this._iab.create(url, '_self', {
        closebuttoncolor:      '#FFFFFF',
        footercolor:           '#003DFF',
        fullscreen:            'yes',
        navigationbuttoncolor: '#FFFFFF',
        toolbarcolor:          '#0027FF',
      }) as InAppBrowserObject;
      browser.on('message').subscribe((e) => {
        this._dispatch(e.data.msg);
        browser?.close();
      });
      browser.on('exit').subscribe(() => {
        this._dispatch(ChildWindowService.WINDOW_CLOSED);
      });
      return true;
    } catch (e) {
      console.warn('Could not open InAppBrowser', e);
      return false;
    }
  }

  /**
   * _downloadPdf
   * - Used by browsers to open/download a PDF file (when coming directly from a URL)
   * @param url 
   * @param fileName 
   * @returns 
   */
  private async _downloadPdf(url: string, fileName?: string) {
    fileName   = fileName
      ? fileName
      : url.substring(url.lastIndexOf('/') + 1);
    return this._openViaAnchor(url, fileName);
  }

  /**
   * _openTab
   * - Used by browsers to open a URL in a new tab
   * @param url 
   * @returns 
   */
  private async _openTab(url: string) {
    try {
      // Open the child window and create an interval to watch for when it closes
      const browser = this._win?.open(url) as Window;
      const watcher = setInterval(() => {
        try {
          // When the window is closed, the document value will be undefined
          // When open at a non-Syzl URL, this will error and be caught in the catch
          // When open at a Syzl URL, this will skip past the IF condition
          if ((browser as Window)?.closed) {
            this._dispatch(ChildWindowService.WINDOW_CLOSED);
            clearInterval(watcher);
          }
        } catch (error) {
          console.error(error);
        } // Window is still open at a non-Syzl URL
      }, 1000);

      // Listen for messages from child windows
      const browserMessage = (e: any) => {
        const msg = e.data?.msg;
        if (msg) {
          this._dispatch(e.data.msg);
          browser?.close();
          this._win?.removeEventListener('message', browserMessage);
        }
      };
      this._win?.addEventListener('message', browserMessage);
      return true;
    } catch (e) {
      console.warn('Error opening browser tab', e);
      return false;
    }
  }

  /**
   * _openBlob
   * - Used by browsers to open/download a Blob file
   * @param blob 
   * @param fileName 
   * @returns 
   */
  private async _openBlob(blob: Blob, fileName: string) {
    return this._openViaAnchor((this._win as Window & typeof globalThis)?.URL.createObjectURL(blob), fileName);
  }

  /**
   * _openViaAnchor
   * - Used by browsers to open/download a file with an <a> tag
   * @param url 
   * @param fileName 
   * @returns 
   */
  private _openViaAnchor(url: string, fileName: string) {
    const a    = this._document.createElement('a');
    a.href     = url;
    a.download = fileName;
    a.target   = '_blank';
    a.click();
    (this._win as Window & typeof globalThis)?.URL.revokeObjectURL(a.href);
    return true;
  }

  /**
   * _checkPermissions
   * - Used by Capacitor to ensure the user has file permissions enabled
   * @returns 
   */
  private async _checkPermissions() {
    // Ensure the user is able to download to the 
    let permissions = await Filesystem.checkPermissions();
    
    if (permissions.publicStorage !== 'granted') {
      permissions = await Filesystem.requestPermissions();
    }
    return permissions.publicStorage === 'granted';
  }

  /**
   * _mkdir
   * - Used by Capacitor to ensure the Cache directory has a tmp folder
   */
  private async _mkdir() {
    // Ensure the directory exists
    try {
      await Filesystem.mkdir({
        directory: Directory.Cache,
        path:      '/tmp',
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * _deleteTmpFile
   * - Deletes the temp file that was downloaded
   * @param fileName 
   */
  private async _deleteTmpFile(fileName: string) {
    try {
      await new Promise((resolve, _) => setTimeout(() => resolve(true), 60000)); // Allow for 60 seconds for the file to load
      await Filesystem.deleteFile({
        directory: Directory.Cache,
        path:      '/tmp/' + fileName,
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Brings focus to the app and dispatches the message
   * @param msg 
   */
  private _dispatch(msg: string) {
    this._ngZone.run(() => {
      this._win?.focus();
      this._messageSubject.next(msg);
    });
  }
}
