import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { User } from 'src/app/services/user.service';
import { BasePageModal } from '../base-page-modal/base-page-modal';
import { GeolocationService } from 'src/app/services/geolocation.service';

@Component({
  standalone: false,
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.page.html',
  styleUrls: ['./profile-preview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate(
            '200ms',
            style({ opacity: 1 }),
          ),
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate(
            '100ms',
            style({ opacity: 0 }),
          ),
        ]),
      ]),
  ],
})
export class ProfilePreviewPage extends BasePageModal implements OnInit {
  @Input() userId                   = '';
  @Input() isPreviewMode            = false;
  @Input() existingConversation     = '';
  @Input() placePayload: any | null = null;
  @Input() isConversation           = false;
  @Input() user: any | null         = null;
  currentUser                       = User.getCurrent();
  isNameVisible                     = true;
  location: any                     = null;

  constructor(
    injector: Injector,
    private _geolocation: GeolocationService,
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.location = await this._geolocation.getCurrentPosition();
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  onNameVisible(isVisible: boolean) {
    this.isNameVisible = isVisible;
  }

  onMessageClick(event: any) {
    if (this.isConversation) {
      this.closeModal();
      return;
    }
    const conversationSid = event;
    if (typeof conversationSid === 'string') {
      this.navigateTo(`${this.tabService.currentTabUrl}/conversation/${conversationSid}`);
    } else {
      window.dispatchEvent(new CustomEvent('conversation:start', { detail: { payload: this.placePayload } }));
    }
  }

}
