// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pref {
  position: relative;
}
.pref::before {
  content: "";
  border: 2px solid transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: all 150ms;
}
.pref:hover, .pref:focus {
  text-decoration: underline;
}
.pref:hover::before, .pref:focus::before {
  border: 2px solid var(--ion-color-blue-200);
  border-radius: 0;
  bottom: -4px;
  left: -4px;
  right: -4px;
  top: -4px;
}
.pref.selected:hover::before, .pref.selected:focus::before {
  border-color: var(--ion-color-blue-300);
}`, "",{"version":3,"sources":["webpack://./src/app/components/preference-list/preference-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,WAAA;EACA,6BAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,qBAAA;AACJ;AAEE;EAEE,0BAAA;AADJ;AAGI;EACE,2CAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;AADN;AAQM;EACE,uCAAA;AANR","sourcesContent":[".pref {\n  position: relative;\n\n  &::before {\n    content: \"\";\n    border: 2px solid transparent;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    top: 0;\n    transition: all 150ms;\n  }\n\n  &:hover,\n  &:focus {\n    text-decoration: underline;\n\n    &::before {\n      border: 2px solid var(--ion-color-blue-200);\n      border-radius: 0;\n      bottom: -4px;\n      left: -4px;\n      right: -4px;\n      top: -4px;\n    }\n  }\n\n  &.selected {\n    &:hover,\n    &:focus {\n      &::before {\n        border-color: var(--ion-color-blue-300);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
