import { Injectable } from '@angular/core';
import { NativeAudio } from '@capacitor-community/native-audio';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AudioService {

  private _audioType = 'html5';
  private _sounds: any = [];

  constructor(platform: Platform) {
    if (platform.is('capacitor') && platform.is('ios')) {
      this._audioType = 'native';
    }
  }

  preload(key: string, asset: string) {

    if (this._audioType === 'html5') {
      const audio = {
        key,
        asset,
        type: 'html5',
      };

      this._sounds.push(audio);
    } else {

      NativeAudio.preload({
        assetId: key,
        assetPath: asset,
        audioChannelNum: 1,
        isUrl: false,
      });

      const audio = {
        key,
        asset: key,
        type: 'native',
      };

      this._sounds.push(audio);
    }

  }

  play(key: string) {

    const audio = this._sounds.find((sound: any) => sound.key === key);

    if (audio.type === 'html5') {

      const audioAsset = new Audio(audio.asset);
      audioAsset.volume = 0.3;
      audioAsset.play();

    } else {

      NativeAudio.play({
        assetId: audio.asset,
        time: 0,
      });
    }
  }

}
