// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 0;
  cursor: default;
}

.special {
  background-color: var(--ion-color-tertiary);
}
.special ion-card-title {
  color: var(--ion-color-light);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-content-only/modal-content-only.page.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,2CAAA;AACF;AACE;EACE,6BAAA;AACJ","sourcesContent":["ion-card {\n  border-radius: 0;\n  cursor: default;\n}\n\n.special {\n  background-color: var(--ion-color-tertiary);\n  \n  ion-card-title {\n    color: var(--ion-color-light);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
