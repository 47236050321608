// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-xxxlarge {
  font-size: 64px;
  line-height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pref-number-stepper/pref-number-stepper.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".text-xxxlarge {\n    font-size: 64px;\n    line-height: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
