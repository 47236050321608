// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 85vh;
}
:host .container ion-icon {
  font-size: 64px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/empty-view/empty-view.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;AACF;AAEI;EACE,eAAA;AAAN","sourcesContent":[":host {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  height: 85vh;\n\n  .container {\n    ion-icon {\n      font-size: 64px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
