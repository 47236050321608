import { Injectable } from '@angular/core';
import { PlaceListingFragment, UserBookingPrefFragment } from 'src/generated/graphql';
import { PreferenceMatchCalculator } from '../helpers/preference-match-calculator';
import { CleaningHoursPipe } from '../pipes/cleaning-hours.pipe';
import { Place } from './place.service';
import { Preference } from './preference.service';

@Injectable({
  providedIn: 'root',
})
export class PlacePrefCacheService {

  private _cache: { [key: string]: PreferenceMatchCalculator } = {};

  constructor(
    private _preference: Preference,
    private _cleaningHoursPipe: CleaningHoursPipe,

  ) {
    // When the booking preferences change, we need to reset the cache
    // so the calculator re-checks
    this._preference.bookingPrefsChanged.subscribe(() => {
      this._cache = {};
    });
  }

  /**
   * Checks the cache to see if the place / pref exists
   * - If not, it adds it to the cache
   * - Returns the PreferenceMatchCalculator info
   * @param placeId 
   * @param placeInfo 
   * @param prefs 
   * @returns 
   */
  fromCache(placeId: string, placeInfo: Place | PlaceListingFragment, prefs: UserBookingPrefFragment) {
    if (!prefs) {
      return new PreferenceMatchCalculator(2, 5, [], [], [], 2, 5, [], [], [], 0, {});
    }
    if (!this._cache[placeId]) {
      this._cache[placeId] = new PreferenceMatchCalculator(
        prefs.teamCount,
        prefs.productionTime + this._cleaningHoursPipe.transform(prefs.productionTime),
        prefs.dietary.edges             as any[],
        prefs.equipmentCategories.edges as any[],
        prefs.features.edges            as any[],
        placeInfo.capacity,
        placeInfo.minimumBooking,
        placeInfo.dietaryRestrictions,
        placeInfo.equipmentCategories,
        placeInfo.features,
        placeInfo.next2weeksAvailableHours,
        placeInfo.next2weeksDetails,
      );
    }
    return this._cache[placeId];
  }
}
