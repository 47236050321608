import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dollarSign',
  standalone: false,
})
export class DollarSignPipe implements PipeTransform {

  transform(value: string, currency?: string): string {
    return value ? `${currency || ''}$${value}` : '';
  }

}
