import { Pipe, PipeTransform } from '@angular/core';
import { ConversationsService } from '../services/conversations.service';
import { User } from '../services/user.service';

@Pipe({
  name: 'getLastMessage',
  standalone: false,
})
export class GetLastMessagePipe implements PipeTransform {
  user = User.getCurrent();

  constructor(
    private _conversationsService: ConversationsService,
  ) {}

  async transform(conversationSid: string) {
   
    if (!conversationSid || conversationSid === '' || conversationSid.length < 24) {
      return '';
    }

    const conversation = await this._conversationsService.conversationClient?.getConversationBySid(conversationSid);
    const lastMessage  = await conversation?.getMessages(1);
    
    let message = '';
    
    if (lastMessage?.items[0].author === this.user.id) {
      message = 'You: ';
    }
    return message + lastMessage?.items[0].body || '';
  }

}
