import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { emailValidator } from 'src/app/validators/email-validator';
import { IModalDetails } from '../i-modal-details';
import { ModalStatics } from 'src/app/services/modal.service';


@Component({
  selector: 'app-modal-content-with-email',
  templateUrl: './modal-content-with-email.component.html',
  styleUrls: ['./modal-content-with-email.component.scss'],
  standalone: false,
})
export class ModalContentWithEmailComponent extends BasePageModal implements OnInit {

  @Input() modalDetails!: IModalDetails;

  readonly CONFIRM   = ModalStatics.CONFIRM;

  safeHtml: SafeHtml | null = null;
  msg                = '';
  form               = new FormGroup({
    email: new FormControl('', [emailValidator(), Validators.required]),
  }, { updateOn: 'change' });

  constructor(
    injector: Injector,
    private _sanitizer: DomSanitizer,
  ) {
    super(injector);
  }

  ngOnInit() {
    const modalDetailsDefaults = { required: true };
    this.modalDetails.textarea = Object.assign({}, modalDetailsDefaults, this.modalDetails.textarea);
    this.safeHtml              = this._sanitizer.bypassSecurityTrustHtml(this.modalDetails.content);
    const defaultText          = this.modalDetails.textarea.defaultText;
    if (defaultText) {
      this.form.controls.email.patchValue(defaultText);
    }
  }

  async buttonClick(action: string) {
    if (action === ModalStatics.GO_BACK) {
      this.modalCtrl.dismiss({ action, msg: '' });
    } else if (this.form.valid) {
      this.modalCtrl.dismiss({ action, msg: this.form.controls.email.value });
    }
  }
}
