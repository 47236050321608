import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Place } from 'src/app/services/place.service';
import { Globals } from 'src/app/helpers/globals';
import { PlacePrefCacheService } from 'src/app/services/place-pref-cache.service';
import { Preference } from 'src/app/services/preference.service';
import { StatusPill } from './place-card.model';
import { ModalActions } from 'src/app/modals/modal-actions';
import { BookingClosedDatesAndTimesPage } from 'src/app/pages/booking-closed-dates-and-times/booking-closed-dates-and-times.page';
import { PlaceGqlService } from 'src/app/services/place-gql.service';
import { User } from 'src/app/services/user.service';
import { GoogleTagService } from 'src/app/services/google-tag.service';
import { PlaceRateService } from 'src/app/services/place-rate.service';
import { Router } from '@angular/router';
import { UserPlacePrefMatchPipe } from 'src/app/pipes/user-place-pref-match.pipe';
import { ComponentBase } from '../../components-standalone/component-base';

@Component({
  selector: 'app-place-card',
  templateUrl: './place-card.component.html',
  styleUrls: ['./place-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PlaceCardComponent extends ComponentBase implements OnChanges {

  readonly pendingStatus    = Globals.PLACE_STATUS_PENDING;
  readonly approvedStatus   = Globals.PLACE_STATUS_APPROVED;
  readonly incompleteStatus = Globals.PLACE_STATUS_INCOMPLETE;
  readonly rejectedStatus   = Globals.PLACE_STATUS_REJECTED;
  readonly privateStatus    = Globals.PLACE_STATUS_PRIVATE;

  readonly MAX_RATING       = 5;
  readonly NO_USER_PREFS    = -1;

  @Input() place!: Place;
  @Input() extraParams: any = {};
  @Input() showStatus       = false;
  @Input() showStatsButton  = false;
  @Input() showEditButton   = false;
  @Input() showDeleteButton = false;
  @Input() showPrice        = false;
  @Input() showCategory     = false;
  @Input() isUserListPage   = false;
  @Input() isProfile        = false;
  @Input() showPrefMatch    = false;

  @Output() editEvent: EventEmitter<Place>   = new EventEmitter<Place>();
  @Output() statsEvent: EventEmitter<Place>  = new EventEmitter<Place>();
  @Output() guidesEvent: EventEmitter<Place>  = new EventEmitter<Place>();
  @Output() deleteEvent: EventEmitter<Place> = new EventEmitter<Place>();
  @Output() like = new EventEmitter<void>();

  isHost         = false;
  canLike        = false;
  isLiked        = false;
  isReadyToView  = false;
  distance       = '';
  prefMatchColor = '';
  prefMatchPct   = this.NO_USER_PREFS;
  statusPill     = new StatusPill('', 0, '');

  // retrieved from place object
  avgRate: number        = 0;
  categories: string     = '';

  private _favPlaces: Place[] = [];

  constructor(
    private _prefs: Preference,
    private _prefMatch: PlacePrefCacheService,
    private _userPlacePrefMatch: UserPlacePrefMatchPipe,
    private _placeGql: PlaceGqlService,
    private _placeService: Place,
    private _changeRef: ChangeDetectorRef,
    private _googleTag: GoogleTagService,
    private _placeRateService: PlaceRateService,
    private _router: Router,
  ) {
    super();
    this._placeService._favePlaces$.subscribe((val: Place[]) => {
      this._favPlaces = val;
      this._updateIsLiked();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.place) {
      this.isHost     = this.place.user.id === User.getCurrent().id;
      this.avgRate    = this.place.get('placeRate')?.get('avgRate');
      this.categories = (this.place.categories || []).join(' • ');
      this.distance   = this.place.distance(this.extraParams.location, this.extraParams.unit, true) || '';
      this._updateIsLiked();
      // setting the status pill and its icon
      const icon = this.place.status === this.approvedStatus
        ? 'checkmark-circle'
        : this.place.status === this.pendingStatus
          ? 'time'
          : this.place.status === this.incompleteStatus || this.place.status === this.privateStatus
            ? 'alert-circle'
            : 'close-circle';
      this.statusPill = new StatusPill(this.place.status.toLowerCase(), 0, icon );

      // Calculate the pref match color
      if (this._prefs.booking) {
        const matchInfo     = this._userPlacePrefMatch.transform(this.place);
        this.prefMatchPct   = matchInfo.match;
        this.prefMatchColor = matchInfo.color;
      }

      this.isReadyToView = this.place.status === this.approvedStatus && this.isUserListPage;
    }

    // hide preference match if user has not set their kitchen preferences yet.
    const prefs = this._prefs.booking;
    if (prefs.features.edges?.length === 0 && prefs.equipmentCategories.edges?.length === 0 && prefs.dietary.edges?.length === 0 && prefs.createdAt === prefs.updatedAt) {
      this.showPrefMatch = false;
    }

  }

  private _updateIsLiked() {
    if (this.place) {
      this.isLiked    = this._favPlaces.some(place => place.id === this.place.id);
    }
    this._changeRef.markForCheck();
  }

  async onLike(event: any) {
    const trans = await this.getTrans(['LIKE_ADDED', 'LIKE_REMOVED'], { placeTitle: this.place.title || '' });
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.isLiked = !this.isLiked;
    
    try {
      await this._placeService.like(this.place as Place);
    } catch (error) {
      console.error(error);
    }
    this._changeRef.markForCheck();

    const toastText = this.isLiked ? trans.LIKE_ADDED : trans.LIKE_REMOVED;
    this.showToast(toastText);

    const rate: any = await this._placeRateService.loadPlaceRateInfo(this.place.id);
    
    // Google Tag Manager
    this._googleTag.add({
      event: 'add_to_wishlist',
      host: this.place?.user?.id,
      listingId: this.place?.id,
      item_name: this.place?.title || '',
      kitchenId: this.place?.facility.objectId,
      kitchen: this.place?.facility.title,
      currency: 'CAD',
      value: rate.defaultRate,
      items: [
        {
          item_id: this.place?.id,
        },
      ],
    });
  }

  /**
   * opens BookedClosedDatesAndTimes modal to edit kitchen availability 
   * @param event 
   */
  async onSuspendTouched(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    const place = await this._placeGql.getFullListing({ placeId: this.place.id });
    await ModalActions.openFullScreenModal(this._modalCtrl, BookingClosedDatesAndTimesPage, { place });
  }

  onSeeMyReviews(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    this._router.navigate([`${this._tabService.currentTabUrl}/places/${this.place.id}/reviews`]);
  }

  onEditPlaceTouched(event: Event) {
    event.stopPropagation();
    this.editEvent.emit(this.place);
  }

  onStatsButtonTouched(event: Event) {
    event.stopPropagation();
    this.statsEvent.emit(this.place);
  }

  onGuidesButtonTouched(event: Event) {
    event.stopPropagation();
    this.guidesEvent.emit(this.place);
  }

  onDeletePlaceTouched(event: Event) {
    if (this.place.status !== this.incompleteStatus) {
      return;
    }

    event.stopPropagation();
    if (this.isHost) {
      this.deleteEvent.emit(this.place);
    }
  }

  onCardClick(event: Event) {
    if (this.isUserListPage) {
      event.stopPropagation();
      this.onEditPlaceTouched(event);
    }
  }

  onViewListingTouched(event: Event) {
    event.stopPropagation();
    this._router.navigate([`${this._tabService.currentTabUrl}/places/${this.place.id}/${this.place.slug}`]);
  }
}
