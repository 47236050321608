import { Injector } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  LoadingController, ToastController,
  AlertController, IonInfiniteScroll, IonRefresher, Platform, ModalController, NavController,
} from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Preference } from 'src/app/services/preference.service';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { GoogleTagService } from 'src/app/services/google-tag.service';
import { NotificationsPreferencesService } from 'src/app/services/notifications-preferences.service';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { LoadingOverlayComponent } from '../../components-standalone/loading-overlay/loading-overlay.component';
import { TabRouteName, TabService } from 'src/app/services/tab.service';
import { lastValueFrom } from 'rxjs';

export abstract class BasePage {

  isErrorViewVisible   = false;
  isEmptyViewVisible   = false;
  isContentViewVisible = false;
  isLoadingViewVisible = false;

  breakpoints: BreakpointService;
  preference: Preference;
  tabService: TabService;
  notifications: NotificationsPreferencesService;
  modalCtrl: ModalController;

  protected refresher: IonRefresher | null = null;
  protected infiniteScroll: IonInfiniteScroll | null = null;
  protected navParams: ActivatedRoute;
  protected translate: TranslateService;
  protected router: Router;
  protected googleTag: GoogleTagService;
  protected platform: Platform;
  protected activatedRoute: ActivatedRoute;

  private _loader: any;
  private _toastCtrl: ToastController;
  private _loadingCtrl: LoadingController;
  private _alertCtrl: AlertController;
  private _navCtrl: NavController;
  private _meta: Meta;
  private _title: Title;

  constructor(injector: Injector) {
    this.breakpoints    = injector.get(BreakpointService);
    this.preference     = injector.get(Preference);
    this.tabService     = injector.get(TabService);
    this.notifications  = injector.get(NotificationsPreferencesService);
    this.modalCtrl      = injector.get(ModalController);

    this.navParams      = injector.get(ActivatedRoute);
    this.translate      = injector.get(TranslateService);
    this.router         = injector.get(Router);
    this.googleTag      = injector.get(GoogleTagService);
    this.platform       = injector.get(Platform);
    this.activatedRoute = injector.get(ActivatedRoute);
    
    this._toastCtrl     = injector.get(ToastController);
    this._loadingCtrl   = injector.get(LoadingController);
    this._alertCtrl     = injector.get(AlertController);
    this._navCtrl       = injector.get(NavController);
    this._meta          = injector.get(Meta);
    this._title         = injector.get(Title);
  }

  abstract enableMenuSwipe(): boolean;

  public get isCapacitorNative(): boolean {
    return Capacitor.isNativePlatform();
  }

  public get isCapacitor(): boolean {
    return this.platform.is('capacitor');
  }

  public get isPwa(): boolean {
    return this.platform.is('pwa');
  }

  public get isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  public get isMobile(): boolean {
    return this.platform.is('mobile');
  }

  public get isAndroid(): boolean {
    return this.platform.is('android');
  }

  public get isApple(): boolean {
    return this.platform.is('ios');
  }

  public get appUrl(): string {
    return environment.appUrl;
  }

  public get appImageUrl(): string {
    return environment.appImageUrl;
  }

  public async setPageTitle(title: string) {
    const str = await this.getTrans('APP_NAME');
    this._title.setTitle(`${title} - ${str}`);
  }

  public async setMetaTags(config1: {
    title?: string;
    description?: string;
    image?: string;
    slug?: string;
  }) {

    const str = await this.getTrans(['APP_NAME', 'APP_DESCRIPTION']);

    const config = {
      title: str.APP_NAME,
      description: str.APP_DESCRIPTION,
      image: this.appImageUrl,
      ...config1,
    };

    let url = this.appUrl + this.router.url;

    if (config.slug) {
      url = this.appUrl + config.slug;
    }

    this._meta.updateTag({
      property: 'og:title',
      content: config.title,
    });

    this._meta.updateTag({
      property: 'og:site_name',
      content: config.title,
    });

    this._meta.updateTag({
      name: 'description',
      content: config.description,
    });

    this._meta.updateTag({
      property: 'og:description',
      content: config.description,
    });

    this._meta.updateTag({
      property: 'og:image',
      content: config.image,
    });

    this._meta.updateTag({
      property: 'og:image:alt',
      content: config.title,
    });

    this._meta.updateTag({
      property: 'og:url',
      content: url,
    });

    this._meta.updateTag({
      name: 'twitter:card',
      content: 'summary_large_image',
    });

    this._meta.updateTag({
      name: 'twitter:title',
      content: config.title,
    });

    this._meta.updateTag({
      name: 'twitter:text:title',
      content: config.title,
    });

    this._meta.updateTag({
      name: 'twitter:description',
      content: config.description,
    });

    this._meta.updateTag({
      name: 'twitter:image',
      content: config.image,
    });

    this._meta.updateTag({
      name: 'twitter:image:alt',
      content: config.title,
    });
  }

  public getShareUrl(slug: string) {
    return this.appUrl + '/' + slug;
  }

  async showLoadingView(params: { showOverlay: boolean; message?: string }) {

    if (params.showOverlay) {
      const loadingText = params.message ? params.message : await this.getTrans('LOADING');

      this._loader = await this._loadingCtrl.create({
        message: loadingText,
        backdropDismiss: true,
      });

      return await this._loader.present();

    } else {
      this.isErrorViewVisible = false;
      this.isEmptyViewVisible = false;
      this.isContentViewVisible = false;
      this.isLoadingViewVisible = true;
    }

    return true;
  }

  /**
   * Shows the Wok Loading Animation overlay and returns the modal
   * Dismiss the modal in the component you are using with loader.dismiss()
   * @param text - Expects translation key from JSON - i.e 'LOADING', 'PROCESSING_PAYMENT', etc.
   * @returns Modal instance of the loading overlay
   */
  async showWokLoadingView(text?: string): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: LoadingOverlayComponent,
      componentProps: { text: text || 'LOADING' },
      cssClass: 'modal-fullscreen',
    });
    await modal.present();
    return modal;
  }

  dismissLoadingView() {
    return this._loader?.dismiss();
  }

  showContentView() {

    this.isErrorViewVisible = false;
    this.isEmptyViewVisible = false;
    this.isLoadingViewVisible = false;
    this.isContentViewVisible = true;

    this.dismissLoadingView();
  }

  showEmptyView() {

    this.isErrorViewVisible = false;
    this.isLoadingViewVisible = false;
    this.isContentViewVisible = false;
    this.isEmptyViewVisible = true;

    this.dismissLoadingView();
  }

  showErrorView() {

    this.isLoadingViewVisible = false;
    this.isContentViewVisible = false;
    this.isEmptyViewVisible = false;
    this.isErrorViewVisible = true;

    this.dismissLoadingView();
  }

  onRefreshComplete(data: any = null) {

    if (this.refresher) {
      this.refresher.disabled = true;
      this.refresher.complete();
      setTimeout(() => {
        if (this.refresher) {
          this.refresher.disabled = false;
        }
      }, 100);
    }

    if (this.infiniteScroll) {
      this.infiniteScroll.complete();

      if (data && data.length === 0) {
        this.infiniteScroll.disabled = true;
      } else {
        this.infiniteScroll.disabled = false;
      }
    }
  }

  async showToast(message: string = '', buttons: any = null, duration: number = 3000) {
    
    const closeText = await this.getTrans('CLOSE');
    const defaultCloseButton = [{
      text: closeText,
      role: 'cancel',
    }];

    buttons = buttons || defaultCloseButton;
    const toast = await this._toastCtrl.create({
      message,
      color: 'info',
      position: 'bottom',
      cssClass: 'tabs-bottom',
      duration,
      buttons,
    });

    toast.present();
    return toast;
  }

  async showAlert(message: string) {

    const okText = await this.getTrans('OK');

    const alert = await this._alertCtrl.create({
      header: '',
      message,
      buttons: [{
        text: okText,
        role: '',
      }],
    });

    return alert.present();
  }

  showConfirm(message: string, buttons?: { OK: string; CANCEL: string }): Promise<boolean> {
    return new Promise((resolve) => {
      if (buttons) {
        this.createConfirm(buttons, message, resolve);
      } else {
        const result = lastValueFrom(this.translate.get(['OK', 'CANCEL']));
        result.then((str) => {
          this.createConfirm(str, message, resolve);
        });
      }
    });
  }

  async createConfirm(str: { OK: string; CANCEL: string }, message: string, resolve: (value: boolean | PromiseLike<boolean>) => void) {
    const confirm = await this._alertCtrl.create({
      header: '',
      message,
      buttons: [{
        text: str.CANCEL,
        role: 'cancel',
        handler: () => resolve(false),
      }, {
        text: str.OK,
        handler: () => resolve(true),
      }],
    });

    confirm.present();
  }

  async showSweetRadio(title: string, confirmText: string, cancelText: string, options: any): Promise<any> {
    return Swal.fire({
      title,
      input: 'radio',
      inputOptions: options,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      showCancelButton: true,
      heightAuto: false,
      reverseButtons: true,
      showClass: {
        popup: 'animated fade-in',
      },
      hideClass: {
        popup: 'animated fade-out',
      },
      position: 'center',
    });
  }

  async  showSweetIcon(
    title: string,
    text: string,
    confirmButtonText: string,
    showCancelButton: boolean,
    icon: SweetAlertIcon,
    cancelButtonText?: string,
    iconColor?: string,
  ) {
    const options: SweetAlertOptions = {
      title,
      text,
      confirmButtonText,
      cancelButtonText,
      showCancelButton,
      heightAuto: false,
      icon,
      iconColor: iconColor || '#003DFF',
      showClass: {
        popup: 'animated fade-in',
      },
      hideClass: {
        popup: 'animated fade-out',
      },
    };

    return await Swal.fire(options);
  }

  async showSweetTextArea(text: string, placeholderText: string, confirmText: string, cancelText: string) {

    return await Swal.fire({
      title: '',
      text,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      showCancelButton: true,
      reverseButtons: true,
      input: 'textarea',
      inputPlaceholder: placeholderText,
      heightAuto: false,
      didOpen: () => {
        const confirmBtn = Swal.getConfirmButton();
        confirmBtn?.setAttribute('disabled', '');
        Swal.getInput()?.addEventListener('keyup', (e: any) => {
          if (e.target.value) {
            confirmBtn?.removeAttribute('disabled');
          } else {
            confirmBtn?.setAttribute('disabled', '');
          }
        });
      },
      showClass: {
        popup: 'animated fade-in',
      },
      hideClass: {
        popup: 'animated fade-out',
      },
      position: 'center',
    });
  }

  openUrl(url: string) {
    return Browser.open({ url });
  }

  openSimpleUrl(url: string) {
    window.open(url);
  }

  navigateTo(page: any, queryParams: any = {}) {
    return this.router.navigate([page], { queryParams });
  }

  navigateToRelative(page: any, queryParams: any = null) {
    return this.router.navigate([page], {
      queryParams,
      relativeTo: this.activatedRoute,
      queryParamsHandling: queryParams ? 'merge' : '',
    });
  }

  createUrlTree(queryParams: any = {}) {
    return this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  goBack() {
    // If there is history, use history.back, otherwise try to determine location to navigate to
    const navigationId = history.state?.navigationId || 0; // Normal Ionic navigation
    //const isModal      = history.state?.modal || false;    // Added when Modals are added
    if (navigationId > 1) {
      this._navCtrl.back();
    } else {
      let uri: TabRouteName | string = this.tabService.currentTabName;
      if (!uri) {
        uri = location.pathname;
        uri = uri.substring(0, uri.lastIndexOf('/'));
      }
      this.navigateTo(uri);
    }
  }

  setRoot(tabRouteName: TabRouteName) {
    this.tabService.requestBaseRoute(tabRouteName);
  }

  getFragments() {
    return this.activatedRoute.snapshot.fragment;
  }

  getParams() {
    return this.activatedRoute.snapshot.params;
  }

  getQueryParams() {
    return this.activatedRoute.snapshot.queryParams;
  }
  /**
  * Returns the query parameters as an Observable
  * Use this on the pages you plan to subscribe to the query parameters and use
  * ON PAGE NEEDED:
  * queryParams$: Observable<ParamMap>
  * queryParams.subscribe...
  */
  getQueryParamMap() {
    return this.activatedRoute.queryParamMap;
  }

  getTrans(key: string | string[], params: any = {}) {
    return lastValueFrom(this.translate.get(key, params));
  }

}
