import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { SyzlStarService } from './syzl-star.service';

@Component({
  selector: 'app-syzl-star',
  templateUrl: './syzl-star.component.html',
  styleUrls: ['./syzl-star.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SyzlStarComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() starSize      = 14;
  @Input() percentFilled = 1;
  @Input() fillColor     = '#003DFF';
  @Input() strokeColor   = '#003DFF';
  @Input() inactiveColor = 'none';

  private _clipPath!: SVGClipPathElement;
  private _fill!: SVGRectElement;
  private _stroke!: SVGPathElement;
  private _inactive!: SVGPathElement;
  private _fullWidth = 0;

  private _clipPathId = '';

  @ViewChild('clipPath') private _clipPathEl!: ElementRef;
  @ViewChild('fillShape') private _rectEl!: ElementRef;
  @ViewChild('strokeShape') private _starEl!: ElementRef;
  @ViewChild('inactiveShape') private _inactiveEl!: ElementRef;

  constructor(
    private _syzlStar: SyzlStarService,
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _changeRef: ChangeDetectorRef,
  ) {
    // Ensure we get a unique ID for each star
    this._clipPathId = this._syzlStar.nextStarId();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this._clipPath = this._clipPathEl.nativeElement as SVGClipPathElement;
    this._fill     = this._rectEl.nativeElement as SVGRectElement;
    this._stroke   = this._starEl.nativeElement as SVGPathElement;
    this._inactive = this._inactiveEl.nativeElement as SVGPathElement;

    this._clipPath.id = 'clipPath-' + this._clipPathId;
    this._fill.id     = 'fill-' + this._clipPathId;
    this._stroke.id   = 'stroke-' + this._clipPathId;
    
    (this._fill.attributes as { [key: string]: any })['clip-path'].value = 'url(#' + this._clipPath.id + ')';

    this._setFillColor();
    this._setStrokeColor();
    this._setInactiveColor();
    this._setSize();
    this._setPercentFilled();
    this._changeRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._clipPath) {return;}

    if (changes.fillColor && changes.fillColor.currentValue) {
      this._setFillColor();
    }
    if (changes.strokeColor && changes.strokeColor.currentValue) {
      this._setStrokeColor();
    }
    if (changes.inactiveColor && changes.inactiveColor.currentValue) {
      this._setInactiveColor();
    }
    if (changes.size && changes.size.currentValue) {
      this._setSize();
    }
    if (changes.percentFilled) {
      this._setPercentFilled();
    }
  }

  private _setFillColor() {
    this._fill.style.fill = this.fillColor;
  }

  private _setStrokeColor() {
    if (this.strokeColor && this.strokeColor !== 'none') {
      this._stroke.style.color = this.strokeColor;
    } else {
      this._stroke.style.visibility = 'hidden';
    }
  }

  private _setInactiveColor() {
    this._inactive.style.fill = this.inactiveColor;
  }

  private _setSize() {
    const el = this._el.nativeElement;
    this._renderer.setStyle(el, 'width', this.starSize + 'px');
    this._renderer.setStyle(el, 'height', this.starSize + 'px');
  }

  private _setPercentFilled() {
    if (this._fullWidth === 0) {
      this._fullWidth = this._fill.width.baseVal.value;
    }
    const size              = this._fullWidth * this.percentFilled;
    this._fill.style.width  = size + 'px';
  }
}
