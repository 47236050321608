import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productionTimeFromTotalTime',
  standalone: false,
})
export class ProductionTimeFromTotalTimePipe implements PipeTransform {

  transform(totalTime: number): number {
    if (!totalTime || typeof totalTime !== 'number' || totalTime < 3 || totalTime > 14) {
      return 0;
    }
    return totalTime >= 10 ? totalTime - 2 : totalTime - 1;
  }

}
