import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IModalButton } from '../../i-modal-button';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'app-modal-buttons',
  templateUrl: './modal-buttons.component.html',
  styleUrls: ['./modal-buttons.component.scss'],
  imports: [
    IonicModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalButtonsComponent implements OnChanges {

  @Input() buttons: IModalButton[] = [];
  @Output() buttonClick = new EventEmitter<string>();

  ngOnChanges() {
    if (this.buttons && this.buttons.length > 0) {
      const buttonDefaults = {
        color: 'primary',
        fill: 'solid',
        size: 'small',
      };
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i] = Object.assign({}, buttonDefaults, this.buttons[i]);
      }
    }
  }

  onButtonClick(action: string) {
    this.buttonClick.emit(action);
  }
}
