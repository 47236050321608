// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
}

ion-card {
  margin: 0;
  border-radius: 10px;
}
ion-card.selected {
  border: 1px solid var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/section-preview-card/section-preview-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,SAAA;EACA,mBAAA;AACF;AACE;EACE,0CAAA;AACJ","sourcesContent":[":host {\n  display: block;\n  position:relative;\n}\n\nion-card {\n  margin: 0;\n  border-radius: 10px;\n\n  &.selected {\n    border: 1px solid var(--ion-color-primary);\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
