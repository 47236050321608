import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { Place } from '../services/place.service';
import { PlaceListingFragment } from 'src/generated/graphql';

@Pipe({
  name: 'isPlaceNew',
  standalone: false,
})
export class IsPlaceNewPipe implements PipeTransform {

  transform(place: Place | PlaceListingFragment): boolean {
    let createdAt: Date | null       = place.createdAt;
    let firstApprovedAt: Date | null = place.firstApprovedAt;
    if (!createdAt && place instanceof Place) {
      createdAt = place.get('createdAt');
    }
    if (!firstApprovedAt && place instanceof Place) {
      firstApprovedAt = place.get('firstApprovedAt');
    }
    const date = new Date(firstApprovedAt || createdAt || new Date());
    const diff = DateTime.now().diff(DateTime.fromJSDate(date), 'days').days;
    return diff < 31;
  }
}
