// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host div.top-row {
  padding-bottom: 4px;
}
:host ion-title {
  min-height: 56px;
  align-items: center;
  padding: 0 10px 0 0;
  padding-inline: 0;
}
:host .new-label {
  margin-top: 4px;
}
:host .place-title ion-text {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
:host .facility-detail > .facility-title {
  color: var(--ion-color-grey-700);
  white-space: normal;
}

@media screen and (min-width: 768px) {
  div.top-row {
    padding-bottom: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/inner-page-header/inner-page-header.component.scss"],"names":[],"mappings":"AACI;EACI,mBAAA;AAAR;AAGI;EACI,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;AADR;AAII;EACI,eAAA;AAFR;AAOQ;EACI,oBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;EACA,qBAAA;AALZ;AASI;EACI,gCAAA;EACA,mBAAA;AAPR;;AAWA;EACI;IACI,mBAAA;EARN;AACF","sourcesContent":[":host {\n    div.top-row {\n        padding-bottom: 4px;\n    }\n\n    ion-title {\n        min-height: 56px;\n        align-items: center;\n        padding: 0 10px 0 0;\n        padding-inline: 0;\n    }\n\n    .new-label {\n        margin-top: 4px;\n    }\n\n    .place-title {\n\n        & ion-text {\n            display: -webkit-box;\n            overflow: hidden;\n            text-overflow: ellipsis;\n            white-space: normal;\n            -webkit-box-orient: vertical;\n            -webkit-line-clamp: 2;\n        }\n    }\n    \n    .facility-detail > .facility-title {\n        color: var(--ion-color-grey-700);\n        white-space: normal;\n    }\n}\n\n@media screen and (min-width: 768px) {\n    div.top-row {\n        padding-bottom: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
