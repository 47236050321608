import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { HtmlLoaderService } from './html-loader.service';

@Component({
  standalone: true,
  selector: 'app-html-loader',
  templateUrl: './html-loader.component.html',
  styleUrls: ['./html-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonicModule,
  ],
})
export class HtmlLoaderComponent implements OnInit, OnChanges {

  @Input() source = ''; //pathway to html file within the assets/i18n folder
  @Input() trusted = true;

  safeHtml: SafeHtml = '';

  constructor(
    private _loader: HtmlLoaderService,
    private _ds: DomSanitizer,
    private _change: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }
  
  ngOnChanges() {
    if (!this.source) {
      return;
    }
    this._load();
  }
  
  private _load() {
    this._loader
      .load(this.source, this.trusted)
      .subscribe({
        next: (html: string) => {
          this.safeHtml = this._ds.bypassSecurityTrustHtml(html);
          this._change.markForCheck();
        },
      });
  }
}
