import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { CleaningHoursPipe } from 'src/app/pipes/cleaning-hours.pipe';
import { TimePlusCleaningPipe } from 'src/app/pipes/time-plus-cleaning.pipe';

@Component({
  selector: 'app-pref-number-stepper',
  templateUrl: './pref-number-stepper.component.html',
  styleUrls: ['./pref-number-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PrefNumberStepperComponent implements OnInit, OnChanges {

  @Input() default = 4;
  @Input() min     = 1;
  @Input() max     = 12;
  @Input() current = this.default;
  @Input() includeCleaning = true;
  @Input() unit         = '';
  @Input() unitPlural   = '';
  @Input() maxLimit     = '';
  @Input() minLimit     = '';
  @Output() prefChanged = new EventEmitter<number>();

  private readonly _SINGULAR = 1;

  cleaningTime = 1;
  totalTime    = 0;
  displayUnits = '';

  constructor(
    private _cleaningHoursPipe: CleaningHoursPipe,
    private _timePlusCleaningPipe: TimePlusCleaningPipe,
  ) { }

  ngOnInit() {
    this._calculateCleaning();
    this._calculateDisplay();
    this._calculateTotal();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.current) {
      this.current = changes.current.currentValue;
    }
  }

  increment() {
    this.current = Math.min(this.current + 1, this.max);
    this._calculateCleaning();
    this._calculateDisplay();
    this._calculateTotal();
    this._emit();
  }

  decrement() {
    this.current = Math.max(this.current - 1, this.min);
    this._calculateCleaning();
    this._calculateDisplay();
    this._calculateTotal();
    this._emit();
  }

  private _calculateCleaning() {
    if (this.includeCleaning) {
      this.cleaningTime = this._cleaningHoursPipe.transform(this.current);
    } else {
      this.cleaningTime = 0;
    }
  }

  private _calculateDisplay() {
    this.displayUnits = this.current > this._SINGULAR ? this.unitPlural : this.unit;
  }

  private _calculateTotal() {
    this.totalTime = this._timePlusCleaningPipe.transform(this.current);
  }

  private _emit() {
    this.prefChanged.emit(this.current);
  }
}
