import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client, Client as ConversationClient, Conversation, LastMessage, Message } from '@twilio/conversations';
import { Device as TwilioDevice } from '@twilio/voice-sdk';
import * as Parse from 'parse';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetConversationsBetweenUsersGQL } from 'src/generated/graphql';
import { IRecipient } from '../pages/inbox/components/conversation-preview/conversation-preview.component';
import { User } from './user.service';

export interface IMessageLike {
  author: string | null;
  body:   string | null;
  attributes: any;
  attachedMedia: any[] | null;
}

interface INextBooking {
  objectId:   string;
  startTime:  string;
  endTime:    string;
  status:     string;
  statusEnum: number;
  timeZone:   string;
}

@Injectable({
  providedIn: 'root',
})
export class ConversationsService {

  static readonly MESSAGE_TYPE_AUTO_CANCEL   = 'autoCancel';
  static readonly MESSAGE_TYPE_DENY          = 'deny';
  static readonly MESSAGE_TYPE_CANCEL        = 'cancel';
  static readonly MESSAGE_TYPE_CONFIRM       = 'confirm';
  static readonly MESSAGE_TYPE_UPDATED_TIMES = 'updatedTimes';
  static readonly MESSAGE_TYPE_REQUESTED     = 'requested';

  private readonly _FIVE_MINUTES = 1000 * 60 * 5;

  private _conversationClient: Client | null = null;
  private _accessToken: any;
  private _statusString$     = new BehaviorSubject<string>('');
  private _conversationList$ = new BehaviorSubject<Conversation[]>([]);
  private _messageSubject$   = new Subject<Message>();
  private _unreadCount$      = new BehaviorSubject<number>(0);
  private _participantCache: {
    [key: string]: {
      userId: string;
      firstName: string;
      name: string;
      photo: Parse.File;
    };
  } = {};
  private _nextBookingCache: {
    [key: string]: {
      lastCheckedAt: number,
      data: INextBooking | null
    }
  } = {};
  private _device: TwilioDevice | null = null;

  private _isInitialied = false;
  private _bookingCache: {
    [key: string]: boolean;
  } = {};

  constructor(
    private _http: HttpClient,
    private _conversationBetweenUsers: GetConversationsBetweenUsersGQL,
  ) {
    window.addEventListener('user:login', async () => {
      this._accessToken = null;
      
      await this.getAccessToken();
    });
  
    window.addEventListener('user:logout', () => {
      this._accessToken = null;
      this._conversationClient?.shutdown();
      this._conversationClient = null;
    });
  }
  //Getters

  get conversationClient() {
    return this._conversationClient;
  }

  get twilioDevice() {
    return this._device;
  }

  get accessToken() {
    return this._accessToken;
  }

  get statusString$() {
    return this._statusString$.asObservable();
  }

  get conversationList$() {
    return this._conversationList$.asObservable();
  }

  get message$() {
    return this._messageSubject$.asObservable();
  }

  get unreadCount$() {
    return this._unreadCount$.asObservable();
  }

  async init() {
    try {
      if (User.getCurrent().get('firstName')) {
        this.getAccessToken();
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Sends a Parse request to API to retrieve an AccessToken from Twilio
   * Creates a new instance of a client
   */
  async getAccessToken() {
    if (!this._accessToken) {
      try {
        this._accessToken = await Parse.Cloud.run('getTwilioAccessToken');

        if (!this._accessToken) {
          return;
        }
        await this._initializeConversationClient(this._accessToken);
        this._initializeTwilioDevice(this._accessToken);

        this._handleTokenExpiry();
        this._isInitialied = true;

      } catch (error) {
        console.error('Error during initialization: ', error);
      }
    }
  }

  private async _initializeConversationClient(token: string): Promise<void> {
    this._conversationClient = new ConversationClient(token);
    this._listenToConnectionStatus();
    this._listenToConversations();
    this._listenToConversationMessages();

    // Load existing conversations
    let conversations: Conversation[] = [];
    let convos = await this._conversationClient.getSubscribedConversations();
    conversations = conversations.concat(convos.items);
    while (convos.hasNextPage) {
      convos = await convos.nextPage();
      conversations = conversations.concat(convos.items);
    }
    this._conversationList$.next([...this.sortConversationsByNewestMessage(conversations)]);
    this._updateUnreadCount(this._conversationList$.getValue());
  }

  private _initializeTwilioDevice(token: string): void {
    this._device = new TwilioDevice(token);
  }

  private async _handleTokenExpiry() {
    this._conversationClient?.on('tokenAboutToExpire', async () => {
      this._accessToken = await Parse.Cloud.run('getTwilioAccessToken');
      if (this._accessToken) {
        this._conversationClient?.updateToken(this._accessToken);
        this._device?.updateToken(this._accessToken);
      }
    });

    this._device?.on('tokenAboutToExpire', async () => {
      this._accessToken = await Parse.Cloud.run('getTwilioAccessToken');
      if (this._accessToken) {
        this._conversationClient?.updateToken(this._accessToken);
        this._device?.updateToken(this._accessToken);
      }
    });
  }

  /**
   * Listens to the connection state of the Client created
   * which then changes the status string based off of the state
   */
  private _listenToConnectionStatus() {
    this._conversationClient?.on('connectionStateChanged', (state) => {
      this._statusString$.next(state);
    });
  }
  /**
   * All conversations are fetched when first called 
   * THEN listens to new conversations added
   */
  private _listenToConversations() {
    this._conversationClient?.on('conversationAdded', (conversation) => {
      if(!this._isInitialied){
        return;
      }
      const conversations       = this._conversationList$.getValue();
      conversations.push(conversation);
      this._conversationList$.next([...this.sortConversationsByNewestMessage(conversations)]);
      this._updateUnreadCount(this._conversationList$.getValue());
    });

    this._conversationClient?.on('conversationUpdated', (conversation) => {
      const conversations       = this._conversationList$.getValue();
      const index               = conversations.findIndex(convo => convo.sid === conversation.conversation.sid);
      // If the conversation isn't in the array, add it
      if (index < 0) {
        conversations.push(conversation.conversation);
      } else {
        for (let i = 0; i < conversations.length; i++) {
          if (conversations[i].sid === conversation.conversation.sid) {
            conversations[i] = conversation.conversation;
            break;
          }
        }
      }
      const sortedConversations = this.sortConversationsByNewestMessage(conversations);
      this._conversationList$.next(sortedConversations);
      // in a time out to give Twillio time to update
      setTimeout(() => this._updateUnreadCount(sortedConversations), 1000);
    });
  }

  private async _updateUnreadCount(conversations: Conversation[]) {

    const requests = conversations.map(async (conversation) => {
      const unread           = await conversation.getUnreadMessagesCount();
      const lastMessageIndex = conversation.lastMessage?.index || 0;
      const isStartedByMe    = (conversation.attributes as any)?.firstMessageAuthor === User.getCurrent().id;

      // null means this is the first message (booking request, direct message)
      const unreadNum = unread !== null
        ? unread
        : lastMessageIndex === 0 && isStartedByMe
          ? 0
          : 1;
      return unreadNum > 0 ? 1 : 0;
    });
    
    const unreadCounts = await Promise.all(requests);

    const unreadCount = unreadCounts.reduce((prev: number, cur: number) => prev + cur, 0);

    this._unreadCount$.next(unreadCount);
  }

  private _listenToConversationMessages() {
    this._conversationClient?.on('messageAdded', (message) => {
      this.updateConversationMessageIndex(message.conversation.sid, message.index);
      this._messageSubject$.next(message);
    });
  }

  async getConversationsBetweenUsers(user1: string, user2: string) {
    return lastValueFrom(this._conversationBetweenUsers.fetch({ user1, user2 }, { fetchPolicy: 'no-cache' }));
  }
  
  async getConversationInformation(conversationSid: string): Promise<IRecipient> {
    return new Promise((resolve, _) => {
      if (this._participantCache[conversationSid]) {
        resolve(this._participantCache[conversationSid]);
      } else {
        Parse.Cloud.run('getConversationParticipants', { conversationSid }).then((result) => {
          if (result.userId !== '') {
            this._participantCache[conversationSid] = result;
          }
          resolve(this._participantCache[conversationSid]);
        }).catch((error) => {
          console.error(error);
          resolve({
            userId: '',
            firstName: '',
            name: '',
            photo: null,
          });
        });
      }
    });
  }

  async checkBookingForConversation(conversationSid: string): Promise<boolean> {
    return new Promise(async (resolve, _) => {
      if (this._bookingCache[conversationSid]) {
        resolve(this._bookingCache[conversationSid]);
      } else {
        try {
          this._bookingCache[conversationSid] = await this.checkBookings(conversationSid);
          resolve(this._bookingCache[conversationSid]);
        } catch (error) {
          console.error(error);
          resolve(false);
        }
      }
    });
  }

  async getConversationBySid(conversationSid: string): Promise<Conversation | undefined> {
    try {
      const conversation = await this._conversationClient?.getConversationBySid(conversationSid);
      return conversation;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getConversationSidByPlaceAsMakr(placeId: string): Promise<string> {
    const conversationSid = await Parse.Cloud.run('getConversationSidByPlaceAsMakr', { placeId });
    return conversationSid;
  }

  async getConversationSidByPlaceAsHost(placeId: string, primaryUserId: string): Promise<string> {
    const conversationSid = await Parse.Cloud.run('getConversationSidByPlaceAsHost', { placeId, primaryUserId });
    return conversationSid;
  }

  updateConversationMessageIndex(conversationSid: string, messageIndex: number) {
    const updatedArray = this._conversationList$.getValue();
    const index        = updatedArray.findIndex(convo => convo.sid === conversationSid);
    if (updatedArray[index].lastMessage) {
      (updatedArray[index].lastMessage as LastMessage).index = messageIndex;
    }

    this._conversationList$.next([...updatedArray]);
  }

  sortConversationsByNewestMessage(conversations: Conversation[]): Conversation[] {
    return conversations.sort((a: Conversation, b: Conversation) => (
      b.lastMessage?.dateCreated && b.lastMessage?.dateCreated?.getTime() || 0) - (a.lastMessage?.dateCreated  && a.lastMessage?.dateCreated?.getTime()
      || 0
    ));
  }

  async messageHost(placeId: string, placeTitle: string, hostId: string, message: string, host: boolean) {
    return await Parse.Cloud.run('messageHost', { placeId, placeTitle, hostId, message, host });
  }

  async messageMakr(placeId: string, placeTitle: string, makrId: string, message: string, host: boolean) {
    return await Parse.Cloud.run('messageMakr', { placeId, placeTitle, makrId, message, host });
  }

  async checkConnection() {
    const options = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      }),
    };


    return new Promise((resolve, _) => {

      this._http.get(`${environment.serverRootUrl}/twilio/checkConnection`, options).subscribe({
        next:  _   => resolve(true),
        error: (error) => {
          console.error(error);
          resolve(false);
        },
      });
    });
  }

  async checkBookings(conversationSid: string) {
    return await Parse.Cloud.run('checkBookings', { conversationSid });
  }
  
  /**
   * getUpcomingOrMostRecentBooking
   * - Gets the closest future booking (based on end date) OR most recently passed booking
   * @param conversationSid 
   * @returns 
   */
  getUpcomingOrMostRecentBooking(conversationSid: string) {
    return new Promise<INextBooking | null>(async (resolve) => {
      const cache = this._nextBookingCache[conversationSid];
      if (!cache || cache.lastCheckedAt < new Date().getTime() - this._FIVE_MINUTES) {
        const nextBooking = await Parse.Cloud.run('getUpcomingOrMostRecentBooking', { conversationSid });
        this._nextBookingCache[conversationSid] = {
          lastCheckedAt: new Date().getTime(),
          data:          nextBooking,
        };
      }
      resolve(this._nextBookingCache[conversationSid].data);
    });
  }
}
