import { Pipe, PipeTransform } from '@angular/core';
import { LocationAddress } from '../interfaces/location-address';
import { Place } from '../services/place.service';

@Pipe({
  name: 'distance',
  standalone: false,
})
export class DistancePipe implements PipeTransform {

  transform(place: Place, location: LocationAddress, unit: string): any {
    return place.distance(location, unit);
  }

}
