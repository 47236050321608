import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { DeletePlaceClosedTimeGQL, GetPlaceClosedTimesGQL, InsertPlaceClosedTimeGQL, Scalars, UpdatePlaceClosedTimeGQL } from 'src/generated/graphql';
import { ClosedDateAndTime } from '../pages/booking-closed-dates-and-times/closed-date-and-time';
import { BookingStatusService } from './booking-status.service';

@Injectable({
  providedIn: 'root',
})
export class BookingClosedDatesAndTimesService {

  private _items: ClosedDateAndTime[] = [];
  private _closedDatesAndTimesSubject = new BehaviorSubject<ClosedDateAndTime[]>([]);

  constructor(
    private _load: GetPlaceClosedTimesGQL,
    private _add: InsertPlaceClosedTimeGQL,
    private _update: UpdatePlaceClosedTimeGQL,
    private _delete: DeletePlaceClosedTimeGQL,
  ) { }

  get closedDatesAndTimes() {
    return this._closedDatesAndTimesSubject;
  }

  /**
   * Loads the closed items from the DB
   * @param placeId 
   */
  load(placeId: Scalars['ID'], timeZone: string) {
    const startOfDay = DateTime.fromJSDate(new Date(), { zone: timeZone }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return this._load
      .fetch({
        where: {
          place: {
            have: {
              objectId: {
                equalTo: placeId,
              },
            },
          },
          status: {
            have: {
              enum: {
                equalTo: BookingStatusService.ENUM_CLOSED_BY_HOST,
              },
            },
          },
          startTime: {
            greaterThanOrEqualTo: startOfDay.toJSDate(),
          },
        },
      },
      {
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next: (response) => {
          if (response.data.bookings.edges) {
            this._items = response.data.bookings.edges.map((item) => {
              const node      = item?.node;
              const startTime = DateTime.fromJSDate(new Date(node?.startTime), { zone: timeZone });
              const endTime   = DateTime.fromJSDate(new Date(node?.endTime),   { zone: timeZone });
              const yyyyMmDd  = startTime.toFormat('yyyy-MM-dd');
              const fromTime  = startTime.toFormat('HH');
              const toTime    = endTime.toFormat('HH');
              const eMinute   = endTime.minute;
              return new ClosedDateAndTime(node?.objectId || '', yyyyMmDd, fromTime, toTime + (eMinute === 59 ? ':59' : ''));
            });
            this._sortItems();
          } else {
            this._items = [];
          }
          this._emit();
        },
        error: (error) => {
          console.warn(error);
          this._items = [];
          this._emit();
        },
      });
  }

  /**
   * Adds or updates an item from the DB and outputs the updated array
   * @param placeId 
   * @param userId 
   * @param statusId 
   * @param closedDateAndTime 
   */
  save(placeId: Scalars['ID'], timeZone: string, userId: Scalars['ID'], statusId: Scalars['ID'], closedDateAndTime: ClosedDateAndTime) {
    const dateTime  = closedDateAndTime.getDateTime(timeZone);
    const startTime = dateTime.set({ hour: parseInt(closedDateAndTime.fromTime, 10) }).toJSDate();
    const endTime   = closedDateAndTime.toTime === ClosedDateAndTime.END_OF_DAY_TIME
      ? dateTime.set({ hour: 23, minute: 59 }).toJSDate()
      : dateTime.set({ hour: parseInt(closedDateAndTime.toTime, 10) }).toJSDate();

    if (closedDateAndTime.objectId && closedDateAndTime.objectId !== '') {
      this._update
        .mutate({
          input: {
            id: closedDateAndTime.objectId,
            fields: {
              startTime,
              endTime,
            },
          },
        })
        .subscribe({
          next: (response) => {
            if (response.data) {
              const index        = this._getIndex(closedDateAndTime);
              this._items[index] = closedDateAndTime;
              this._sortItems();
              this._emit();
            }
          },
          error: error => console.warn(error),
        });
    } else {
      this._add
        .mutate({
          input: {
            fields: {
              bookingSession: {
                createAndLink: {
                  user: {
                    link: userId,
                  },
                  totalCost: 0,
                },
              },
              place: {
                link: placeId,
              },
              primaryUser: {
                link: userId,
              },
              status: {
                link: statusId,
              },
              cleanTime: 0,
              startTime,
              endTime,
              timeSelected: true,
            },
          },
        })
        .subscribe({
          next: (response) => {
            if (response.data) {
              closedDateAndTime.objectId = response.data?.createBooking?.booking.objectId || '';
              this._items.push(closedDateAndTime);
              this._sortItems();
              this._emit();
            }
          },
          error: error => console.warn(error),
        });
    }
  }

  /**
   * Removes an item from the DB and outputs the updated array
   * @param closedDateAndTime 
   */
  remove(closedDateAndTime: ClosedDateAndTime) {
    this._delete
      .mutate({
        input: {
          id: closedDateAndTime.objectId,
        },
      }, {
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next: (response) => {
          if (response.data) {
            const index = this._getIndex(closedDateAndTime);
            this._items.splice(index, 1);
            this._emit();
          }
        },
        error: error => console.warn(error),
      });
  }

  /**
   * Emits the next value for the _closedDatesAndTimesSubject BehaviorSubject
   */
  private _emit() {
    this._closedDatesAndTimesSubject.next([...this._items]);
  }

  /**
   * Returns the item's index within the array
   * @param closedDateAndTime 
   * @returns 
   */
  private _getIndex(closedDateAndTime: ClosedDateAndTime) {
    return this._items.findIndex(item => item.objectId === closedDateAndTime.objectId);
  }

  /**
   * Sorts the array based on date and startTime
   */
  private _sortItems() {
    this._items.sort((a, b) => {
      if (a.yyyyMmDd === b.yyyyMmDd) {return a.fromTime < b.fromTime ? -1 : 1;}
      return a.yyyyMmDd < b.yyyyMmDd ? -1 : 1;
    });
  }
}
