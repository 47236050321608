import { Injectable } from '@angular/core';
import { UserWebhookResultCheckerService } from './user-webhook-result-checker.service';
import { User } from './user.service';
import { Subject } from 'rxjs';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root',
})
export class StripeIdentityService {

  static readonly STATUS_NONE       = 'None';
  static readonly STATUS_FAILED     = 'FAIL';
  static readonly STATUS_PASSED     = 'PASS';
  static readonly STATUS_PENDING    = 'PENDING';

  private readonly _IDENTITY_STATUS = 'identityStatus';

  private _watchingValue: string | null = null;
  private _identityStatusChange$ = new Subject<string>();

  constructor(
    private _userWebhookResultChecker: UserWebhookResultCheckerService,
  ) { }

  get identityStatusChange$() {
    return this._identityStatusChange$.asObservable();
  }

  get isUnstarted() {
    return !this._watchingValue && !this._user.get(this._IDENTITY_STATUS)
        || this._user.get(this._IDENTITY_STATUS) === StripeIdentityService.STATUS_NONE;
  }

  get isPending() {
    return this._watchingValue                   === StripeIdentityService.STATUS_PENDING
        || this._user.get(this._IDENTITY_STATUS) === StripeIdentityService.STATUS_PENDING;
  }

  get isFailed() {
    return this._watchingValue                   === StripeIdentityService.STATUS_FAILED
        || this._user.get(this._IDENTITY_STATUS) === StripeIdentityService.STATUS_FAILED;
  }

  get isPassed() {
    return this._watchingValue                   === StripeIdentityService.STATUS_PASSED
        || this._user.get(this._IDENTITY_STATUS) === StripeIdentityService.STATUS_PASSED;
  }
  
  get isComplete() {
    return this.isFailed || this.isPassed;
  }

  userPropChanged = (newValue: string) => {
    const stopWatching = newValue === StripeIdentityService.STATUS_NONE
                      || newValue === StripeIdentityService.STATUS_FAILED
                      || newValue === StripeIdentityService.STATUS_PASSED;

    if (stopWatching) {
      this._watchingValue = null;
    }
    if (newValue !== this._watchingValue) {
      this._identityStatusChange$.next(newValue);
    }
    return stopWatching;
  };

  getStripeIdentityCheckSecret() {
    return Parse.Cloud.run('getStripeIdentityCheckSecret');
  }

  watchUser(initialValue?: string) {
    if (initialValue) {
      this._watchingValue = initialValue;
      this._identityStatusChange$.next(initialValue);
    }
    if (this.isPending) {
      this._userWebhookResultChecker.watchProp(this._IDENTITY_STATUS, 30000, this.userPropChanged);
    }
  }

  private get _user() {
    return User.getCurrent();
  }
}
