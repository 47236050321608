import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
  ],
})
export class ReadMoreComponent implements OnInit {

  private readonly _TEXT_LIMIT = 140;

  isCollapsed = true;
  textLimit   = this._TEXT_LIMIT;
  wholeText   = '';
  collapsedText = '';
  show = false;
  @Input() text = '';
  constructor() { }

  ngOnInit() {
    const textLength = this.text.length;
    this.wholeText = this.text;
    this.collapsedText = this.text.substring(0, this.textLimit) + '...';
    if (textLength > this.textLimit) {
      this.show        = true;
      this.isCollapsed = true;
    } else {
      this.isCollapsed = false;
      this.show        = false;
    }
  }

}


