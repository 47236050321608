import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Place } from '../../services/place.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'info-window',
  templateUrl: './info-window.html',
  styleUrls: ['./info-window.scss'],
  standalone: false,
})
export class InfoWindowComponent {

  @Input() place!: Place;
  @Input() location: any;
  @Input() unit!: string;

  @Output() buttonTouched: EventEmitter<Place> = new EventEmitter<Place>();

  constructor() {
  }

  goToPlace() {
    this.buttonTouched.emit(this.place);
  }

}
