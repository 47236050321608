import { Pipe, PipeTransform } from '@angular/core';
import { ParseFile } from '../services/parse-file-service';

@Pipe({
  name: 'parseFileName',
  standalone: false,
})
export class ParseFileNamePipe implements PipeTransform {

  transform(value: ParseFile): string {
    const fileName = value.name();
    return fileName.substring(fileName.indexOf('_') + 1, fileName.length);
  }

}
