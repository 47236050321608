/* eslint-disable @stylistic/no-extra-parens */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IonicModule, IonTextarea } from '@ionic/angular';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { SharedModule } from 'src/app/shared.module';
import { IModalDetails } from '../i-modal-details';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalButtonsComponent } from '../components/modal-buttons/modal-buttons.component';
import { FormsModule } from '@angular/forms';
import { BookingMessageReasonInfoFragment, GetBookingMessageReasonsGQL } from 'src/generated/graphql';
import { ModalStatics } from 'src/app/services/modal.service';

@Component({
  standalone: true,
  selector: 'app-modal-cancellation',
  templateUrl: './modal-cancellation.component.html',
  styleUrls: ['./modal-cancellation.component.scss'],
  imports: [
    IonicModule,
    SharedModule,
    FormsModule,
    ModalButtonsComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCancellationComponent extends BasePageModal implements OnInit {

  @Input() modalDetails!: IModalDetails;
  @Input() userType: string = '';

  safeHtml: SafeHtml | null = null;
  msg = '';
  reason: BookingMessageReasonInfoFragment | null = null;
  showError = false;

  hostCancellationReasons: BookingMessageReasonInfoFragment[]  = [];
  makerCancellationReasons: BookingMessageReasonInfoFragment[] = [];

  @ViewChild('ionItem', { read: ElementRef }) private _ionItem!: ElementRef;
  @ViewChild(IonTextarea) private _textArea!: IonTextarea;

  readonly KEY_OTHER = 'CANCELLATION_REASONS.OTHER';
  
  constructor(
    injector: Injector,
    private _getBookingMessageReasonsGQL: GetBookingMessageReasonsGQL,
    private _sanitizer: DomSanitizer,
    private _renderer: Renderer2,
    private _changeRef: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit() {
    this._getBookingMessageReasons();
    const modalDetailsDefaults = { required: true };
    this.modalDetails.select = Object.assign({}, modalDetailsDefaults, this.modalDetails.select);
    this.safeHtml = this._sanitizer.bypassSecurityTrustHtml(this.modalDetails.content);
  }

  async selectReason(e: any) {
    this.showError = false;
    this.reason    = e.detail.value as BookingMessageReasonInfoFragment;

    this.modalDetails.textarea = Object.assign({}, this.modalDetails.textarea, { required: this.reason.key === 'CANCELLATION_REASONS.OTHER' });
    this.modalDetails          = { ...this.modalDetails };
  }

  async buttonClick(action: string) {
    if (action === ModalStatics.GO_BACK) {
      this.modalCtrl.dismiss();
      return;

    } else if (action === ModalStatics.CONFIRM) {
      if (!this.reason || this.reason.key === this.KEY_OTHER && !this.msg) {
        this.showError = true;
        return;

      } else if (this.reason || ((this.reason as BookingMessageReasonInfoFragment)?.key === this.KEY_OTHER && this.msg)) {
        this.modalCtrl.dismiss({
          action,
          reason: this.reason.objectId,
          msg: this.msg ? this.msg : await this.getTrans(this.reason.key),
        });
      }

    } else {
      this._renderer.addClass(this._ionItem.nativeElement, 'invalid');
      this._textArea.setFocus();
    }
    this._changeRef.markForCheck();
  }

  private async _getBookingMessageReasons() {
    this._getBookingMessageReasonsGQL
      .fetch()
      .subscribe({
        next: (response) => {
          const nodes                   = response.data.bookingMessageReasons.edges?.map(edge => edge?.node) || [];
          this.hostCancellationReasons  = nodes?.filter(node => node?.forHost)  as BookingMessageReasonInfoFragment[] || [];
          this.makerCancellationReasons = nodes?.filter(node => node?.forMaker) as BookingMessageReasonInfoFragment[] || [];
          this._changeRef.markForCheck();
        },
      });
  }
}
