import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-equipment-accordion',
  templateUrl: './section-equipment-accordion.component.html',
  styleUrls: ['./section-equipment-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SectionEquipmentAccordionComponent implements OnInit {

  @Input() items: any[] = [];

  constructor() { }

  ngOnInit() {}
}
