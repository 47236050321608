import { Directive, Output, Input, EventEmitter, Optional } from '@angular/core';
import PhotoSwipe from 'photoswipe';

import { PhotoGalleryConfig } from '../interfaces/config';
import { GalleryImage, GalleryItem, GalleryOptions } from '../interfaces/photoswipe';
import { LightboxService } from '../services/lightbox.service';

export const DEFAULT_OPTIONS = {
  history: false,
  closeEl: true,
  captionEl: false,
  fullscreenEl: false,
  zoomEl: true,
  shareEl: false,
  counterEl: true,
  arrowEl: false,
  preloaderEl: true,
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[photoGalleryGroup]',
  standalone: false,
})
export class PhotoGalleryGroupDirective {

  @Input('photoGalleryGroup') options: GalleryOptions | '' = '';
  @Output() photoGalleryInit    = new EventEmitter<any>();
  @Output() photoGalleryDestroy = new EventEmitter();

  private _defaultOptions: GalleryOptions;
  private _gallery: any;
  private _galleryItems: { [key: string]: GalleryItem } = {};
  private _galleryItemIds: Set<string> = new Set<string>();
  private _galleryImages: GalleryImage[] = [];

  constructor(
    @Optional() private _photoGalleryConfig: PhotoGalleryConfig,
    private _lightboxService: LightboxService,
  ) {
    this._defaultOptions = { ...DEFAULT_OPTIONS, ...this._photoGalleryConfig?.defaultOptions };
  }

  registerGalleryItem(item: { id: string; element: HTMLElement; imageUrl: string; caption?: string }): void {
    const image: GalleryImage = {
      id: item.id,
      src: item.imageUrl,
      ...item.caption ? { title: item.caption } : {},
      w: 0,
      h: 0,
      doGetSlideDimensions: true,
    };
    this._galleryItems[item.id] = {
      id: item.id,
      element: item.element,
      image,
    };

    this._galleryItemIds.add(item.id);
  }

  unregisterGalleryItem(id: string): void {
    this._galleryItemIds.delete(id);
  }

  async openPhotoSwipe(id: string): Promise<void> {
    if (this._galleryItems[id].image.doGetSlideDimensions) {
      try {
        const targetImage = await this._loadImage(this._galleryItems[id].image.src);
        this._galleryItems[id].image.w = targetImage.naturalWidth;
        this._galleryItems[id].image.h = targetImage.naturalHeight;
        delete this._galleryItems[id].image.doGetSlideDimensions;
      } catch (error) {
        console.error('Error loading image', error);
      }
    }

    this._galleryImages = [...this._galleryItemIds].map(key => this._galleryItems[key].image);
    const idx = this._galleryImages.findIndex(image => image.id === id);
    const options: GalleryOptions = { ...this._defaultOptions, ...this.options };
    options.index = idx;
    options.getThumbBoundsFn = (imageIndex: number) => {
      const key = this._galleryImages[imageIndex].id;
      const thumbnail = this._galleryItems[key].element;
      const origin = this._galleryItems[key].image;
      const pageYScroll = window.scrollY || document.documentElement.scrollTop;
      const rect = thumbnail.getBoundingClientRect();

      const thumbnailRate = rect.height / rect.width;
      const originRate = origin.h / origin.w;
      let x: number; let y: number; let w: number;
      if (thumbnailRate > originRate) {
        // portrait
        y = rect.top + pageYScroll;
        w = origin.w * rect.height / origin.h;
        x = rect.left - (w - rect.width) / 2;
      } else {
        // landscape
        const imageHeight = origin.h * rect.width / origin.w;
        x = rect.left;
        w = rect.width;
        y = rect.top + pageYScroll - (imageHeight - rect.height) / 2;
      }

      return { x, y, w };
    };

    
    this._gallery = new PhotoSwipe({ dataSource: this._galleryImages });
    this._gallery.listen('gettingData', (_: any, slide: any) => {
      if (slide.doGetSlideDimensions) {
        setTimeout(async () => {
          await this._getSlideDimensions(slide);
        }, 300);
      }
    });
    this._gallery.listen('imageLoadComplete', async (_: any, slide: any) => {
      if (slide.doGetSlideDimensions) {
        await this._getSlideDimensions(slide);
      }
    });
    this._gallery.listen('destroy', () => {
      this.photoGalleryDestroy.emit();
    });
    this.photoGalleryInit.emit(this._gallery);
    this._gallery.init();
  }

  private async _getSlideDimensions(slide: GalleryImage): Promise<void> {
    if (!slide.doGetSlideDimensions) {
      return;
    }

    const image: HTMLImageElement | null = await this._loadImage(slide.src).catch(() => null);

    slide.doGetSlideDimensions = false;

    slide.w = image?.naturalWidth  || 0;
    slide.h = image?.naturalHeight || 0;

    this._gallery.invalidateCurrItems();
    this._gallery.updateSize(true);
  }

  private async _loadImage(path: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = e => reject(e);
      image.src = path;
    });
  }
}
