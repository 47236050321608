// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 0;
}

ion-input {
  --color: var(--ion-color-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-content-with-email/modal-content-with-email.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ","sourcesContent":["ion-card {\n    border-radius: 0;\n}\n\nion-input {\n    --color: var(--ion-color-dark);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
