import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { Place } from 'src/app/services/place.service';
import { ConversationEdge } from 'src/generated/graphql';

@Component({
  selector: 'app-modal-conversations',
  templateUrl: './modal-conversations.component.html',
  styleUrls: ['./modal-conversations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ModalConversationsComponent extends BasePageModal {
  @Input() conversations: ConversationEdge[] | null = [];
  @Input() usersName = '';
  @Input() places: any | null = null;
  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  async navigateToConvo(conversationSid: string) {
    this.modalCtrl.dismiss();
    this.navigateTo(`${this.tabService.currentTabUrl}/conversation/${conversationSid}`);
  }

  async startConversation(place: Place) {
    const payload = {
      placeId: place.id,
      placeTitle: place.title,
      hostId: place.get('user').id,
      hostName: this.usersName,
    };
    window.dispatchEvent(new CustomEvent('conversation:start', { detail: { payload } }));
  }

}
