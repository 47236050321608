import { Pipe, PipeTransform, inject } from '@angular/core';
import { TabService } from '../services/tab.service';

@Pipe({
  name: 'tabServiceNormalizeRoute',
  standalone: false,
})
export class TabServiceNormalizeRoutePipe implements PipeTransform {

  private _tabService = inject(TabService);

  transform(route: string): string {
    return this._tabService.normalizeRoute(route);
  }

}
