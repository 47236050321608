import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Injector } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { User } from 'src/app/services/user.service';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { TabRouteName } from 'src/app/services/tab.service';

@Component({
  standalone: true,
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    DirectivesModule,
  ],
})
export class VerificationComponent extends BasePageModal implements OnInit {

  @Input() iconType   = '';

  user!: User;
  identityStatus     = '';
  criminalStatus     = '';
  insuranceStatus    = '';
  foodHandlingStatus = '';
  identity           = false;
  criminal           = false;
  insurance          = false;
  foodHandling       = false;
  identityPath       = 'identity-verification';
  criminalPath       = 'identity-verification';
  insurancePath      = 'documentation';
  foodHandlingPath   = 'documentation';
  complete           = 'COMPLETE';
  edit               = 'EDIT';


  constructor(
    private _injector: Injector,
  ) {
    super(_injector);
  }

  ngOnInit() {
    this.user           = User.getCurrent();
    this.identityStatus = this.user.identityStatus;
    this.criminalStatus = this.user.certnScore;
    this.insuranceStatus = this.user.insuranceStatus;
    this.foodHandlingStatus = this.user.foodSafetyStatus;

    this.identityCheck(this.identityStatus);
    this.criminalCheck(this.criminalStatus);
    this.insuranceCheck(this.insuranceStatus);
    this.foodHandlingCheck(this.foodHandlingStatus);
  }

  identityCheck(check: string) {
    if (check === 'PASS') {
      this.iconType = 'color';
      this.identity = true;
    }
  }

  criminalCheck(check: string) {
    if (check === 'PASS') {
      this.iconType = 'color';
      this.criminal = true;
    }
  }

  insuranceCheck(check: string) {
    if (check === 'PASS') {
      this.iconType = 'color';
      this.insurance = true;
    }
  }

  foodHandlingCheck(check: string) {
    if (check === 'PASS') {
      this.iconType = 'color';
      this.foodHandling = true;
    }
  }

  onNavigate(path: string) {
    this.navigateTo(this.tabService.normalizeRoute(`${this.tabService.currentTabUrl}/${TabRouteName.profile}/${path}`));
  }

}
