import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace',
  standalone: false,
})
export class StringReplacePipe implements PipeTransform {

  transform(str: string, searchFor: string, replaceWith: string): string {
    return str.replace(searchFor, replaceWith);
  }

}
