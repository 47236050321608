import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'timeOnly',
  standalone: false,
})
export class TimeOnlyPipe implements PipeTransform {

  transform(date: Date | null): string {
    if (!date) {
      return '';
    }
    const luxonDate = DateTime.fromJSDate(date);
    const isSameDay = luxonDate.hasSame(DateTime.local(), 'day');
    
    return isSameDay ? luxonDate.toFormat('h:mm a') : luxonDate.toFormat('LLL d');
  }

}
