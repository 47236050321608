import { Pipe, PipeTransform } from '@angular/core';

interface IPlaceLike {
  id: string;
  name?: string;
  title?: string;
}

@Pipe({
  name: 'placeNameFromId',
  standalone: false,
})
export class PlaceNameFromIdPipe implements PipeTransform {

  transform(placeId: string, places: IPlaceLike[]): string {
    const place = places.find(item => item.id === placeId);
    return place ? place.name || place.title || '' : '';
  }

}
