import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-syzl-star-bar',
  templateUrl: './syzl-star-bar.component.html',
  styleUrls: ['./syzl-star-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SyzlStarBarComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() starSize      = 24;
  @Input() starGap       = 2;
  @Input() isHorizontal  = true;
  @Input() isInteractive = false;
  @Input() mode          = '';
  @Input() rating        = 0;
  @Input() overall       = 0;
  @Input() cleanliness   = 0;
  @Input() communication = 0;
  @Input() stars         = 5;
  @Input() fillColor     = '#003DFF';
  @Input() strokeColor   = '#003DFF';
  @Input() inactiveColor = 'none';

  @Output() ratingChange        = new EventEmitter<number>();
  @Output() overallChange       = new EventEmitter<number>();
  @Output() cleanlinessChange   = new EventEmitter<number>();
  @Output() communicationChange = new EventEmitter<number>();

  starFilled = 0;
  hovered    = 0;
  numStars: number[] = [];

  readonly MODE_OVERALL       = 'overall';
  readonly MODE_CLEANLINESS   = 'cleanliness';
  readonly MODE_COMMUNICATION = 'communication';

  constructor(
    private _changeRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    switch (this.mode) {
      case this.MODE_OVERALL:
        this.starFilled = this.overall;
        break;

      case this.MODE_CLEANLINESS:
        this.starFilled = this.cleanliness;
        break;

      case this.MODE_COMMUNICATION:
        this.starFilled = this.communication;
        break;

      default:
        this.starFilled = this.rating;
        break;
    }
  }

  ngAfterViewInit(): void {
    this._createStarsArray();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stars && changes.stars.currentValue) {
      this._createStarsArray();
    }

    if (changes.rating && changes.rating.currentValue) {
      this.starFilled = changes.rating.currentValue;
    }

    if (changes.overall && changes.overall.currentValue) {
      this.starFilled = changes.overall.currentValue;
    }

    if (changes.cleanliness && changes.cleanliness.currentValue) {
      this.starFilled = changes.cleanliness.currentValue;
    }

    if (changes.communication && changes.communication.currentValue) {
      this.starFilled = changes.communication.currentValue;
    }
  }

  onStarHover(star: number) {
    if (!this.isInteractive) {return;}
    this.hovered = star;
    this._changeRef.markForCheck();
  }

  onRatingChange(star: number) {
    if (!this.isInteractive) {return;}

    switch (this.mode) {
      case this.MODE_OVERALL:
        this.overall = star;
        this.overallChange.emit(star);
        break;

      case this.MODE_CLEANLINESS:
        this.cleanliness = star;
        this.cleanlinessChange.emit(star);
        break;

      case this.MODE_COMMUNICATION:
        this.communication = star;
        this.communicationChange.emit(star);
        break;
    }

    this.starFilled = star;
  }

  private _createStarsArray() {
    this.numStars = Array(this.stars).fill(null).map((_, i) => i + 1);
  }
}
