import { ChangeDetectorRef, inject } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject, lastValueFrom } from 'rxjs';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { TabService } from '../services/tab.service';

export abstract class ComponentBase {

  isLoadingViewVisible = false;

  tabService                   = inject(TabService);
  protected _alertCtrl         = inject(AlertController);
  protected _changeDetectorRef = inject(ChangeDetectorRef);
  protected _modalCtrl         = inject(ModalController);
  protected _tabService        = inject(TabService);
  protected _toastCtrl         = inject(ToastController);
  protected _translateService  = inject(TranslateService);
  protected _loadingCtrl       = inject(LoadingController);
  protected _platform          = inject(Platform);

  protected _destroy$         = new Subject<boolean>();

  private _loader: any;
  
  async showAlert(message: string) {

    const okText = await this.getTrans('OK');

    const alert = await this._alertCtrl.create({
      header: '',
      message,
      buttons: [{
        text: okText,
        role: '',
      }],
    });

    return alert.present();
  }
  showConfirm(message: string, buttons?: { OK: string; CANCEL: string }): Promise<boolean> {
    return new Promise((resolve) => {
      if (buttons) {
        this.createConfirm(buttons, message, resolve);
      } else {
        lastValueFrom(this._translateService.get(['OK', 'CANCEL'])).then((str) => {
          this.createConfirm(str, message, resolve);
        });
      }
    });
  }

  async createConfirm(str: { OK: string; CANCEL: string }, message: string, resolve: (value: boolean | PromiseLike<boolean>) => void) {
    const confirm = await this._alertCtrl.create({
      header: '',
      message,
      buttons: [{
        text: str.CANCEL,
        role: 'cancel',
        handler: () => resolve(false),
      }, {
        text: str.OK,
        handler: () => resolve(true),
      }],
    });

    confirm.present();
  }

  async showToast(message: string = '', buttons: any = null, duration: number = 3000, color: string = 'info') {
    
    const closeText = await this.getTrans('CLOSE');
    const defaultCloseButton = [{
      text: closeText,
      role: 'cancel',
    }];

    buttons = buttons || defaultCloseButton;
    const toast = await this._toastCtrl.create({
      message,
      color: color,
      position: 'bottom',
      cssClass: 'tabs-bottom',
      duration,
      buttons,
    });

    toast.present();
    return toast;
  }

  async showWokLoadingView(text?: string): Promise<HTMLIonModalElement> {
    const modal = await this._modalCtrl.create({
      component: LoadingOverlayComponent,
      componentProps: { text: text || 'LOADING' },
      cssClass: 'modal-fullscreen',
    });
    await modal.present();
    return modal;
  }


  async  showSweetIcon(
    title: string,
    text: string,
    confirmButtonText: string,
    showCancelButton: boolean,
    icon: SweetAlertIcon,
    cancelButtonText?: string,
    iconColor?: string,
  ) {
    const options: SweetAlertOptions = {
      title,
      text,
      confirmButtonText,
      cancelButtonText,
      showCancelButton,
      heightAuto: false,
      icon,
      iconColor: iconColor || '#003DFF',
      showClass: {
        popup: 'animated fade-in',
      },
      hideClass: {
        popup: 'animated fade-out',
      },
    };

    return await Swal.fire(options);
  }

  async showLoading(params: { showOverlay: boolean; message?: string }) {
    if (params.showOverlay) {
      const loadingText = params.message ? params.message : await this.getTrans('LOADING');
      this._loader = await this._loadingCtrl.create({
        message: loadingText,
        backdropDismiss: true,
      });

      return await this._loader.present();
    }
    return true;
  }

  isMobile(): boolean {
    return this._platform.is('mobile') || this._platform.is('mobileweb');
  }

  dismissLoading() {
    return this._loader?.dismiss();
  }
  
  getTrans(key: string | string[], params: any = {}) {
    return lastValueFrom(this._translateService.get(key, params));
  }
}
