import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BasePage } from 'src/app/pages/base-page/base-page';
import { AddressObfuscatorService, IObfuscatedAddress } from 'src/app/services/address-obfuscator.service';
import { ChildWindowService } from 'src/app/services/child-window.service';

@Component({
  selector: 'app-address-obfuscator',
  templateUrl: './address-obfuscator.component.html',
  styleUrls: ['./address-obfuscator.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('true', style({ overflow: 'hidden', opacity: 1, height: '*' })),
      state('false', style({ overflow: 'hidden', opacity: 0, height: 0 })),
      transition('0 => 1', animate('250ms ease-out')),
      transition('1 => 0', animate('250ms ease-in')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AddressObfuscatorComponent extends BasePage implements OnInit, OnChanges {

  @Input() placeId     = '';
  @Input() kitchenName = '';
  @Input() sectionName = '';
  @Input() startOpen   = false;
  @Input() isBookingDetailPage = false;
  @Input() isConfirmed = false;

  @Output() addressChange = new EventEmitter<IObfuscatedAddress>();

  address = '';
  mapOpen = false;

  obfuscatedAddress: IObfuscatedAddress | null = null;

  constructor(
    injector: Injector,
    private _addressObfuscator: AddressObfuscatorService,
    private _changeRef: ChangeDetectorRef,
    private _childWindowService: ChildWindowService,
  ) {
    super(injector);
  }

  enableMenuSwipe(): boolean {
    return false;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.placeId && changes.placeId.currentValue) {
      this._load();
    }
  }

  async toggleMap() {
    this.mapOpen = !this.mapOpen;
    this._changeRef.markForCheck();
  }

  /**
   * Load the obfuscated address details and the associated static Map image
   */
  private _load() {
    this._addressObfuscator
      .load(this.placeId)
      .then((details) => {
        this.address = this._addressObfuscator.parseAndFormatAddress(details.address);
        details.address = this.address;
        this.obfuscatedAddress = details;
        this.addressChange.emit(this.obfuscatedAddress);

        if (this.startOpen) { this.toggleMap(); }

        this._changeRef.markForCheck();
      });
  }

  openMapApp() {
    const latitude  = this.obfuscatedAddress?.geo.latitude;
    const longitude = this.obfuscatedAddress?.geo.longitude;
    const ua        = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1 || this.isAndroid;
    const url       = isAndroid || this.isDesktop
      ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
      : `maps://maps.apple.com/?q=${latitude},${longitude}`;

    this._childWindowService.open({ url });
  }
}
