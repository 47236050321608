// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.disabled {
  color: var(--ion-color-grey-400);
}`, "",{"version":3,"sources":["webpack://./src/app/components/address-obfuscator/address-obfuscator.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,gCAAA;AACJ","sourcesContent":[".btn-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.disabled {\n    color: var(--ion-color-grey-400);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
