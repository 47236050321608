// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  width: 100%;
  color: var(--ion-color-light);
  background: var(--ion-color-primary);
  height: auto;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}
footer a {
  display: inline;
  color: var(--ion-color-light);
  font-size: 14px;
  cursor: pointer;
}
footer a:hover, footer a:focus, footer a:active {
  color: var(--ion-color-secondary);
}
footer .syzl-container > a {
  font-size: 26px;
  padding: 5px 0;
}
footer .socials-container > a {
  cursor: pointer;
}
footer .socials-container > a > ion-icon {
  color: var(--ion-color-secondary);
  width: 54px;
  height: 54px;
}
footer .socials-container > a:hover ion-icon, footer .socials-container > a:focus ion-icon {
  color: var(--ion-color-light);
}

.container-bord {
  border: 1px solid red;
}

@media only screen and (min-width: 768px) {
  footer {
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,6BAAA;EACA,oCAAA;EACA,YAAA;EACA,6BAAA;EACA,4BAAA;AACF;AACE;EACE,eAAA;EACA,6BAAA;EACA,eAAA;EACA,eAAA;AACJ;AACI;EAGE,iCAAA;AADN;AAKE;EACE,eAAA;EACA,cAAA;AAHJ;AAME;EACE,eAAA;AAJJ;AAMI;EACE,iCAAA;EACA,WAAA;EACA,YAAA;AAJN;AASM;EACE,6BAAA;AAPR;;AAaA;EACE,qBAAA;AAVF;;AAaA;EACE;IACE,6BAAA;IACE,4BAAA;EAVJ;AACF","sourcesContent":["footer {\n  width: 100%;\n  color: var(--ion-color-light);\n  background: var(--ion-color-primary);\n  height: auto;\n  border-top-right-radius: 30px;\n  border-top-left-radius: 30px;\n\n  a {\n    display: inline;\n    color: var(--ion-color-light);\n    font-size: 14px;\n    cursor: pointer;\n\n    &:hover,\n    &:focus,\n    &:active {\n      color: var(--ion-color-secondary);\n    }\n  }\n\n  .syzl-container  > a {\n    font-size: 26px;\n    padding: 5px 0;\n  }\n\n  .socials-container > a {\n    cursor: pointer;\n\n    > ion-icon {\n      color: var(--ion-color-secondary);\n      width: 54px;\n      height: 54px;\n    }\n\n    &:hover,\n    &:focus {\n      ion-icon {\n        color: var(--ion-color-light);\n      }\n    }\n  }\n}\n\n.container-bord {\n  border: 1px solid red;\n}\n\n@media only screen and (min-width: 768px) {\n  footer {\n    border-top-right-radius: 50px;\n      border-top-left-radius: 50px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
