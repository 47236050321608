export class PlaceData {
  section = '';
  sectionId = '';
  kitchen = '';
  kitchenId = '';
  statusPill: StatusPill;

  constructor(
    statusPill: StatusPill,
  ) {
    this.statusPill = statusPill;
  }
}

export class StatusPill {
  status: string;
  statusEnum: number;
  icon: string;

  constructor(status: string, statusEnum: number, icon: string) {
    this.status = status;
    this.statusEnum = statusEnum;
    this.icon = icon;
  }
}
