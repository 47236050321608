import { NgModule } from '@angular/core';
import { PhotoGalleryGroupDirective } from './photo-gallery-group.directive';
import { PhotoGalleryDirective } from './photo-gallery.directive';
import { SyzlIconDirective } from './syzl-icon.directive';
import { DelayedInputDirective } from './delayed-input.directive';
import { NoEmojisDirective } from './no-emojis.directive';
import { IntersectionObserverDirective } from './intersection-observer.directive';
import { ImageOptimizerDirective } from './image-optimizer.directive';
import { PipesModule } from '../pipes/pipes.module';
import { SimpleContentSliderDirective } from './simple-content-slider.directive';

@NgModule({
  declarations: [
    PhotoGalleryGroupDirective,
    PhotoGalleryDirective,
    SyzlIconDirective,
    DelayedInputDirective,
    NoEmojisDirective,
    IntersectionObserverDirective,
    ImageOptimizerDirective,
    SimpleContentSliderDirective,
  ],
  imports: [
    PipesModule,
  ],
  exports: [
    PhotoGalleryGroupDirective,
    PhotoGalleryDirective,
    SyzlIconDirective,
    DelayedInputDirective,
    NoEmojisDirective,
    IntersectionObserverDirective,
    ImageOptimizerDirective,
    SimpleContentSliderDirective,
  ],
})
export class DirectivesModule {}
