import { Component, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'photo-gallery-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LightboxComponent {
  @ViewChild('Lightbox', { static: true }) element!: ElementRef;

  constructor() {}
}
