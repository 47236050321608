import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalButtonsComponent } from './components/modal-buttons/modal-buttons.component';
import { ModalAddHelperPage } from './modal-add-helper/modal-add-helper.page';
import { ModalContentOnlyPage } from './modal-content-only/modal-content-only.page';
import { ModalContentWithTextareaPage } from './modal-content-with-textarea/modal-content-with-textarea.page';
import { ModalInsuranceDetailsPage } from './modal-insurance-details/modal-insurance-details.page';
import { ComponentsModule } from '../components/components.module';
import { ModalContentWithRadioAndTextareaComponent } from './modal-content-with-radio-and-textarea/modal-content-with-radio-and-textarea.component';
import { ModalCheckboxComponent } from './components/modal-checkbox/modal-checkbox.component';
import { ModalContentWithEmailComponent } from './modal-content-with-email/modal-content-with-email.component';
import { ModalHtmlLoaderComponent } from './modal-html-loader/modal-html-loader.component';
import { HtmlLoaderComponent } from '../components-standalone/html-loader/html-loader.component';
import { ModalShareMyLocationComponent } from './modal-share-my-location/modal-share-my-location.component';
import { ModalConversationsComponent } from './modal-conversations/modal-conversations.component';
import { PipesModule } from '../pipes/pipes.module';
import { ModalContentWithInputPage } from './modal-content-with-input/modal-content-with-input.page';
import { BasePageModal } from '../pages/base-page-modal/base-page-modal';

@NgModule({
  declarations: [
    ModalCheckboxComponent,
    ModalAddHelperPage,
    ModalInsuranceDetailsPage,
    ModalContentOnlyPage,
    ModalContentWithTextareaPage,
    ModalContentWithInputPage,
    ModalContentWithRadioAndTextareaComponent,
    ModalContentWithEmailComponent,
    ModalConversationsComponent,
    ModalHtmlLoaderComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ComponentsModule,
    HtmlLoaderComponent,
    ModalShareMyLocationComponent,
    PipesModule,
    ModalButtonsComponent,
    BasePageModal,
  ],
  exports: [
    ModalAddHelperPage,
    ModalContentOnlyPage,
    ModalContentWithInputPage,
    ModalContentWithTextareaPage,
    ModalConversationsComponent,
    ModalInsuranceDetailsPage,
    ModalContentWithRadioAndTextareaComponent,
    ModalContentWithEmailComponent,
    ModalHtmlLoaderComponent,
    ModalShareMyLocationComponent,
  ],
})
export class ModalsModule { }
