import { Component, Injector } from '@angular/core';
import { ModalManagerService } from 'src/app/modals/modal-manager.service';
import { BasePage } from '../base-page/base-page';

/**
 * BasePageModal
 * - DO NOT use this class directly. Instead, extend this class on your page (if your page is a modal).
 *   Otherwise, extend your page with BasePage
 * - This class pushes to the history state when initialized and performs a history.back() when being destroyed
 * - You must:
 *   1. call super(injector) in your constructor
 *   2. If your page has ionViewWillLeave, make sure you call super.ionViewWillLeave() as the LAST statement to ensure
 *      ionViewWillLeave within this class is called (it is required to remove the modal from the ModalManagerService,
 *      update the navigation history, etc.)
 *   3. If your page isn't working how you think it should, ensure #2 above are implemented correctly
 */

@Component({
  template: '',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BasePageModal extends BasePage {
  modal: HTMLElement | undefined = undefined;
  name = '';
  private _modalManager: ModalManagerService;

  constructor(injector: Injector) {
    super(injector);
    this._modalManager = injector.get(ModalManagerService);
  }

  ngOnInit(): void {
    // Initialize the modal and register it with ModalManagerService
    if (this.modal) {
      this.name = this.modal.id || 'UnknownModalID';
      this._modalManager.add(this);
    }
  }

  ionViewWillLeave(): void {
    this._modalManager.remove(this);
  }

  enableMenuSwipe(): boolean {
    return false;
  }
}