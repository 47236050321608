import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { User } from './user.service';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root',
})
export class UserWebhookResultCheckerService {

  private _checking: { [key: string]: any } = {};

  constructor() { }

  watchProp(prop: string, period: number, cb: (newValue: any) => boolean) {
    if (!this._checking[prop]) {
      const sub = interval(period).subscribe({
        next: async () => {
          const user     = User.getCurrent();
          const value    = user.get(prop);
          try {
            const newValue = await Parse.Cloud.run('getCurrentUserPropValue', { prop }, { });
            if (value !== newValue) {
              user.set(prop, newValue);
              const stop = cb(newValue);
              if (stop) {
                sub.unsubscribe();
                this._checking[prop] = null;
                delete this._checking[prop];
              }
            }
          } catch (error) {
            console.error(error);
          }
        },
      });
      this._checking[prop] = sub;
    }
  }
}
