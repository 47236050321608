import { Directive, HostListener, Input } from '@angular/core';
import { ViewWillLeave } from '@ionic/angular';
import PhotoSwipe from 'photoswipe';
import { PhotoSwipeOptions } from 'photoswipe/dist/types/core/base';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[photoGallery]',
  standalone: false,
})
export class PhotoGalleryDirective implements ViewWillLeave {
  @Input('photoGallery') imageUrl = '';
  @Input() imageGallery: any[] = [];
  @Input() index = 0;
  @Input() photoGalleryTrackBy    = '';
  @Input() photoGalleryCaption    = '';

  id = '';
  galleryDecoded = false;
  lightbox: any = null;
  lightboxOptions: PhotoSwipeOptions = {
    closeSVG: '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 512 512" width="100" height="125"><path d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"/></svg>',
    zoom: true,
    preloaderDelay: 1000,
    bgOpacity: 0.75,
  };

  constructor() {}

  ionViewWillLeave(): void {
    if (typeof this.lightbox !== 'undefined') {
      if (!this.lightbox.isDestroying) {
        this.lightbox.destroy();
      }
    }
  }

  @HostListener('click')
  async onPhotoClick(): Promise<void> {
    this.openPhotoSwipe();
  }


  async prepareImages() {
    const gallery = [];
    for (const image of this.imageGallery) {
      const img = new Image();
      img.src   = image.url;
      await img.decode();
      gallery.push({
        src: image.url,
        height: img.height,
        width: img.width,
      });

      img.remove();
    }
    this.galleryDecoded = true;
    return gallery;
  }

  async openPhotoSwipe() {
    if (!this.galleryDecoded) {
      // call this.prepareImages method to create an array of image objects (each image object has: src, height, and width)
      this.imageGallery = await this.prepareImages();
    }

    const options = {
      ...this.lightboxOptions,
      index: this.index,
      dataSource: this.imageGallery,
    };

    this.lightbox = new PhotoSwipe(options);

    this.lightbox.init();
  }
}
