import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';

export enum BookingCartItemType {
  eventInsurance = 'EVENT_INSURANCE',
  foodHandling   = 'FOOD_HANDLING',
  subscription   = 'SUBSCRIPTION',
  zensurance     = 'ZENSURANCE',
}

export interface IBookingCartItemPayload {
  itemType: BookingCartItemType;
  bookingSessionId: string;
  bookingId?: string | string[];
  productCostId?: string;
  taxRegionCode?: string;
  productServiceFeeId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BookingCartItemService {

  private _bookingCartItems$ = new BehaviorSubject<any[]>([]);

  constructor() { }

  get bookingCartItems$() {
    // do async against to bring to checkout page
    return this._bookingCartItems$.asObservable();
  }

  reset() {
    this._bookingCartItems$.next([]);
  }

  onBookingCartItemsLoaded(bookingCartItems: any[]): void {
    this._bookingCartItems$.next(bookingCartItems.concat(this._bookingCartItems$.getValue()));
  }

  onRemoveBookingCartItem(bookingCartItems: Parse.Object[]) {
    this._removeBookingCartItems(bookingCartItems.map(item => item.id));
  }

  /**
   * addBookingCartItem
   * - Creates a BookingCartItem record and returns it
   * @param bookingCartItemPayload 
   * @returns 
   */
  async addBookingCartItem(bookingCartItemPayload: IBookingCartItemPayload): Promise<any> {
    const bookingCartItem = await Parse.Cloud.run('bookingCartItemAdd', bookingCartItemPayload);
    if (!bookingCartItem.err) {
      this._bookingCartItems$.next([bookingCartItem].concat(this._bookingCartItems$.getValue()));
    }
    return bookingCartItem;
  }

  /**
   * getUpdatedFoodHandlingItem
   * - When a maker adds SmarterU to the cart and then adds a subscription,
   *   the SmarterU cost should be free
   * @param bookingCartItem 
   */
  async getUpdatedFoodHandlingItem(bookingCartItem: Parse.Object) {
    const objectId     = bookingCartItem.id;
    bookingCartItem    = await bookingCartItem.fetch();
    bookingCartItem    = bookingCartItem.clone();
    bookingCartItem.id = objectId;

    const bookingCartItems = this._bookingCartItems$.getValue();
    for (let i = 0; i < bookingCartItems.length; i++) {
      if (bookingCartItems[i].id === bookingCartItem.id) {
        bookingCartItems[i] = bookingCartItem;
      }
    }
    this._bookingCartItems$.next([...bookingCartItems]);
    return bookingCartItem;
  }

  async getBookingCartItems(bookingSessionId: string): Promise<any> {
    const bookingCartItems = await Parse.Cloud.run('getBookingCartItems', { bookingSessionId });
    if (bookingCartItems) {
      this._bookingCartItems$.next(bookingCartItems);
    }
  }

  async getRequestedBookingCartItems(bookingSessionId: string): Promise<any[]> {
    return Parse.Cloud.run('getRequestedBookingCartItems', { bookingSessionId });
  }

  async deleteBookingCartItem(bookingCartItemId: string): Promise<any> {
    await Parse.Cloud.run('removeBookingCartItem', { bookingCartItemId });
    this._removeBookingCartItems([bookingCartItemId]);
  }

  async getAutoApplyBookingPromoCode(bookingSessionId: string) {
    const promoCode = await Parse.Cloud.run('getAutoApplyBookingPromoCode', { bookingSessionId });
    return promoCode;
  }

  private _removeBookingCartItems(bookingCartItemIds: string[]) {
    const bookingCartItems         = this._bookingCartItems$.getValue();
    const filteredBookingCartItems = bookingCartItems.filter(item => !bookingCartItemIds.includes(item.id));
    this._bookingCartItems$.next([...filteredBookingCartItems]);
  }
}
