import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceSpaceWith',
  standalone: false,
})
export class ReplaceSpaceWithPipe implements PipeTransform {

  transform(str: string, replaceWith: string, replaceWhat?: string): string {
    if (replaceWhat) {
      const regEx = new RegExp(replaceWhat, 'g');
      return str ? str.replace(regEx, replaceWith) : '';
    }
    return str ? str.replace(/ /g, replaceWith) : '';
  }

}
