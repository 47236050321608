import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Subject } from 'rxjs';
import { GetUpcomingBookingsCountGQL, Scalars } from 'src/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class StripeCustomerService {

  static readonly STATUS_INITIAL      = 'initial';                // User has an account, but has not started Stripe's SetupIntent
  static readonly STATUS_SI_STARTED   = 'setup-intent-started';   // User has an account AND has STARTED Stripe's SetupIntent
  static readonly STATUS_SI_COMPLETED = 'setup-intent-completed'; // User has an account AND has COMPLETED Stripe's SetupIntent

  private _stripeCustomerCreated$ = new Subject<any>();

  constructor(
    private _upcomingBookings: GetUpcomingBookingsCountGQL,
  ) { }

  get customerCreated$() {
    return this._stripeCustomerCreated$.asObservable();
  }

  onCustomerCreated(stripeCustomer: any): void {
    this._stripeCustomerCreated$.next(stripeCustomer);
  }

  /**
   * Returns the Stripe customer information
   * @returns 
   */
  getStripeCustomer(): Promise<{ stripeCustomer: any; stripeIntent: any }> {
    return Parse.Cloud.run('getStripeCustomer');
  }

  /**
   * Returns the number of upcoming requested or approved bookings
   * @param userId 
   * @param startTime 
   * @returns 
   */
  getUpcomingBookingsCount(userId: Scalars['ID'], startTime: Date): Promise<number> {
    return new Promise((resolve, reject) => {
      this._upcomingBookings.fetch({
        userId,
        startTime,
      }, {
        fetchPolicy: 'no-cache',
      }).subscribe({
        next: (rsp) => {
          resolve(rsp.data.bookings.count);
        },
        error: (error) => {
          console.error(error);
          reject(0);
        },
      });
    });
  }

  async checkUserWallet() {
    const stripeCustomer = await this.getStripeCustomer();
    return stripeCustomer.stripeCustomer && stripeCustomer.stripeCustomer.get('status') === StripeCustomerService.STATUS_SI_COMPLETED ? true : false;
  }

  async isComplete() {
    const info = await this.getStripeCustomer();
    const {
      status,
      last4,
      paymentMethod,
    } = info.stripeCustomer.attributes;
    return status === StripeCustomerService.STATUS_SI_COMPLETED && this._isNotEmpty(last4) && this._isNotEmpty(paymentMethod);
  }

  createNewSetupIntent() {
    return Parse.Cloud.run('createNewSetupIntent');
  }

  deleteCard() {
    return Parse.Cloud.run('deleteCard');
  }

  private _isNotEmpty(val: string) {
    return val !== null && val !== '';
  }
}
