import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Installation {

  constructor(public http: HttpClient) {}

  getOne(id: string): Promise<any> {

    const headers = new HttpHeaders().set(
      'X-Parse-Application-Id', environment.appId,
    );
    const url = `${environment.serverUrl}/installations/${id}`;

    return lastValueFrom(this.http.get(url, { headers }));
  }

  save(id: string, data: any = {}): Promise<any> {

    const appId = environment.appId;
    const serverUrl = environment.serverUrl;

    const headers = new HttpHeaders().set('X-Parse-Application-Id', appId);
    const bodyString = JSON.stringify(data);
    let url = `${serverUrl}/installations/`;

    if (id) {
      url += id;
      return lastValueFrom(this.http.put(url, bodyString, { headers }));
    } else {
      return lastValueFrom(this.http.post(url, bodyString, { headers }));
    }
  }

}
