// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 16px 16px;
}

div.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 88px;
  width: 100%;
}
div.image-container .bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  max-height: 88px;
  width: 100%;
  max-width: 88px;
}
div.image-container img {
  height: 88px;
  max-height: 88px;
}

div.content {
  margin: 16px 0;
}
div.content ion-title,
div.content p {
  margin: 16px 24px;
  text-align: center;
}

div.avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
div.avatar ion-avatar {
  width: 48px;
  height: 48px;
}

div.action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/notification/notification.page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;AACF;AACE;EACE,sBAAA;EACA,4BAAA;EACA,2BAAA;EAEA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;AAAJ;AAGE;EACE,YAAA;EACA,gBAAA;AADJ;;AAKA;EACE,cAAA;AAFF;AAIE;;EAEE,iBAAA;EACA,kBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;AAHF;AAKE;EACE,WAAA;EACA,YAAA;AAHJ;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AAJF","sourcesContent":["div.close {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin: 8px 16px 16px;\n}\n\ndiv.image-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  max-height: 88px;\n  width: 100%;\n\n  .bg-img {\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n\n    height: 100%;\n    max-height: 88px;\n    width: 100%;\n    max-width: 88px;\n  }\n\n  img {\n    height: 88px;\n    max-height: 88px;\n  }\n}\n\ndiv.content {\n  margin: 16px 0;\n\n  ion-title,\n  p {\n    margin: 16px 24px;\n    text-align: center;\n  }\n}\n\ndiv.avatar {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n\n  ion-avatar {\n    width: 48px;\n    height: 48px;\n  }\n}\n\ndiv.action {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  margin: 24px 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
