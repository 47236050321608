import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { provideRouter } from '@angular/router';
import { TabRouteUrl } from './services/tab.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'stripe-return',
    loadChildren: () => import('./pages/stripe-return/stripe-return.module').then(m => m.StripeReturnPageModule),
  },
  {
    path: 'stripe-refresh',
    loadChildren: () => import('./pages/stripe-refresh/stripe-refresh.module').then(m => m.StripeRefreshPageModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsPageModule),
  },
  {
    path: 'p/:id',
    loadChildren: () => import('./pages/public-profile/public-profile.module').then(m => m.PublicProfilePageModule),
  },
  {
    path: '**',
    redirectTo: TabRouteUrl.search,
    pathMatch: 'full',
  },
  {
    path: 'place-create',
    loadChildren: () => import('./pages/place-create/place-create.module').then(m => m.PlaceCreatePageModule),
  },
  {
    path: 'place-guides',
    loadChildren: () => import('./pages/place-guides/place-guides.module').then(m => m.PlaceGuidesPageModule),
  },
  {
    path: 'referrals',
    loadChildren: () => import('./pages/referrals/referrals.module').then(m => m.ReferralsPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      canceledNavigationResolution: 'replace', // Handle navigation cancellations
      paramsInheritanceStrategy: 'always', // Ensure child routes inherit data from parent
      urlUpdateStrategy: 'eager', // Update the browser's URL eagerly
    }),
  ],
  exports: [RouterModule],
  providers: [
    provideRouter(
      routes,
    ),
  ],
})
export class AppRoutingModule {}
