import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})

export class SyzlAsyncValidators {

  private _cache: {
    [key: string]: {
      lastValue:      any;
      lastValidation: { [key: string]: any } | null;
      updateAt:       DateTime;
    };
  } = {};

  constructor(
    private _userService: User,
  ) {}

  /**
   * Ensures a property on the USER record specifically is unique
   * - The formControlName must match the DB fieldName
   * @returns 
   */
  hasUniqueUserProperty(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      // Wait until the control has content and a parent form
      if (!control.parent || control.pristine) {
        return of(null);
      }

      // Get the control's name and the last cache value
      const formGroup = control.parent as FormGroup;
      const controls  = (control.parent as FormGroup).controls;
      const name      = Object.keys(controls).find(controlName => control === formGroup.get(controlName)) || '';
      const value     = control.value;
      let cache       = this._cache[name];

      // Ensure the cache exists. If not, create it
      if (!cache || Math.abs(cache.updateAt.diffNow('minutes').minutes) > 1){
        this._cache[name] = { lastValue: null, lastValidation: null, updateAt: DateTime.now() };
        cache             = this._cache[name];
      }

      // If the value hasn't changed, return the last cached value (when switching between tabs)
      if (cache.lastValue === value) {
        return of(cache.lastValidation);
      }

      // Check the server to see if the value is unique or not
      return from(this._userService.validateUniqueUserField(name, value)).pipe(map((isUnique) => {
        const validation     = isUnique ? null : { notUnique: true };
        cache.lastValue      = value;
        cache.lastValidation = validation;
        return validation;
      }));
    };
  }
}
