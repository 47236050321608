import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Place } from 'src/app/services/place.service';
import { PlaceListingFragment } from 'src/generated/graphql';
import * as Parse from 'parse';
import { User } from 'src/app/services/user.service';
import { GoogleTagService } from 'src/app/services/google-tag.service';
import { PlaceRateService } from 'src/app/services/place-rate.service';
import { Preference } from 'src/app/services/preference.service';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { IsPlaceNewPipe } from 'src/app/pipes/is-place-new.pipe';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ComponentBase } from 'src/app/components-standalone/component-base';

@Component({
  selector: 'app-inner-page-header',
  templateUrl: './inner-page-header.component.html',
  styleUrls: ['./inner-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InnerPageHeaderComponent extends ComponentBase implements OnInit, OnChanges {

  @Input() place: PlaceListingFragment | null = null;
  @Input() isModal    = false;
  @Input() showSocial = false;
  @Input() preference: Preference | null = null;
  @Input() title      = '';
  @Input() subTitle   = '';
  @Input() close      = false;

  @Output() dismiss = new EventEmitter<void>();
  @Output() share = new EventEmitter<void>();

  isLiked = false;
  isNew   = false;
  canLike = false;
  optionClicked = false;

  @ViewChild('ionBackButton') private _ionBackButton!: any;

  constructor(
    private _placeService: Place,
    private _changeRef: ChangeDetectorRef,
    private _googleTag: GoogleTagService,
    private _placeRateService: PlaceRateService,
    private _navCtrl: NavController,
    private _router: Router,
    private _isPlaceNewPipe: IsPlaceNewPipe,
    public breakpoints: BreakpointService,
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.place && changes.place.currentValue && this.place) {
      this.isNew = this._isPlaceNewPipe.transform(this.place);

      if (User.getCurrent().firstName) {
        this.canLike  = true;
        const place   = new Parse.Object('Place');
        place.id      = this.place?.objectId || '';
        this._placeService
          .isLiked(place as Place)
          .then((isLiked) => {
            this.isLiked  = isLiked;
            this._changeRef.markForCheck();
          });
      }
    }
  }

  async onLike() {
    this.isLiked = !this.isLiked;
    const place  = new Place();
    place.id     = this.place?.objectId || '';
    try {
      await this._placeService.like(place as Place);
    } catch (error) {
      console.error(error);
    }
    this._changeRef.markForCheck();

    
    const rate: any = await this._placeRateService.loadPlaceRateInfo(place.id);
    
    // Google Tag Manager
    this._googleTag.add({
      event: 'add_to_wishlist',
      host: this.place?.user?.objectId,
      listingId: this.place?.objectId,
      item_name: this.place?.title || '',
      kitchenId: this.place?.facility.objectId,
      kitchen: this.place?.facility.title,
      currency: 'CAD',
      value: rate.defaultRate,
      items: [
        {
          item_id: this.place?.objectId,
        },
      ],
    });
  }

  onShare(){
    this.share.emit();
  }

  onBackOrCloseButtonClicked() {
    if (this.isModal) {
      this.dismiss.emit();
    } else {
      const el = this._ionBackButton?.el || this._ionBackButton?.nativeElement;
      el && el.click();
    }
  }
}
