// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--ion-color-grey-200);
  border-radius: 8px;
  width: 140px;
  overflow: hidden;
  cursor: pointer;
}
.container > img {
  height: 70%;
  width: 100%;
  object-fit: cover;
}

.label {
  display: flex;
  align-items: flex-start;
  border-top: 1px solid var(--ion-color-grey-200);
  gap: 8px;
}
.label > .pdf-info {
  display: flex;
  flex-direction: column;
}
.label > .pdf-info > span {
  align-self: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/conversation/components/message/pdf-thumbnail/pdf-thumbnail.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,2CAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACI;EACE,WAAA;EACA,WAAA;EACA,iBAAA;AACN;;AAIA;EACI,aAAA;EACA,uBAAA;EACA,+CAAA;EACA,QAAA;AADJ;AAGI;EACI,aAAA;EACA,sBAAA;AADR;AAIQ;EACI,sBAAA;AAFZ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--ion-color-grey-200);\n    border-radius: 8px;\n    width: 140px;\n    overflow: hidden;\n    cursor: pointer;\n\n    & > img {\n      height: 70%;\n      width: 100%;\n      object-fit: cover;\n    }\n}\n\n\n.label {\n    display: flex;\n    align-items: flex-start;\n    border-top: 1px solid var(--ion-color-grey-200);\n    gap: 8px;\n\n    & > .pdf-info {\n        display: flex;\n        flex-direction: column;\n\n       \n        & > span {\n            align-self: flex-start;\n        }\n    } \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
