import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from './user.service';
import { Deferred } from '../helpers/deferred';

@Injectable({
  providedIn: 'root',
})
export class ConvertHeicService {

  private _url  = environment.serverRootUrl;
  private _http = inject(HttpClient);

  convertToParseFile(file: File) {
    const user         = User.getCurrent();
    const sessionToken = user.getSessionToken();
    const userId       = user.id;

    const formData = new FormData();
    formData.append('sessionToken', sessionToken);
    formData.append('userId', userId);
    formData.append('file', file);

    const deferred = new Deferred();

    this._http
      .post(this._url + '/convert/heic-to-jpeg', formData, {
        headers: {
          contentType: 'multipart/form-data',
        },
        responseType: 'arraybuffer',
      })
      .subscribe({
        next:  buffer => deferred.resolve(buffer),
        error: error  => deferred.reject(error),
      });
    
    return deferred.promise$;
  }
}
