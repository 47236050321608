export class SectionPref {
  objectId   = '';
  icon       = '';
  name       = '';
  deletedAt: string | null = null;
  hasPref    = false;

  constructor(objectId: string, icon: string, name: string, deletedAt?: string, hasPref: boolean = false) {
    this.objectId  = objectId;
    this.icon      = icon;
    this.name      = name;
    this.deletedAt = deletedAt || null;
    this.hasPref   = hasPref;
  }
}
