import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { ModalActions } from 'src/app/modals/modal-actions';
import { ModalStatics } from 'src/app/services/modal.service';
import { User } from 'src/app/services/user.service';

@Component({
  standalone: true,
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})



export class TrainingComponent implements OnInit {

  readonly MODE_EDIT_PUBLIC   = 'editPublic';

  @Input() profileUserId  = ''; // The user we want to view
  @Input() user!: User;         // The logged in user
  @Output() deleteTraining = new EventEmitter<void>();

  public form!: FormGroup;

  constructor(
    private _controlContainer: ControlContainer,
    private _modalCtrl: ModalController,
    private _translate: TranslateService,
  ) {}

  ngOnInit() {
    this.form = this._controlContainer.control as FormGroup;
  }

  async onDelete() {
    const trans = await lastValueFrom(this._translate.get(['CONFIRM', 'CONFIRM_DELETE_PROGRAM', 'YES', 'NO']));
    const modal = await ModalActions.openModalContentOnly(this._modalCtrl, {
      title:   trans.CONFIRM,
      content: trans.CONFIRM_DELETE_PROGRAM,
      buttons: [
        {
          action: ModalStatics.GO_BACK,
          text:   trans.NO,
          fill:   'clear',
        },
        {
          action: ModalStatics.CONFIRM,
          text:   trans.YES,
        },
      ],
    });
    const { data } = await modal.onDidDismiss();

    if (data.action === ModalStatics.CONFIRM) {
      this.deleteTraining.emit();
    }
  }
}
