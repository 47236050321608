import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeocoderService {

  constructor(
    private _httpClient: HttpClient,
  ) { }

  public reverse(coords: { lat: any; lng: any }): Promise<any> {

    const url = 'https://maps.googleapis.com/maps/api/geocode/json';

    let params = new HttpParams();
    params = params.append('latlng', `${coords.lat},${coords.lng}`);
    params = params.append('key', environment.googleMapsApiKey);

    return lastValueFrom(this._httpClient.get(url, { params }));
  }

  public ipToGeo(): Promise<any> {
    const url = 'https://get.geojs.io/v1/ip/geo.json';
    const params = new HttpParams();
    return lastValueFrom(this._httpClient.get(url, { params }));
  }
}
