// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-button {
  height: 100%;
  margin: 0;
  width: 70px;
  min-width: 70px;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --border-radius: 0;
}
ion-button ion-img {
  width: 24px;
}

.disabled {
  color: var(--ion-color-dark) !important;
  opacity: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components-standalone/phone-number/phone-number.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,YAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;AACJ;;AAGA;EACE,uCAAA;EACA,wBAAA;AAAF","sourcesContent":[":host {\n  display: block;\n}\n\nion-button {\n  height: 100%;\n  margin: 0;\n  width: 70px;\n  min-width: 70px;\n  --padding-start: 0;\n  --padding-end: 0;\n  --padding-top: 0;\n  --padding-bottom: 0;\n  --border-radius: 0;\n\n  ion-img {\n    width: 24px;\n  }\n}\n\n.disabled {\n  color: var(--ion-color-dark) !important;\n  opacity: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
