import { Component, Input, OnInit } from '@angular/core';
import { ISectionEquipmentType } from './i-section-equipment-type';

@Component({
  selector: 'app-section-equipment-card',
  templateUrl: './section-equipment-card.component.html',
  styleUrls: ['./section-equipment-card.component.scss'],
  standalone: false,
})
export class SectionEquipmentCardComponent implements OnInit {

  @Input() equipment!: ISectionEquipmentType;
  
  detailsExpanded = false;
  
  constructor() { }

  ngOnInit() {}

  detailsClick() {
    this.detailsExpanded = !this.detailsExpanded;
  }
}
