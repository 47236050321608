// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 10px;
}

ion-img {
  height: 150px;
  width: 121px;
}

ion-label {
  font-weight: bold;
  padding-left: 40px;
  vertical-align: middle;
}
ion-label > div {
  color: var(--ion-color-primary);
}
ion-label > div > ion-icon {
  font-size: 24px;
  padding-left: 10px;
  vertical-align: middle;
}

ion-card-title {
  font-weight: bold;
}

ion-card-subtitle {
  padding-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/section-equipment-card/section-equipment-card.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,YAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;EACA,sBAAA;AACJ;AACI;EACI,+BAAA;AACR;AACQ;EACI,eAAA;EACA,kBAAA;EACA,sBAAA;AACZ;;AAIA;EACI,iBAAA;AADJ;;AAIA;EACI,gBAAA;AADJ","sourcesContent":["ion-card {\n    border-radius: 10px;\n}\n\nion-img {\n    height: 150px;\n    width: 121px; \n}\n\nion-label {\n    font-weight: bold;\n    padding-left: 40px;\n    vertical-align: middle;\n\n    > div {\n        color: var(--ion-color-primary);\n\n        > ion-icon {\n            font-size: 24px;\n            padding-left: 10px;\n            vertical-align: middle;\n        }\n    }\n}\n\nion-card-title {\n    font-weight: bold;\n}\n\nion-card-subtitle {\n    padding-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
