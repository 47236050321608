// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 0;
}

ion-input {
  --color: var(--ion-color-dark);
}

ion-select,
ion-input {
  --placeholder-color: var(--ion-color-grey-300);
  --placeholder-opacity: 1;
}

ion-select::part(text) {
  color: var(--ion-color-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-add-helper/modal-add-helper.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;;EAEI,8CAAA;EACA,wBAAA;AACJ;;AAEA;EACI,4BAAA;AACJ","sourcesContent":["ion-card {\n    border-radius: 0;\n}\n\nion-input {\n    --color: var(--ion-color-dark);\n}\n\nion-select, \nion-input {\n    --placeholder-color: var(--ion-color-grey-300);\n    --placeholder-opacity: 1;\n}\n\nion-select::part(text) {\n    color: var(--ion-color-dark);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
