import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SyzlStarService {

  private _starId = 1;
  constructor() { }

  nextStarId() {
    this._starId++;
    return 'syzl-star-' + this._starId;
  }
}
