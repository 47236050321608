import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root',
})
export class ParseFile {

  constructor() {
  }

  get name(): any {
    return this.name();
  }
  static upload(data: any, isBase64: boolean = true, ext: string = '') {
    const file   = isBase64 ? { base64: data } : data;
    let fileName = data.name || data.type?.replace('/', '.') || 'image.jpg';
    fileName     = fileName
      .replace(/ /g, '-').replace(/[^a-zA-Z0-9 \.\_\-]/g, '') // Only allow characters that ParseServer is OK with
      .replace(/\.(?=.*\.)/g, '-');                           // Only allow 1 period because multiple will hard-crash ParseServer (keep the last period)
    if (fileName.indexOf('.') < 0) {
      fileName += '.' + (ext || 'jpg');
    }
    
    return new Parse.File(fileName, file).save();
  }
}
