import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentModalPageModule } from '../content-modal/content-modal.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../../../app/components/components.module';
import { SignInSocialCompletionComponent } from './sign-in-social-completion.component';
import { OneSignalFailedComponent } from '../../../app/components-standalone/one-signal-failed/one-signal-failed.component';

@NgModule({
  declarations: [
    SignInSocialCompletionComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    ContentModalPageModule,
    OneSignalFailedComponent,
  ],
})
export class SignInSocialCompletionModule { }
