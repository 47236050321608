// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  color: var(--ion-color-dark-shade);
  line-height: 20px;
}

span {
  color: var(--ion-color-primary);
  line-height: 20px;
  margin-left: auto;
  word-break: normal;
}
span:hover {
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/components-standalone/read-more/read-more.component.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,iBAAA;AACJ;;AAEA;EACI,+BAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAAI;EACI,eAAA;EACA,0BAAA;AAER","sourcesContent":["p {\n    color: var(--ion-color-dark-shade);\n    line-height: 20px;\n}\n\nspan {\n    color: var(--ion-color-primary);\n    line-height: 20px;\n    margin-left: auto;\n    word-break: normal;\n    &:hover {\n        cursor: pointer;\n        text-decoration: underline;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
