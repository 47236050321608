// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-connect-button {
  margin-top: 20px;
}

ion-card-content p {
  margin-bottom: 16px;
}

ion-img,
img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

ion-card-header ion-grid {
  margin: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/components/calendar-sync/calendar-sync.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;;EAEE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".calendar-connect-button {\n  margin-top: 20px;\n}\n\nion-card-content p {\n  margin-bottom: 16px;\n}\n\nion-img,\nimg {\n  width: 50px;\n  height: 50px;\n  object-fit: contain;\n}\n\nion-card-header ion-grid {\n    margin: inherit;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
