// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-title {
  font-weight: bold;
}

ion-icon {
  height: 48px;
  width: 48px;
}

ion-label {
  font-weight: bold;
}

ion-input {
  color: var(--ion-color-success);
}`, "",{"version":3,"sources":["webpack://./src/app/components/section-equipment-accordion/section-equipment-accordion.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,+BAAA;AACJ","sourcesContent":["ion-title {\n    font-weight: bold;\n}\n\nion-icon {\n    height: 48px;\n    width: 48px;\n}\n\nion-label {\n    font-weight: bold;\n}\n\nion-input {\n    color: var(--ion-color-success);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
