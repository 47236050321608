// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-icon {
  background-color: var(--ion-color-light);
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
  padding: 0;
  width: 17px;
  height: 17px;
}

:host.small span {
  display: none;
}

div.is-clickable {
  cursor: pointer;
}

.paddingLeft {
  padding-left: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/preference-match/preference-match.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,wCAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;AACF;;AAGE;EAAO,aAAA;AACT;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,4BAAA;AACF","sourcesContent":[":host {\n  display: block;\n}\n\nion-icon {\n  background-color: var(--ion-color-light);\n  border-radius: 50%;\n  position: absolute;\n  top: -5px;\n  left: -5px;\n  padding: 0;\n  width: 17px;\n  height: 17px;\n}\n\n:host.small {\n  span { display: none; }\n}\n\ndiv.is-clickable {\n  cursor: pointer;\n}\n\n.paddingLeft {\n  padding-left: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
