import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { InfoWindowComponent } from './info-window/info-window';
import { TranslateModule } from '@ngx-translate/core';
import { PlaceCardComponent } from './place-card/place-card.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PreferenceCardComponent } from './preference-card/preference-card.component';
import { SectionPreviewCardComponent } from './section-preview-card/section-preview-card.component';
import { PreferenceListComponent } from './preference-list/preference-list.component';
import { BookingBarComponent } from './booking-bar/booking-bar.component';
import { PrefNumberStepperComponent } from './pref-number-stepper/pref-number-stepper.component';
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { SectionEquipmentAccordionComponent } from './section-equipment-accordion/section-equipment-accordion.component';
import { SectionEquipmentCardComponent } from './section-equipment-card/section-equipment-card.component';
import { SwiperCarouselAndLightboxComponent } from './swiper-carousel-and-lightbox/swiper-carousel-and-lightbox.component';
import { SwiperModule } from 'swiper/angular';
import { MapModalComponent } from './map-modal/map-modal.component';
import { AddressObfuscatorComponent } from './address-obfuscator/address-obfuscator.component';
import { InnerPageHeaderComponent } from './inner-page-header/inner-page-header.component';
import { SyzlStarComponent } from './syzl-star/syzl-star.component';
import { SyzlStarBarComponent } from './syzl-star-bar/syzl-star-bar.component';
import { StatusPillComponent } from './status-pill/status-pill.component';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { EnhancedEmptyViewComponent } from './enhanced-empty-view/enhanced-empty-view.component';
import { PreferenceMatchComponent } from './preference-match/preference-match.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { ProfileEditorComponent } from './profile-editor/profile-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifyWhenInCityComponent } from './notify-when-in-city/notify-when-in-city.component';
import { PhoneNumberComponent } from '../components-standalone/phone-number/phone-number.component';
import { HtmlLoaderComponent } from '../components-standalone/html-loader/html-loader.component';
import { ExperienceComponent } from '../components-standalone/experience/experience.component';
import { TrainingComponent } from '../components-standalone/training/training.component';
import { VerificationComponent } from '../components-standalone/verification/verification.component';
import { ReadMoreComponent } from '../components-standalone/read-more/read-more.component';
import { PdfThumbnailComponent } from '../pages/conversation/components/message/pdf-thumbnail/pdf-thumbnail.component';
import { GALLERY_CONFIG, GalleryModule } from 'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxModule } from 'ng-gallery/lightbox';
import { UploadBoxComponent } from './upload-box/upload-box.component';
import { CalendarSyncComponent } from './calendar-sync/calendar-sync.component';
import { GcalWebcalNamePipe } from '../pipes/gcal-webcal-name.pipe';

@NgModule({
  declarations: [
    InfoWindowComponent,
    PlaceCardComponent,
    PreferenceCardComponent,
    SectionPreviewCardComponent,
    PreferenceListComponent,
    BookingBarComponent,
    PrefNumberStepperComponent,
    SectionEquipmentAccordionComponent,
    SectionEquipmentCardComponent,
    SwiperCarouselAndLightboxComponent,
    MapModalComponent,
    AddressObfuscatorComponent,
    InnerPageHeaderComponent,
    SyzlStarComponent,
    SyzlStarBarComponent,
    StatusPillComponent,
    EnhancedEmptyViewComponent,
    PreferenceMatchComponent,
    BackButtonComponent,
    ProfileEditorComponent,
    NotifyWhenInCityComponent,
    CalendarSyncComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    LazyLoadImageModule,
    PipesModule,
    LazyLoadImageModule,
    DirectivesModule,
    SwiperModule,
    ImgFallbackModule,
    PhoneNumberComponent,
    HtmlLoaderComponent,
    ExperienceComponent,
    TrainingComponent,
    VerificationComponent,
    ReadMoreComponent,
    PdfThumbnailComponent,
    GalleryModule,
    LightboxModule,
    UploadBoxComponent,
    GcalWebcalNamePipe,
  ],
  exports: [
    TranslateModule,
    DirectivesModule,
    InfoWindowComponent,
    PlaceCardComponent,
    PreferenceCardComponent,
    SectionPreviewCardComponent,
    PreferenceListComponent,
    BookingBarComponent,
    PrefNumberStepperComponent,
    SectionEquipmentAccordionComponent,
    SectionEquipmentCardComponent,
    SwiperCarouselAndLightboxComponent,
    MapModalComponent,
    AddressObfuscatorComponent,
    InnerPageHeaderComponent,
    SyzlStarComponent,
    SyzlStarBarComponent,
    StatusPillComponent,
    EnhancedEmptyViewComponent,
    PreferenceMatchComponent,
    BackButtonComponent,
    ProfileEditorComponent,
    NotifyWhenInCityComponent,
    PdfThumbnailComponent,
    CalendarSyncComponent,
  ],
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: true,
        imageSize: 'cover',

      },
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        panelClass: 'fullscreen',
      },
    },
  ],
})
export class ComponentsModule {}
