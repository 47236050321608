import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Inject, Input, OnChanges, OnDestroy, Optional, Renderer2, Self, SimpleChanges } from '@angular/core';
import { IonImg } from '@ionic/angular';
import { ImageOptimizationPipe } from '../pipes/image-optimization.pipe';
import { Router } from '@angular/router';
import { TabRouteUrl } from '../services/tab.service';

class Rect {
  static empty: Rect = new Rect(0, 0, 0, 0);

  left   = 0;
  top    = 0;
  right  = 0;
  bottom = 0;

  constructor(left: number, top: number, right: number, bottom: number) {
    this.left   = left;
    this.top    = top;
    this.right  = right;
    this.bottom = bottom;
  }

  static fromElement(element: HTMLElement): Rect {
    const { left, top, right, bottom } = element.getBoundingClientRect();

    if (left === 0 && top === 0 && right === 0 && bottom === 0) {
      return Rect.empty;
    } else {
      return new Rect(left, top, right, bottom);
    }
  }

  static fromWindow(_window: Window): Rect {
    return new Rect(0, 0, _window.innerWidth, _window.innerHeight);
  }

  inflate(inflateBy: number) {
    this.left   -= inflateBy;
    this.top    -= inflateBy;
    this.right  += inflateBy;
    this.bottom += inflateBy;
  }

  intersectsWith(rect: Rect): boolean {
    return rect.left < this.right && this.left < rect.right && rect.top < this.bottom && this.top < rect.bottom;
  }

  getIntersectionWith(rect: Rect): Rect {
    const left   = Math.max(this.left, rect.left);
    const top    = Math.max(this.top, rect.top);
    const right  = Math.min(this.right, rect.right);
    const bottom = Math.min(this.bottom, rect.bottom);

    if (right >= left && bottom >= top) {
      return new Rect(left, top, right, bottom);
    } else {
      return Rect.empty;
    }
  }
}

@Directive({
  selector: 'div[appImageOptimizer],img[appImageOptimizer],ion-img[appImageOptimizer]',
  standalone: false,
})
export class ImageOptimizerDirective implements AfterViewInit, OnChanges, OnDestroy {

  private readonly _CSS_FAILED      = 'ng-failed-lazyloaded';
  private readonly _CSS_LOADED      = 'ng-lazyloaded';
  private readonly _CSS_LOADING     = 'ng-lazyloading';
  private readonly _LIMIT_BY_HEIGHT = 'height';
  private readonly _LIMIT_BY_WIDTH  = 'width';
  private readonly _LIMIT_BY_WIDTH_HEIGHT  = 'widthHeight';

  /**
   * appImageOptimizer
   * - The URL path to the image (can either be based on a Syzl URL or Cloudinary)
   * - NOTE: Added { url: string; type: string }[] to comply with Swiper data types ... but don't use it (yet anyway)
   */
  @Input() appImageOptimizer: string | { url: string; type: string }[] = '';
  /**
   * defaultImage
   * - The image to display prior to lazy loading OR when an image not found error occurs
   */
  @Input() defaultImage = '/assets/img/placeholder.png';
  /**
   * maxHeight
   * - The image's maximum height
   */
  @Input() maxHeight = 0;
  /**
   * minHeight
   * - The image's mininum height
   */
  @Input() minHeight = 0;
  /**
   * maxWidth
   * - The image's maximum width
   */
  @Input() maxWidth = 0;
  /**
   * minWidth
   * - The image's minimum width
   */
  @Input() minWidth = 0;
  /**
   * lazyLoadOffset
   * - The amount (in pixels) the image is offscreen prior to loading
   */
  @Input() lazyLoadOffset = 100;
  /**
   * limitBy
   * - When sizing the image, should it be based on the width or height
   */
  @Input() limitBy: 'width' | 'height' | 'widthHeight' = this._LIMIT_BY_WIDTH;
  /**
   * aspectRatio
   * - Not yet used
   * - When resizing the image, will ensure it is sized to the correct aspect ratio
   */
  @Input() aspectRatio = '';
  /**
   * scrollContainer
   * - The container in which the image belongs
   * - This is usually null as the IntersectionObserver handles lazy loading pretty well
   */
  @Input() scrollContainer: ElementRef<HTMLElement> | null = null;
 
  private _width  = 0;
  private _height = 0;
  private _loaded = false;
  private _reload = false;
  private _resizeTimeout = 0;
  private _io: IntersectionObserver | null = null;
  private _url = '';

  constructor(
    private _el: ElementRef,
    private _changeRef: ChangeDetectorRef,
    private _cloudinaryPipe: ImageOptimizationPipe,
    private _renderer: Renderer2,
    private _router: Router,
    @Inject(DOCUMENT) private _document: Document,
    @Optional() @Self() private _ionImage: IonImg,
  ) {}

  /**
   * Listen to window resize events
   * - When an event occurs, it is placed into a setTimeout
   * - If a second event occurs before the first is run, the first is cancelled
   *   (this helps prevent overcalculating and fetching)
   */
  @HostListener('window:resize')
  onResize() {
    clearTimeout(this._resizeTimeout);
    this._resizeTimeout = window.setTimeout(() => {
      this._calculateSize();
      this._observeElement();
    }, 250);
  }

  /**
   * After the element has been drawn, we can set it's default image
   * - We also calclate its size and start the IntersectionObserver
   */
  ngAfterViewInit(): void {
    this._setImg(this.defaultImage);
    setTimeout(() => {
      this._calculateSize();
      this._observeElement();
    }, 100);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appImageOptimizer) {
      if (changes.appImageOptimizer.currentValue) {
        this._url = this._cloudinaryPipe.transform(this.appImageOptimizer as string);
      } else {
        this._url = this.defaultImage;
      }
      if (!changes.appImageOptimizer.firstChange) {
        this._setImg(this._url);
      }
    }
  }

  ngOnDestroy() {
    this._stopObserving();
  }

  /**
   * _observeElement
   * - Creates an IntersectionObserver to watch for when the element is either on screen or close to on screen
   */
  private _observeElement() {
    if (!this._io) {
      this._io = new IntersectionObserver(() => {
        this._checkIfVisible();
      }, { root: this.scrollContainer?.nativeElement, rootMargin: this.lazyLoadOffset + 'px' });
      this._io.observe(this._el.nativeElement);
    }
  }

  /**
   * _stopObserving
   * - Removes the IntersectionObserver after the image is loaded
   * - When the page is resized, a new IntersectionObserver is created
   */
  private _stopObserving() {
    if (this._io) {
      this._io.unobserve(this._el.nativeElement);
      this._io = null;
    }
  }

  /**
   * _calculateSize
   * - Calculates the width and height of the element or its parent container
   */
  private _calculateSize() {
    const parentNode = this._findFirstParentWithSize(this._el.nativeElement.parentNode as HTMLElement);

    // Height settings
    let height = this._el.nativeElement.offsetHeight || parentNode.offsetHeight || this._document.defaultView?.innerHeight;
    height     = this.maxHeight > 0 ? Math.min(this.maxHeight, height) : height;
    height     = this.minHeight > 0 ? Math.max(this.minHeight, height) : height;

    // Width settings
    let width  = this._el.nativeElement.offsetWidth  || parentNode.offsetWidth || this._document.defaultView?.innerWidth;
    width      = this.maxWidth > 0 ? Math.min(this.maxWidth, width) : width;
    width      = this.minWidth > 0 ? Math.max(this.minWidth, width) : width;

    // Determine if we need to reload the image
    this._reload = height > this._height || width > this._width;
    this._height = height;
    this._width  = width;
  }

  /**
   * _findFirstParentWithSize
   * - Recursively calls backwards through the node tree until it finds a node with a width or height
   * @param parentNode 
   * @returns 
   */
  private _findFirstParentWithSize(parentNode: HTMLElement): { offsetHeight: number; offsetWidth: number } {
    if (this.limitBy === this._LIMIT_BY_WIDTH  && parentNode.offsetWidth  > 0
     || this.limitBy === this._LIMIT_BY_HEIGHT && parentNode.offsetHeight > 0
     || this.limitBy === this._LIMIT_BY_WIDTH_HEIGHT && parentNode.offsetHeight > 0
    ) {
      return { offsetHeight: parentNode.offsetHeight, offsetWidth: parentNode.offsetWidth };
    }
    return parentNode.parentNode ? this._findFirstParentWithSize(parentNode.parentNode as HTMLElement) : { offsetHeight: 0, offsetWidth: 0 };
  }

  /**
   * _checkIfVisible
   * - Checks to see if the element is visible or close to visible
   * - If so, it loads the element
   */
  private _checkIfVisible() {
    // We might not need to reload the image
    if (!this._reload && this._loaded) {
      return;
    }
    const imageBounds  = Rect.fromElement(this._el.nativeElement);
    const windowBounds = Rect.fromWindow(window);
    let intersects     = false;

    if (this.scrollContainer) {
      const scrollBounds = Rect.fromElement(this.scrollContainer.nativeElement);
      const intersection = scrollBounds.getIntersectionWith(windowBounds);
      intersects         = intersection.intersectsWith(imageBounds);
    } else {
      intersects         = windowBounds.intersectsWith(imageBounds);
    }

    if (intersects && (this._reload || !this._loaded)) {
      this._load();
    }
  }

  /**
   * Get the width of the element and download an image that fits correctly
   * - DIV and IonImg both stretch to fit their containers, so will have an offsetWidth/offsetHeight
   * - IMG is 0 width until it gets an src, so we can use the parent's offsetWidth/offsetHeight
   */
  private _load() {
    // Set the parameters (currently only using w_ or h_)
    let params = '';
    if (this._url !== this.defaultImage) {
      params = '?tx=';
      if (this.limitBy === this._LIMIT_BY_WIDTH) {
        params += 'w_' + this._width;
      }  else if (this.limitBy === this._LIMIT_BY_HEIGHT) {
        params += 'h_' + this._height;
      } else if (this.limitBy === this._LIMIT_BY_WIDTH_HEIGHT) {
        params += 'h_' + this._height + ',w_' + this._width;
      }
      if (this._router.url.includes(TabRouteUrl.search + '/places/')) {
        params += ',ar_2.5,c_fill,g_auto:subject/q_auto/f_auto';
      }
    }
    
    this._setLoading();
    const src   = this._url + params;
    const img   = this._document.createElement('img');
    img.onload  = () => { this._setImg(src); this._setLoaded(); };
    img.onerror = () => { this._setImg(this.defaultImage); this._setFailed(); };
    img.src     = src;
  }

  /**
   * _setImg
   * - Sets the image url to the src property or background-image
   * @param src 
   */
  private _setImg(src: string) {
    if (this._ionImage) {
      this._ionImage.src = src;
    } else {
      if (this._el.nativeElement instanceof HTMLImageElement) {
        const el = this._el.nativeElement;
        el.src   = src;
      } else {
        this._renderer.setStyle(this._el.nativeElement, 'background-image', `url("${src}")`);
      }
    }
    this._changeRef.markForCheck();
  }

  /**
   * _setLoading
   * - Adds the loading CSS class (removes others)
   */
  private _setLoading() {
    this._loaded = false;
    this._removeCssClassName(this._CSS_FAILED);
    this._removeCssClassName(this._CSS_LOADED);
    this._addCssClassName(this._CSS_LOADING);
  }

  /**
   * _setLoaded
   * - Adds the loaded CSS class (removes others)
   */
  private _setLoaded() {
    this._loaded = true;
    this._removeCssClassName(this._CSS_FAILED);
    this._removeCssClassName(this._CSS_LOADING);
    this._addCssClassName(this._CSS_LOADED);
    this._stopObserving();
  }

  /**
   * _setFailed
   * - Adds the failed CSS class (removes others)
   */
  private _setFailed() {
    this._loaded = true;
    this._removeCssClassName(this._CSS_LOADED);
    this._removeCssClassName(this._CSS_LOADING);
    this._addCssClassName(this._CSS_FAILED);
  }
  
  /**
   * _removeCssClassName
   * - Removes a CSS class name from the element
   * @param cssClassName 
   */
  private _removeCssClassName(cssClassName: string) {
    this._el.nativeElement.className = this._el.nativeElement.className.replace(cssClassName, '');
  }
  
  /**
   * _addCssClassName
   * - Adds a CSS class name to the element
   * @param cssClassName 
   */
  private _addCssClassName(cssClassName: string) {
    if (!this._el.nativeElement.className.includes(cssClassName)) {
      this._el.nativeElement.className += ` ${cssClassName}`;
    }
  }
}
