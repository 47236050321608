import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { SharedModule } from 'src/app/shared.module';
import { ModalStatics } from 'src/app/services/modal.service';

@Component({
  standalone: true,
  selector: 'app-modal-share-my-location',
  templateUrl: './modal-share-my-location.component.html',
  styleUrls: ['./modal-share-my-location.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
  ],
})
export class ModalShareMyLocationComponent extends BasePageModal implements OnInit {

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }


  ngOnInit() {}

  async onAccept() {
    await this.modalCtrl.dismiss({ action: ModalStatics.CONFIRM });
  }

  async onDecline() {
    await this.modalCtrl.dismiss({ action: ModalStatics.GO_BACK });
  }
}
