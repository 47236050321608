// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
  color: var(--ion-color-primary);
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/components-standalone/verification/verification.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;EACA,gBAAA;AACJ","sourcesContent":[".status {\n    color: var(--ion-color-primary);\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
