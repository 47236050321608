import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Media } from '@twilio/conversations';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ChildWindowService } from 'src/app/services/child-window.service';

interface IPdfInfo {
  filename: string;
  size: number;
  sid: string;
  pdfMedia: Media;
}
@Component({
  selector: 'app-pdf-thumbnail',
  templateUrl: './pdf-thumbnail.component.html',
  styleUrls: ['./pdf-thumbnail.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IonicModule,
    PipesModule,
  ],
})
export class PdfThumbnailComponent implements OnChanges {
  @Input() pdfSrc: IPdfInfo | null = null;

  fileName        = '';
  fileNameDisplay = '';
  src             = '';
  size            = 0;

  constructor(
    private _childWindowService: ChildWindowService,
  ) {}

  async ngOnChanges() {
    if (this.pdfSrc) {
      const { filename, size } = this.pdfSrc;
      this.fileName = filename;
      this.fileNameDisplay = this._shortenFileName(filename) || '';
      this.size     = size;
    }
  }

  private _shortenFileName(fileName: string) {
    const shortenedLength = 10;
    if (fileName.length >= 15) {
      return fileName.slice(0, shortenedLength) + '..' + '.pdf';
    } else {
      return fileName;
    }
  }

  async download() {
    const src  = await this.pdfSrc?.pdfMedia.getContentTemporaryUrl() || '';
    await this._childWindowService.open({ url: src, fileName: this.fileName, isPdf: true });
  }
}
