import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  async getNextMaintenance(): Promise<Parse.Object | undefined> {
    const result = await Parse.Cloud.run('getNextMaintenance');
    return result;
  }

  async getCurrentMaintenance(): Promise<Parse.Object | undefined> {
    const result = await Parse.Cloud.run('getCurrentMaintenance');
    return result;
  }
}
