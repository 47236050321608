// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --ion-color-base: var(--ion-color-white-subtle);
}

ion-thumbnail {
  min-width: 60px !important;
  min-height: 60px !important;
}
ion-thumbnail img {
  border-radius: 4px;
  width: 60px !important;
  height: 60px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/info-window/info-window.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;AACF;;AAEA;EACE,0BAAA;EACA,2BAAA;AACF;AACE;EACE,kBAAA;EACA,sBAAA;EACA,uBAAA;AACJ","sourcesContent":["ion-item {\n  --ion-color-base: var(--ion-color-white-subtle);\n}\n\nion-thumbnail {\n  min-width: 60px !important;\n  min-height: 60px !important;\n\n  img {\n    border-radius: 4px;\n    width: 60px !important;\n    height: 60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
