import {
  Directive, ElementRef, EventEmitter, Input,
  OnDestroy, OnInit, Output,
} from '@angular/core';
import { fromEvent, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appDelayedInput]',
  standalone: false,
})
export class DelayedInputDirective implements OnInit, OnDestroy {

  private _destroy$ = new Subject<void>();

  @Input() delayTime = 600;
  @Output() delayedInput = new EventEmitter<Event>();

  constructor(private _elementRef: ElementRef<HTMLInputElement>) {
  }

  ngOnInit() {
    fromEvent(this._elementRef.nativeElement, 'input')
      .pipe(
        debounce(() => timer(this.delayTime)),
        distinctUntilChanged(
          (x: string, y: string) => x === y,
          (event: Event) => (event.target as HTMLInputElement).value,
        ),
        takeUntil(this._destroy$),
      )
      .subscribe(e => this.delayedInput.emit(e));
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}
