import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { IModalDetails } from '../i-modal-details';
import { ModalStatics } from 'src/app/services/modal.service';


interface IHelper {
  userId: string;
  name: string;
  email: string;
}

@Component({
  selector: 'app-modal-add-helper',
  templateUrl: './modal-add-helper.page.html',
  styleUrls: ['./modal-add-helper.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ModalAddHelperPage extends BasePageModal implements OnInit {

  @Input() modalDetails!: IModalDetails;
  @Input() helpers: IHelper[] = [];

  safeHtml: SafeHtml | null = null;

  addHelperForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    userId: new FormControl(''),
  });

  selectedHelper: IHelper | null = null;

  constructor(
    injector: Injector,
    private _sanitizer: DomSanitizer,
    private _changeRef: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.safeHtml = this._sanitizer.bypassSecurityTrustHtml(this.modalDetails.content);
    this._changeRef.markForCheck();
  }

  onSelectChange(e: any) {

    // Update formGroup
    this.selectedHelper = e.detail.value;
    this.addHelperForm.controls.name.setValue(this.selectedHelper?.name || '');
    this.addHelperForm.controls.email.setValue(this.selectedHelper?.email || '');
    this.addHelperForm.controls.userId.setValue(this.selectedHelper?.userId || '');
  }

  resetUserId() {
    const formValue = this.addHelperForm.getRawValue();
    if (this.selectedHelper && this.selectedHelper.email !== formValue.email) {
      this.addHelperForm.controls.userId.setValue('');
      this.selectedHelper = null;
    }
  }

  buttonClick(action: string) {
    this.addHelperForm.controls.name.addValidators(Validators.required);
    this.addHelperForm.controls.name.updateValueAndValidity();
    this.addHelperForm.controls.email.addValidators([Validators.required]);
    this.addHelperForm.controls.email.updateValueAndValidity();

    if (action === ModalStatics.GO_BACK) {
      this.modalCtrl.dismiss({ action });
    }

    if (!this.addHelperForm.controls.name.valid || !this.addHelperForm.controls.email.valid) {
      return;
    }

    this.modalCtrl.dismiss({
      action,
      helper: this.addHelperForm.getRawValue(),
    });
  }
}
