import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StripeCustomerService } from './stripe-customer.service';
import { User } from './user.service';
import * as Parse from 'parse';
import { StripeIdentityService } from './stripe-identity.service';

export interface IProfileSection {
  state: string;
  icon: string;
  title: string;
  instructions: string;
  cta: string;
  path?: string;
  order: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileCompleteService {

  static readonly KITCHEN_PREF_TITLE      = 'KITCHEN_PREFERENCES';
  static readonly PROFILE_PIC_TITLE       = 'PROFILE_PICTURE';
  static readonly ID_VERIFICATION_TITLE   = 'IDENTITY_VERIFICATION';
  static readonly WALLET_TITLE            = 'WALLET';
  static readonly STATE_COMPLETE          = 'complete';
  static readonly STATE_INCOMPLETE        = 'incomplete';
  static readonly STATE_DISMISSED         = 'dismissed';
  static readonly STATE_PENDING           = 'pending';

  readonly ACCOUNT_CREATED_TITLE          = 'WELCOME_TO_SYZL';
  readonly ACCOUNT_CREATED_INSTRUCTIONS   = 'YOU_HAVE_CREATED_ACCOUNT';
  readonly ACCOUNT_CREATED_CTA            = 'NEXT';

  readonly PROFILE_COMPLETE_TITLE         = 'PROFILE_COMPLETE';
  readonly PROFILE_COMPLETE_INSTRUCTIONS  = '';
  readonly PROFILE_COMPLETE_CTA           = 'EXIT';
  readonly PROFILE_COMPLETE_ICON          = 'birthday-cake';

  private readonly _FILLER_SECTION_LENGTH = 2;

  private readonly _notQuiteComplete: IProfileSection = {
    state:        ProfileCompleteService.STATE_COMPLETE,
    icon:         'ice-cream',
    title:        'PROFILE_DISMISSED',
    instructions: 'PROFILE_DISMISSED_INSTRUCTIONS',
    cta:          'EXIT',
    path:         '',
    order:        7,
  };

  private readonly _waitingForPending: IProfileSection = {
    state:        ProfileCompleteService.STATE_COMPLETE,
    icon:         'ice-cream',
    title:        'PROFILE_PENDING',
    instructions: 'PROFILE_PENDING_INSTRUCTIONS',
    cta:          'EXIT',
    path:         '',
    order:        7,
  };

  private _kitchenPrefComplete$          = new BehaviorSubject<IProfileSection>({
    state:        ProfileCompleteService.STATE_INCOMPLETE,
    icon:         'demo',
    title:         ProfileCompleteService.KITCHEN_PREF_TITLE,
    instructions: 'PROFILE_COMPLETE_KITCHEN_PREF_INSTRUCTIONS',
    cta:          'SET_PREFERENCES',
    path:         'preference-card',
    order:        2,
  });

  private _profilePhotoComplete$         = new BehaviorSubject<IProfileSection>({
    state:        ProfileCompleteService.STATE_INCOMPLETE,
    icon:         'camera',
    title:        ProfileCompleteService.PROFILE_PIC_TITLE,
    instructions: 'PROFILE_COMPLETE_PHOTO_INSTRUCTIONS',
    cta:          'UPLOAD_PICTURE',
    path:         'profile-edit',
    order:        3,
  });
  private _identityVerificationComplete$ = new BehaviorSubject<IProfileSection>({
    state:        ProfileCompleteService.STATE_INCOMPLETE,
    icon:         'identity',
    title:        ProfileCompleteService.ID_VERIFICATION_TITLE,
    instructions: 'PROFILE_COMPLETE_IDENTITY_INSTRUCTIONS',
    cta:          'VERIFY_ID',
    path:         'identity-verification',
    order:        4,
  });
  private _walletComplete$               = new BehaviorSubject<IProfileSection>({
    state:        ProfileCompleteService.STATE_INCOMPLETE,
    icon:         'wallet',
    title:        ProfileCompleteService.WALLET_TITLE,
    instructions: 'PROFILE_COMPLETE_WALLET_INSTRUCTIONS',
    cta:          'ADD_PAYMENT',
    path:         'wallet',
    order:        5,
  });

  
  private _checklist$                   = new BehaviorSubject<IProfileSection[]>([]);
  private _profileComplete$             = new BehaviorSubject<boolean>(true);
  private _dismissedComplete$           = new BehaviorSubject<boolean>(false);
 

  private _progressMessage$             = new BehaviorSubject<string>('');
  private _incompleteIndexes$           = new BehaviorSubject<number[]>([]);
  private _lastUpdatedSection$          = new BehaviorSubject<IProfileSection | null>(null);
  private _isInitialized                = false;

  signUpComplete: IProfileSection = {
    state:        ProfileCompleteService.STATE_COMPLETE,
    icon:         'user',
    title:        this.ACCOUNT_CREATED_TITLE,
    instructions: this.ACCOUNT_CREATED_INSTRUCTIONS,
    cta:          this.ACCOUNT_CREATED_CTA,
    path:         '',
    order:        1,
  };

  fullyComplete: IProfileSection = {
    state: ProfileCompleteService.STATE_COMPLETE,
    icon:         this.PROFILE_COMPLETE_ICON,
    title:        this.PROFILE_COMPLETE_TITLE,
    instructions: this.PROFILE_COMPLETE_INSTRUCTIONS,
    cta:          this.PROFILE_COMPLETE_CTA,
    path:         '',
    order:        7,
  };

  initialCompleteCount = 1;
  isHost               = false;
  profileCompletionState: any;
  listenersCreated     = false;

  constructor(
    private _stripeIdentityService: StripeIdentityService,
    private _stripeCustomer: StripeCustomerService,
  ) { }

  get kitchenPrefComplete() {
    return this._kitchenPrefComplete$;
  }

  get profilePhotoComplete() {
    return this._profilePhotoComplete$;
  }

  get identityVerificationComplete() {
    return this._identityVerificationComplete$;
  }

  get walletComplete() {
    return this._walletComplete$;
  }

  get progressMessage() {
    return this._progressMessage$;
  }

  get incompleteIndexes() {
    return this._incompleteIndexes$;
  }

  get lastUpdatedSection() {
    return this._lastUpdatedSection$;
  }

  get profileComplete() {
    return this._profileComplete$;
  }

  get dismissedComplete() {
    return this._dismissedComplete$;
  }

  get checklist() {
    return this._checklist$;
  }

  get firstIncompleteSection() {
    const checklist       = this._checklist$.getValue();
    const firstSection    = checklist.find((section: IProfileSection) => section.state === ProfileCompleteService.STATE_INCOMPLETE);
    const completeSection = checklist.every(item => item.state === ProfileCompleteService.STATE_COMPLETE)
      ? this.fullyComplete
      : checklist.some(item => item.state === ProfileCompleteService.STATE_DISMISSED)
        ? this._notQuiteComplete
        : this._waitingForPending;

    return firstSection || completeSection;
  }

  private _identityListener() {
    const userIdIncomplete   = User.getCurrent().identityStatus !== StripeIdentityService.STATUS_PASSED;
    const userNotAnon       = !User.getCurrent().isAnonymous();

    if (userIdIncomplete && userNotAnon) {
      this._stripeIdentityService.identityStatusChange$.subscribe({
        next: () => {
          this.checkIdentityVerification(true);
          this.checkIsComplete();
        },
      });
      this._stripeIdentityService.watchUser();
    } else {
      this.checkIdentityVerification();
    }
  }

  private async _setupChecklist() {

    if (!this.profileCompletionState) {
      await this.getProfileCompletion();
    }

    const isHost    = this.profileCompletionState.get('isHost');
    const validUser = User.getCurrent().firstName;

    if (validUser && isHost) {
      this.checkProfilePhoto(false);
      this.checkIdentityVerification(false);
      await this.checkWalletComplete(false);
      this.lastUpdatedSection.next(null);
      this._isInitialized = true;
    } else if (validUser && !isHost) {
      await this.checkKitchenPref(undefined, false);
      this.checkProfilePhoto(false);
      this.checkIdentityVerification(false);
      await this.checkWalletComplete(false);
      this._isInitialized = true;
    }

    this._updateCheckList();
  }

  private async _updateCheckList() {

    if (!this.profileCompletionState) {
      await this.getProfileCompletion();
    }

    const isHost    = this.profileCompletionState.get('isHost');
    const makrChecklist = [
      this._kitchenPrefComplete$.getValue(),
      this._profilePhotoComplete$.getValue(),
      this._identityVerificationComplete$.getValue(),
      this._walletComplete$.getValue(),
    ];

    const hostChecklist = [
      this._profilePhotoComplete$.getValue(),
      this._identityVerificationComplete$.getValue(),
      this._walletComplete$.getValue(),
    ];

    const prepArray = isHost ? hostChecklist : makrChecklist;

    prepArray.sort((a, b) => {
      const aComplete = a.state === ProfileCompleteService.STATE_COMPLETE;
      const bComplete = b.state === ProfileCompleteService.STATE_COMPLETE;
      if (aComplete && bComplete || !(aComplete && !bComplete)) {
        return a.order < b.order ? -1 : 1;
      }
      return aComplete ? -1 : 1;
    });
    const progressStart = isHost ? 3 : 1;
    const step = prepArray.reduce((totalProgress, x: IProfileSection) => {
      const sectionProgressed = x.state === ProfileCompleteService.STATE_COMPLETE || x.state === ProfileCompleteService.STATE_DISMISSED;
      if (sectionProgressed) {
        return totalProgress + 1;
      } else {
        return totalProgress;
      }
    }, progressStart);

    this._progressMessage$.next(`PROFILE_PROGRESS_${step}`);

    prepArray.unshift(this.signUpComplete);
    prepArray.push(this.fullyComplete);

    this._checklist$.next([...prepArray]);
  }

  async checkIsComplete(login: boolean = false) {

    if (login) {
      this.initialCompleteCount = 1;
      this._isInitialized = false;
    }

    await this._setupChecklist();

    if (this._isInitialized) {
      const checklist = this._checklist$.getValue();
      const realLength = checklist.length - this._FILLER_SECTION_LENGTH;
      const completeCount = checklist.reduce((totalProgress, x: IProfileSection) =>
        x.state === ProfileCompleteService.STATE_COMPLETE && x.path
          ? totalProgress + 1
          : totalProgress
      , 0);

      const dismissedCount = checklist.reduce((totalProgress, x: IProfileSection) =>
        x.state === ProfileCompleteService.STATE_DISMISSED && x.path
          ? totalProgress + 1
          : totalProgress
      , 0);
      let hasIncomplete = false;
      let firstIncomplete;
      const incompleteOrDismissedIndexes = [];
      for (let i = 0; i < checklist.length - 1; i++) {
        if (checklist[i].state === ProfileCompleteService.STATE_INCOMPLETE || checklist[i].state === ProfileCompleteService.STATE_DISMISSED) {
          incompleteOrDismissedIndexes.push(i);
        }

        if (!hasIncomplete && checklist[i].state === ProfileCompleteService.STATE_INCOMPLETE) {
          hasIncomplete = true;
          firstIncomplete = i;
        }
      }
     
      this._incompleteIndexes$.next(incompleteOrDismissedIndexes);
      this._profileComplete$.next(realLength === completeCount);
      this._dismissedComplete$.next(realLength === completeCount + dismissedCount);
    
      return {
        complete: realLength === completeCount + dismissedCount || realLength === completeCount,
        index: firstIncomplete || incompleteOrDismissedIndexes[0],
        hasIncomplete,
      };
    }
  }

  async checkKitchenPref(returnBoolean?: boolean, needsUpdate: boolean = true) {

    if (returnBoolean && !User.getCurrent().isLoggedIn()) {
      return false;
    }

    if (!this.profileCompletionState) {
      await this.getProfileCompletion();
    }

    let state = this.profileCompletionState.get('preferenceState');
    if (returnBoolean) {
      return state;
    }
    if (needsUpdate) {
      const kitchenPref = User.getCurrent().kitchenPreference;
      await kitchenPref.fetch();
      state = kitchenPref.updatedAt > kitchenPref.createdAt ? ProfileCompleteService.STATE_COMPLETE : state;
      if (state !== this.profileCompletionState.get('preferenceState')) {
        await this.updateState('preferenceState', state);
      }
    }
    
    const currentKitchenCompleteValue = this._kitchenPrefComplete$.getValue();
    currentKitchenCompleteValue.state = state;
    this._kitchenPrefComplete$.next({ ...currentKitchenCompleteValue });

    if (needsUpdate) {
      this._updateCheckList();
      if (currentKitchenCompleteValue.state === ProfileCompleteService.STATE_COMPLETE && !this.profileComplete.getValue()) {
        this._lastUpdatedSection$.next({ ...currentKitchenCompleteValue });
      }
    } else {
      if (currentKitchenCompleteValue.state === ProfileCompleteService.STATE_COMPLETE && !this._isInitialized) {
        this.initialCompleteCount += 1;
      }
    }
  }

  async checkProfilePhoto(needsUpdate: boolean = true) {
 
    if (!this.profileCompletionState) {
      await this.getProfileCompletion();
    }

    let state = this.profileCompletionState.get('photoState');
    if (needsUpdate) {
      const profileComplete = User.getCurrent().photo;
      state = profileComplete ? ProfileCompleteService.STATE_COMPLETE : ProfileCompleteService.STATE_INCOMPLETE;
      if (state !== this.profileCompletionState.get('photoState')) {
        await this.updateState('photoState', state);
      }
    }

    const currentProfilePhotoValue      = this._profilePhotoComplete$.getValue();

    currentProfilePhotoValue.state      = this.profileCompletionState.get('photoState');
    this._profilePhotoComplete$.next({ ...currentProfilePhotoValue });

    if (needsUpdate) {
      this._updateCheckList();
      if (currentProfilePhotoValue.state === ProfileCompleteService.STATE_COMPLETE && !this.profileComplete.getValue()) {
        this._lastUpdatedSection$.next({ ...currentProfilePhotoValue });
      }
    } else {
      if (currentProfilePhotoValue.state === ProfileCompleteService.STATE_COMPLETE && !this._isInitialized) {
        this.initialCompleteCount += 1;
      }
    }
  }

  async checkIdentityVerification(needsUpdate: boolean = true) {
    
    if (!this.profileCompletionState) {
      await this.getProfileCompletion();
    }

    let state = this.profileCompletionState.get('identityState');
    if (needsUpdate) {
      const certnScore                      = User.getCurrent().certnScore     || '';
      const identityStatus                  = User.getCurrent().identityStatus || '';
      const certnComplete                   = certnScore === StripeIdentityService.STATUS_PASSED;
      const identityComplete                = certnComplete || identityStatus === StripeIdentityService.STATUS_PASSED;
      state = identityComplete
        ? ProfileCompleteService.STATE_COMPLETE
        : identityStatus === StripeIdentityService.STATUS_PENDING
          ? ProfileCompleteService.STATE_PENDING
          : state;
      if (state !== this.profileCompletionState.get('identityState')) {
        await this.updateState('identityState', state);
      }
    }

    const currentIDVerificationValue      = this._identityVerificationComplete$.getValue();
    currentIDVerificationValue.state      = this.profileCompletionState.get('identityState');

    this._identityVerificationComplete$.next({ ...currentIDVerificationValue });
    if (needsUpdate) {
      this._updateCheckList();
      if ((
        currentIDVerificationValue.state === ProfileCompleteService.STATE_COMPLETE ||
          currentIDVerificationValue.state === ProfileCompleteService.STATE_PENDING
      ) && !this.profileComplete.getValue()
      ) {
        this._lastUpdatedSection$.next({ ...currentIDVerificationValue });
      }
    } else {
      if (currentIDVerificationValue.state === ProfileCompleteService.STATE_COMPLETE && !this._isInitialized) {
        this.initialCompleteCount += 1;
      }
    }
  }

  async checkWalletComplete(needsUpdate: boolean = true) {
    
    if (!this.profileCompletionState) {
      await this.getProfileCompletion();
    }
    const isHost                         = this.profileCompletionState.get('isHost');
    const makrWallet                     = this.profileCompletionState.get('makrWalletState');
    const hostWallet                     = this.profileCompletionState.get('hostWalletState');
    let state = isHost ? hostWallet : makrWallet;
    if (needsUpdate) {
      if (isHost) {
        try {
          const hostWalletAccount = await Parse.Cloud.run('getUserStripeAccount');
          state = hostWalletAccount && hostWalletAccount.get('status') === 'complete' ? ProfileCompleteService.STATE_COMPLETE : state;
          if (state !== hostWallet) {
            await this.updateState('hostWalletState', state);
          }
        } catch (error) {
          console.error(error);
        }

      } else {
        const makrWalletAccount = await this._stripeCustomer.isComplete();
        state = makrWalletAccount ? ProfileCompleteService.STATE_COMPLETE : state;
        if (state !== makrWallet) {
          await this.updateState('makrWalletState', state);
        }
      }
    }
    const currentWalletCompleteVal      = this._walletComplete$.getValue();

    currentWalletCompleteVal.state      = state;
    this._walletComplete$.next({ ...currentWalletCompleteVal });
    if (needsUpdate) {
      this._updateCheckList();
      if (currentWalletCompleteVal.state === ProfileCompleteService.STATE_COMPLETE && !this.profileComplete.getValue()) {
        this._lastUpdatedSection$.next({ ...currentWalletCompleteVal });
      }
    } else {
      if (currentWalletCompleteVal.state === ProfileCompleteService.STATE_COMPLETE && !this._isInitialized) {
        this.initialCompleteCount += 1;
      }
    }
  }

  async getProfileCompletion() {
    try {
      const completionObject      = await Parse.Cloud.run('getProfileCompletion');
      this.isHost                 = completionObject.get('isHost');
      this.profileCompletionState = completionObject;
  
      if (this.profileCompletionState.get('identityState') !== ProfileCompleteService.STATE_COMPLETE && !this.listenersCreated) {
        this._identityListener();
        this.listenersCreated = true;
      }
      return completionObject;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async setDismiss(section: string) {
    const currentKitchenValue       = this._kitchenPrefComplete$.getValue();
    const currentPhotoValue         = this._profilePhotoComplete$.getValue();
    const currentIdentityValue      = this._identityVerificationComplete$.getValue();
    const currentWalletValue        = this._walletComplete$.getValue();

    switch(section) {
      case ProfileCompleteService.KITCHEN_PREF_TITLE:
        this.profileCompletionState.set('preferenceState', ProfileCompleteService.STATE_DISMISSED);
       
        currentKitchenValue.state = ProfileCompleteService.STATE_DISMISSED;
        this._kitchenPrefComplete$.next({ ...currentKitchenValue });
        break;

      case ProfileCompleteService.PROFILE_PIC_TITLE:
        this.profileCompletionState.set('photoState', ProfileCompleteService.STATE_DISMISSED);

        currentPhotoValue.state = ProfileCompleteService.STATE_DISMISSED;
        this._profilePhotoComplete$.next({ ...currentPhotoValue });
        break;

      case ProfileCompleteService.ID_VERIFICATION_TITLE:
        this.profileCompletionState.set('identityState', ProfileCompleteService.STATE_DISMISSED);

        currentIdentityValue.state = ProfileCompleteService.STATE_DISMISSED;
        this._identityVerificationComplete$.next({ ...currentIdentityValue });
        break;

      case ProfileCompleteService.WALLET_TITLE:
        const walletState = this.profileCompletionState.get('isHost')
          ? 'hostWalletState'
          : 'makrWalletState';
        this.profileCompletionState.set(walletState, ProfileCompleteService.STATE_DISMISSED);

        currentWalletValue.state = ProfileCompleteService.STATE_DISMISSED;
        this._walletComplete$.next({ ...currentWalletValue });
        break;
    }
    await this.profileCompletionState.save();
    this._updateCheckList();
  }

  async updateState(propertyName: string, val: string) {
    await this.profileCompletionState.save({
      [propertyName]: val,
    });
  }

  clearLastUpdated() {
    this.lastUpdatedSection.next(null);
  }
}
