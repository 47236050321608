import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OneSignalWebWrapperService {

  private readonly _ONESIGNAL_SDK_ID          = 'onesignal-sdk';
  private readonly _ONESIGNAL_SCRIPT_SRC      = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
  private readonly _ONESIGNAL_NOT_SETUP_ERROR = 'OneSignal is not setup correctly.';

  private _isOneSignalInitialized = false;
  private _oneSignalFunctionQueue: IOneSignalFunctionQueueItem[] = [];

  constructor() { }

  get isOneSignalInitialized() {
    return this._isOneSignalInitialized;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private set isOneSignalInitialized(val: boolean) {
    this._isOneSignalInitialized = val;
  }

  get oneSignal() {
    return (window as any).OneSignal;
  }

  /**
   * init
   * - Initialize the OneSignal client by downloading its script from the CDN
   * - If the script is blocked by the browser, return an error object
   * @param options 
   * @returns 
   */
  init(options: IInitObject): Promise<IOneSignalInitResult> {
    return new Promise(async (resolve) => {
      const out: IOneSignalInitResult = { error: null, loaded: false };

      if (this.isOneSignalInitialized) {
        out.loaded = true;
        resolve(out);
      }

      const isLoaded = await this._injectScript();
      if (isLoaded) {
        this._setupOneSignalIfMissing();

        // Initialize OneSignal
        this.oneSignal.push(async () => {
          try {
            await this.oneSignal.init(options);

            // Resolve the initialized state
            this.oneSignal.push(() => {
              out.loaded                  = true;
              this.isOneSignalInitialized = true;
              this._processQueuedOneSignalFunctions();
              resolve(out);
            });
          } catch (error) {
            console.error(error);
            this.isOneSignalInitialized = false;
            out.error = { code: 403, message: this._ONESIGNAL_NOT_SETUP_ERROR };
            resolve(out);
          }
        });
      } else {
        out.error = { code: 403, message: this._ONESIGNAL_NOT_SETUP_ERROR };
        resolve(out);
      }
    });
  }

  /**
   * on
   * - Subscribe to OneSignal events
   * @param event 
   * @param listener 
   * @returns 
   */
  on(event: string, listener: IEventData): void {
    if (!this._doesOneSignalExist()) {
      this._oneSignalFunctionQueue.push({
        name: OneSignalWebWrapperPublicInterface.on,
        args: arguments,
      });
      return;
    }
    this.oneSignal.push(() => {
      this.oneSignal.on(event, listener);
    });
  }

  /**
   * off
   * - Unsubscribe to OneSignal events
   * @param event 
   * @param listener 
   * @returns 
   */
  off(event: string, listener: IEventData): void {
    if (!this._doesOneSignalExist()) {
      this._oneSignalFunctionQueue.push({
        name: OneSignalWebWrapperPublicInterface.off,
        args: arguments,
      });
      return;
    }
    this.oneSignal.push(() => {
      this.oneSignal.off(event, listener);
    });
  }

  once(event: string, listener: IEventData): void {
    if (!this._doesOneSignalExist()) {
      this._oneSignalFunctionQueue.push({
        name: OneSignalWebWrapperPublicInterface.once,
        args: arguments,
      });
      return;
    }
    this.oneSignal.push(() => {
      this.oneSignal.once(event, listener);
    });
  }

  isPushNotificationsEnabled(callback?: Action<boolean>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.isPushNotificationsEnabled,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.isPushNotificationsEnabled(callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  showHttpPrompt(options?: IAutoPromptOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.showHttpPrompt,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.showHttpPrompt(options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  registerForPushNotifications(options?: IRegisterOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.registerForPushNotifications,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.registerForPushNotifications(options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  setDefaultNotificationUrl(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.setDefaultNotificationUrl,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.setDefaultNotificationUrl(url)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  setDefaultTitle(title: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.setDefaultTitle,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.setDefaultTitle(title)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  getTags(callback?: Action<any>): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.getTags,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.getTags(callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  sendTag(key: string, value: any, callback?: Action<Object>): Promise<Object | null> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.sendTag,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.sendTag(key, value, callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  sendTags(tags: ITagsObject<any>, callback?: Action<Object>): Promise<Object | null> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.sendTags,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.sendTags(tags, callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  deleteTag(tag: string): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.deleteTag,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.deleteTag(tag)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  deleteTags(tags: Array<string>, callback?: Action<Array<string>>): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.deleteTags,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.deleteTags(tags, callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  addListenerForNotificationOpened(callback?: Action<Notification>): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.addListenerForNotificationOpened,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.addListenerForNotificationOpened(callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  setSubscription(newSubscription: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.setSubscription,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.setSubscription(newSubscription)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  showHttpPermissionRequest(options?: IAutoPromptOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.showHttpPermissionRequest,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.showHttpPermissionRequest(options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  showNativePrompt() {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.showNativePrompt,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.showNativePrompt()
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  showSlidedownPrompt(options?: IAutoPromptOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.showSlidedownPrompt,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.showSlidedownPrompt(options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  showCategorySlidedown(options?: IAutoPromptOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.showCategorySlidedown,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.showCategorySlidedown(options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  showSmsSlidedown(options?: IAutoPromptOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.showSmsSlidedown,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.showSmsSlidedown(options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  showEmailSlidedown(options?: IAutoPromptOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.showEmailSlidedown,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.showEmailSlidedown(options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  showSmsAndEmailSlidedown(options?: IAutoPromptOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.showSmsAndEmailSlidedown,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.showSmsAndEmailSlidedown(options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  getNotificationPermission(onComplete?: Action<NotificationPermission>): Promise<NotificationPermission> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.getNotificationPermission,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.getNotificationPermission(onComplete)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  getUserId(callback?: Action<string | undefined | null>): Promise<string | undefined | null> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.getUserId,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.getUserId(callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  getSubscription(callback?: Action<boolean>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.getSubscription,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.getSubscription(callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  setEmail(email: string, options?: ISetEmailOptions): Promise<string | null> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.setEmail,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.setEmail(email, options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  setSMSNumber(smsNumber: string, options?: ISetSMSOptions): Promise<string | null> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.setSMSNumber,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.setSMSNumber(smsNumber, options)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  logoutEmail(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.logoutEmail,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.logoutEmail()
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  logoutSMS(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.logoutSMS,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.logoutSMS()
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  setExternalUserId(externalUserId: string | undefined | null, authHash?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.setExternalUserId,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.setExternalUserId(externalUserId, authHash)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  removeExternalUserId(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.removeExternalUserId,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.removeExternalUserId()
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  getExternalUserId(): Promise<string | undefined | null> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.getExternalUserId,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.getExternalUserId()
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  provideUserConsent(consent: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.provideUserConsent,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.provideUserConsent(consent)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  getEmailId(callback?: Action<string | undefined>): Promise<string | null | undefined> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.getEmailId,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.getEmailId(callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  getSMSId(callback?: Action<string | undefined>): Promise<string | null | undefined> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.getSMSId,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.getSMSId(callback)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  sendOutcome(outcomeName: string, outcomeWeight?: number | undefined): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._doesOneSignalExist()) {
        this._oneSignalFunctionQueue.push({
          name:            OneSignalWebWrapperPublicInterface.sendOutcome,
          args:            arguments,
          promiseResolver: resolve,
          promiseRejector: reject,
        });
        return;
      }
      this.oneSignal.push(() => {
        this.oneSignal.sendOutcome(outcomeName, outcomeWeight)
          .then((value: any) => resolve(value))
          .catch((error: any) => reject(error));
      });
    });
  }

  /**
   * _injectScript
   * - Adds the script tag to the document
   * - When loaded, returns true
   * - When failed, returns false
   * @returns 
   */
  private _injectScript() {
    return new Promise((resolve, _) => {
      const script   = document.createElement('script');
      script.id      = this._ONESIGNAL_SDK_ID;
      script.src     = this._ONESIGNAL_SCRIPT_SRC;
      script.async   = true;
      script.onload  = () => resolve(true);
      script.onerror = () => resolve(false);
      document.head.appendChild(script);
    });
  }

  /**
   * _doesOneSignalExist
   * - Returns true when the OneSignal object already exists on the window object
   * @returns 
   */
  private _doesOneSignalExist() {
    return this.oneSignal ? true : false;
  }

  /**
   * _setupOneSignalIfMissing
   * - Ensures window.OneSignal has a value
   */
  private _setupOneSignalIfMissing() {
    if (!this._doesOneSignalExist()) {
      (window as any).OneSignal = (window as any).OneSignal || {};
    }
  }

  /**
   * _processQueuedOneSignalFunctions
   * - Processes all of the items in OneSignal's queue
   */
  private _processQueuedOneSignalFunctions() {
    this._oneSignalFunctionQueue.forEach((element) => {
      const { name, args, promiseResolver, promiseRejector } = element;
      if (promiseResolver) {
        (this[name] as any)(...args)
          .then((result: any) => promiseResolver(result))
          .catch((error: any) => promiseRejector(error));
      } else {
        this.oneSignal[name](...args);
      }
    });
  }
}

enum OneSignalWebWrapperPublicInterface {
  init                             = 'init',
  on                               = 'on',
  off                              = 'off',
  once                             = 'once',
  isPushNotificationsEnabled       = 'isPushNotificationsEnabled',
  showHttpPrompt                   = 'showHttpPrompt',
  registerForPushNotifications     = 'registerForPushNotifications',
  setDefaultNotificationUrl        = 'setDefaultNotificationUrl',
  setDefaultTitle                  = 'setDefaultTitle',
  getTags                          = 'getTags',
  sendTag                          = 'sendTag',
  sendTags                         = 'sendTags',
  deleteTag                        = 'deleteTag',
  deleteTags                       = 'deleteTags',
  addListenerForNotificationOpened = 'addListenerForNotificationOpened',
  setSubscription                  = 'setSubscription',
  showHttpPermissionRequest        = 'showHttpPermissionRequest',
  showNativePrompt                 = 'showNativePrompt',
  showSlidedownPrompt              = 'showSlidedownPrompt',
  showCategorySlidedown            = 'showCategorySlidedown',
  showSmsSlidedown                 = 'showSmsSlidedown',
  showEmailSlidedown               = 'showEmailSlidedown',
  showSmsAndEmailSlidedown         = 'showSmsAndEmailSlidedown',
  getNotificationPermission        = 'getNotificationPermission',
  getUserId                        = 'getUserId',
  getSubscription                  = 'getSubscription',
  setEmail                         = 'setEmail',
  setSMSNumber                     = 'setSMSNumber',
  logoutEmail                      = 'logoutEmail',
  logoutSMS                        = 'logoutSMS',
  setExternalUserId                = 'setExternalUserId',
  removeExternalUserId             = 'removeExternalUserId',
  getExternalUserId                = 'getExternalUserId',
  provideUserConsent               = 'provideUserConsent',
  getEmailId                       = 'getEmailId',
  getSMSId                         = 'getSMSId',
  sendOutcome                      = 'sendOutcome',
}

interface IOneSignalFunctionQueueItem {
  name:             OneSignalWebWrapperPublicInterface;
  args:             IArguments;
  promiseResolver?: any;
  promiseRejector?: any;
}

interface IOneSignalInitResult {
  error:  { code: number; message: string } | null;
  loaded: boolean;
}

interface IInitObject {
  appId:                           string;
  subdomainName?:                  string;
  requiresUserPrivacyConsent?:     boolean;
  promptOptions?:                  object;
  welcomeNotification?:            object;
  notifyButton?:                   object;
  persistNotification?:            boolean;
  webhooks?:                       object;
  autoResubscribe?:                boolean;
  autoRegister?:                   boolean;
  notificationClickHandlerMatch?:  string;
  notificationClickHandlerAction?: string;
  serviceWorkerParam?:             { scope: string; };
  serviceWorkerPath?:              string;
  serviceWorkerUpdaterPath?:       string;
  path?:                           string;
  allowLocalhostAsSecureOrigin?:   boolean;
  [key: string]:                   any;
}

interface IEventData {
  (eventData?: any): void;
}

declare type Action<T> = (item: T) => void;

interface IAutoPromptOptions {
  force?: boolean;
  forceSlidedownOverNative?: boolean;
  slidedownPromptOptions?: IOneSignalAutoPromptOptions;
}

interface IRegisterOptions {
  modalPrompt?: boolean;
  httpPermissionRequest?: boolean;
  slidedown?: boolean;
  autoAccept?: boolean;
}

interface ISetSMSOptions {
  identifierAuthHash?: string;
}

interface ISetEmailOptions {
  identifierAuthHash?: string;
  emailAuthHash?: string;
}

interface ITagsObject<T> {
  [key: string]: T;
}

interface IOneSignalAutoPromptOptions {
  force?: boolean;
  forceSlidedownOverNative?: boolean;
  isInUpdateMode?: boolean;
  categoryOptions?: IOneSignalCategories;
}

interface IOneSignalCategories {
  positiveUpdateButton: string;
  negativeUpdateButton: string;
  savingButtonText: string;
  errorButtonText: string;
  updateMessage: string;
  tags: IOneSignalTagCategory[];
}

interface IOneSignalTagCategory {
  tag: string;
  label: string;
  checked?: boolean;
}
