import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalStatics {
  static readonly GO_BACK = 'goBack';
  static readonly CONFIRM = 'confirm';

  constructor() {}
}
