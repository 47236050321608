import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SectionPreviewData } from './section-preview-card.model';

@Component({
  selector: 'app-section-preview-card',
  templateUrl: './section-preview-card.component.html',
  styleUrls: ['./section-preview-card.component.scss'],
  standalone: false,
})
export class SectionPreviewCardComponent implements OnChanges {

  @Input() sectionPreviewData: SectionPreviewData | null = null;
  @Input() isSelected     = false;
  @Input() numSelections  = 0;
  @Input() showBookButton = true;
  @Input() showPrefMatch  = true;
  @Output() prefClicked   = new EventEmitter<number>();

  isNew = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sectionPreviewData && changes.sectionPreviewData.currentValue) {
      const aMonthAgo = new Date();
      aMonthAgo.setDate(-31);
      this.isNew = (this.sectionPreviewData?.listingDate || new Date()) >= aMonthAgo;
    }
  }
}
