import { Pipe, LOCALE_ID, Inject, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Preference } from '../services/preference.service';

@Pipe({
  name: 'currencyGlobal',
  standalone: false,
})
export class CurrencyGlobalPipe extends CurrencyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private _preference: Preference,
  ) {
    super(environment.defaultLang);
  }

  transform(value: string | number, currencyCode?: string | undefined, display?: string | boolean | undefined, digitsInfo?: string | undefined, locale?: string | undefined): string | null;
  transform(value: null | undefined, currencyCode?: string | undefined, display?: string | boolean | undefined, digitsInfo?: string | undefined, locale?: string | undefined): null;
   
  transform(
    value: string | number | null | undefined,
    currencyCode?: string | undefined,
    display?: string | boolean | undefined,
    digitsInfo?: string | undefined,
    locale?: string | undefined
  ): string | null;
  
  transform(value: string | number | null | undefined): string | null {
    return super.transform(
      value,
      environment.currency.code,
      environment.currency.display,
      environment.currency.digitsInfo,
      this._preference.lang || environment.defaultLang,
    );
  }
}
