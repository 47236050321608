import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { IBookingAddOnTemplateTree } from 'src/app/services/bookings-add-ons.service';
import { BookingRequest } from '../pages/booking-cart/booking-request';

@Pipe({
  name: 'requestStartDateToHumanReadable',
  standalone: false,
})
export class RequestStartDateToHumanReadablePipe implements PipeTransform {

  transform(bookingRequest: BookingRequest, addOnTemplateTree: IBookingAddOnTemplateTree, notAvailableTxt: string): string {
    if (!bookingRequest || !bookingRequest.startDate) {
      return '';
    }
    const parts = bookingRequest.startDate.substring(0, 10).split('-');
    const dt    = DateTime.fromObject({
      year:  Number(parts[0]),
      month: Number(parts[1]),
      day:   Number(parts[2]),
    });
    const isAvailable = addOnTemplateTree.extras.some(extra => extra.bookings.some(booking =>
      extra.placeId     === bookingRequest.placeId   &&
      booking.bookingId === bookingRequest.bookingId &&
      booking.isAvailable,
    ));
    return dt.toFormat('LLL dd') + (isAvailable ? '' : ' ' + notAvailableTxt);
  }
}
