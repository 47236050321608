// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-container {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.close {
  height: 16px;
  width: 16px;
  position: relative;
  left: 4px;
}

.modal-close {
  height: 24px;
  width: 24px;
}

.exit-preview-button:hover,
.exit-preview-button:focus {
  text-decoration: underline;
  color: var(--ion-color-primary-tint);
}
.exit-preview-button:hover > ion-icon.close,
.exit-preview-button:focus > ion-icon.close {
  color: var(--ion-color-primary-tint);
}

.logo {
  height: 36px;
  width: 36px;
}

ion-header {
  height: 64px;
}

ion-toolbar {
  display: flex;
  align-items: center;
  height: 64px;
}

ion-avatar > img {
  border-color: var(--ion-color-secondary);
  padding: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/profile-preview/profile-preview.page.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;AAAJ;;AAGA;EACI,YAAA;EACA,WAAA;AAAJ;;AAGA;;EAEI,0BAAA;EACA,oCAAA;AAAJ;AAEI;;EACI,oCAAA;AACR;;AAGA;EACI,YAAA;EACA,WAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,wCAAA;EACA,YAAA;AAAJ","sourcesContent":["\n.name-container {\n    display: flex;\n    align-items: center;\n    margin-left: 12px;\n}\n\n.close {\n    height: 16px;\n    width: 16px;\n    position: relative;\n    left: 4px;\n}\n\n.modal-close {\n    height: 24px;\n    width: 24px;\n}\n\n.exit-preview-button:hover, \n.exit-preview-button:focus {\n    text-decoration: underline;\n    color: var(--ion-color-primary-tint);\n\n    > ion-icon.close {\n        color: var(--ion-color-primary-tint);\n    }\n}\n\n.logo {\n    height: 36px;\n    width: 36px;\n}\n\nion-header {\n    height: 64px;\n}\n\nion-toolbar {\n    display: flex;\n    align-items: center;\n    height: 64px;\n}\n\nion-avatar > img {\n    border-color: var(--ion-color-secondary);\n    padding: 2px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
