import { ChangeDetectionStrategy, Component, Injector, Input, OnDestroy } from '@angular/core';
import { User } from 'src/app/services/user.service';
import { BasePageModal } from '../base-page-modal/base-page-modal';
import { ModalStatics } from 'src/app/services/modal.service';

@Component({
  selector: 'app-sign-in-social-completion',
  templateUrl: './sign-in-social-completion.component.html',
  styleUrls: ['./sign-in-social-completion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SignInSocialCompletionComponent extends BasePageModal implements OnDestroy {

  @Input() user!: User;

  private _isSubmitted = false;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnDestroy(): void {
    if (!this._isSubmitted) {
      // Delete the user (commented out for now)
      // this.user.destroy();
      window.dispatchEvent(new CustomEvent('user:logout'));
    }
  }

  onDismiss() {
    this.modalCtrl.dismiss({ action: ModalStatics.GO_BACK });
  }

  onUserUpdated(user: User) {
    this._isSubmitted = true;
    this.modalCtrl.dismiss({
      action: ModalStatics.CONFIRM,
      user,
      isIncomplete: user.isBasicIncomplete(),
    });
  }
}
