import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastSchedulerService {

  private readonly _DEFAULT_COLOR    = 'dark';
  private readonly _DEFAULT_DURATION = 1500;
  private readonly _DEFAULT_ICON     = 'checkmark-circle';
  private readonly _DEFAULT_POSITION = 'bottom';

  constructor(
    private _toastCtrl: ToastController,
  ) { }

  /**
   * Adds a new toast to be displayed
   * - If there is a toast currently being displayed, it is removed and replaced with the new one
   * - In that case, the leave and enter animations are disabled ... there is a quick flash when switching
   *   between the toasts
   * @param options 
   */
  async add(options: ToastOptions) {
    // Remove the top toast if it exists and remove animations so the new toast appears to replace the current one
    const topToast = await this._toastCtrl.getTop();
    if (topToast) {
      topToast.animated = false;
      options.animated  = false;
      await topToast.dismiss();
    }
    options.color    = options.color    || this._DEFAULT_COLOR;
    options.duration = options.duration || this._DEFAULT_DURATION;
    options.icon     = options.icon     || this._DEFAULT_ICON;
    options.position = options.position || this._DEFAULT_POSITION;
    const toast      = await this._toastCtrl.create(options);
    await toast.present();
    // Re-enable animations
    toast.animated = true;
  }
}
