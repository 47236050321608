import { Pipe, PipeTransform } from '@angular/core';
import { Place } from '../services/place.service';

@Pipe({
  name: 'placeRequiresCertn',
  standalone: false,
})
export class PlaceRequiresCertnPipe implements PipeTransform {

  /**
   * Returns true when the place requires a Certn background check to book
   * @param place Place | PlaceListingFragment
   * @returns 
   */
  transform(place: any): boolean {
    if (!place) {return false;}
    const choice = place.verificationChoice || place.attributes?.verificationChoice;
    return choice === Place.VERIFICATION_CHOICES.background;
  }

}
