// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  border-radius: 0;
}

::ng-deep ion-textarea .label-text {
  color: var(--ion-color-dark-shade);
}

ion-textarea {
  --color: var(--ion-color-dark);
}

.invalid {
  --border-color: var(--ion-color-danger);
}

ion-select::part(container) {
  font-size: 16px;
  color: var(--ion-color-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-cancellation/modal-cancellation.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,uCAAA;AACF;;AAGE;EACE,eAAA;EACA,4BAAA;AAAJ","sourcesContent":["ion-card {\n  border-radius: 0;\n}\n\n::ng-deep ion-textarea .label-text {\n  color: var(--ion-color-dark-shade);\n}\n\nion-textarea {\n  --color: var(--ion-color-dark);\n}\n\n.invalid {\n  --border-color: var(--ion-color-danger);\n}\n\nion-select{\n  &::part(container) {\n    font-size: 16px;\n    color: var(--ion-color-dark);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
