import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlaceListingFragment } from 'src/generated/graphql';
import { BasePageModal } from '../base-page-modal/base-page-modal';
import { BookingClosedDatesAndTimesService } from 'src/app/services/booking-closed-dates-and-times.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ClosedDateAndTime } from './closed-date-and-time';
import { DateTime } from 'luxon';
import { BookingStatusService } from 'src/app/services/booking-status.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { User } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { IonContent } from '@ionic/angular';

class Time {
  display = '';
  value   = '';

  constructor(display?: string, value?: string) {
    this.display = display || '';
    this.value   = value   || '-1';
  }
}

@Component({
  standalone: false,
  selector: 'app-booking-closed-dates-and-times',
  templateUrl: './booking-closed-dates-and-times.page.html',
  styleUrls: ['./booking-closed-dates-and-times.page.scss'],
  animations: [
    trigger('fadeInOut', [
      state('true', style({ overflow: 'hidden', opacity: 1, height: '*' })),
      state('false', style({ overflow: 'hidden', opacity: 0, height: 0 })),
      transition('0 => 1', animate('250ms ease-out')),
      transition('1 => 0', animate('250ms ease-in')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingClosedDatesAndTimesPage extends BasePageModal implements OnInit, OnDestroy {

  @Input() place: PlaceListingFragment | null = null;

  editing              = false;
  closedDateAndTime: ClosedDateAndTime | null = null;
  closedDatesAndTimes$ = new Observable<ClosedDateAndTime[]>();
  fromTime: Time       = new Time();
  toTime: Time         = new Time();
  fromTimes: Time[]    = [];
  toTimes: Time[]      = [];

  private _destroy$      = new Subject<boolean>();
  private _statusId      = '';
  private _subscription  = Subscription.EMPTY;
  private _times: Time[] = [];
  private _user!: User;

  @ViewChild(IonContent) private _ionContent!: IonContent;

  constructor(
    injector: Injector,
    private _service: BookingClosedDatesAndTimesService,
    private _statuses: BookingStatusService,
    private _changeRef: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit() {
    this._statuses.isLoaded.pipe(takeUntil(this._destroy$)).subscribe({
      next: (isLoaded) => {
        if (isLoaded) {
          this._statusId = this._statuses.statusIdFromEnum(BookingStatusService.ENUM_CLOSED_BY_HOST);
        }
      },
    });
    this._user                = User.getCurrent();
    this.closedDatesAndTimes$ = this._service.closedDatesAndTimes;
    this._generateTimes();
    this._service.load(this.place?.objectId || '', this.place?.timeZone || '');
    this.addNew();
    this._watchChanges();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._destroy$.next(true);
  }

  addNew() {
    this.edit(this._getEmpty());
  }

  /**
   * Sets up editing a closed time
   * @param closedDateAndTime 
   */
  async edit(closedDateAndTime: ClosedDateAndTime) {
    this.closedDateAndTime = ClosedDateAndTime.newFromSelf(closedDateAndTime);
    const hasFromTime      = closedDateAndTime.fromTime && closedDateAndTime.fromTime !== '-1';
    const hasToTime        = closedDateAndTime.toTime   && closedDateAndTime.toTime   !== '-1';

    this.fromTime = hasFromTime ? this.fromTimes.find(time => time.value === closedDateAndTime.fromTime) || new Time() : new Time();
    this._setAvailableToTimes(this.fromTime);

    this.toTime = hasToTime ? this.toTimes.find(time => time.value === closedDateAndTime.toTime) || new Time() : new Time();
    await this._ionContent.scrollToPoint(0, 0, 250);
    this.editing = true;
    this._changeRef.markForCheck();
  }

  /**
   * Picks the date
   * @param e 
   */
  setDate(e: any) {
    const dateTime = e.detail.value as string;
    (this.closedDateAndTime as ClosedDateAndTime).yyyyMmDd = dateTime.substring(0, 10);
    this._changeRef.markForCheck();
  }

  /**
   * Selects the fromTime and updates the available toTimes
   * @param e 
   */
  setFromTime(e: any) {
    const val     = e.detail.value;
    this.fromTime = this.fromTimes.find(time => time.value === val) || new Time();
    (this.closedDateAndTime as ClosedDateAndTime).fromTime = this.fromTime.value;
    this._setAvailableToTimes(this.fromTime);
    this._changeRef.markForCheck();
  }

  /**
   * Selects the toTime
   * @param e 
   */
  setToTime(e: any) {
    const val   = e.detail.value;
    this.toTime = this.toTimes.find(time => time.value === val) || new Time();
    (this.closedDateAndTime as ClosedDateAndTime).toTime = this.toTime.value;
    this._changeRef.markForCheck();
  }

  /**
   * Cancels editing
   */
  cancel() {
    this.closedDateAndTime = this._getEmpty();
    this.editing           = false;
  }

  /**
   * Saves a Closed Date and Time
   */
  save() {
    this._service.save(this.place?.objectId || '', this.place?.timeZone || '', this._user.id, this._statusId, this.closedDateAndTime as ClosedDateAndTime);
    this._changeRef.markForCheck();
  }

  /**
   * Removes a Closed Date and Time
   * @param closedDateAndTime 
   */
  async remove(closedDateAndTime: ClosedDateAndTime) {
    const yes = await this.showConfirm('Are you sure you want to delete this closure time?', {
      OK: 'Yes',
      CANCEL: 'No',
    });
    if (yes) {
      this._service.remove(closedDateAndTime);
      this._changeRef.markForCheck();
    }
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  /**
   * Returns and empty ClosedDateAndTime object
   * @returns 
   */
  private _getEmpty() {
    return new ClosedDateAndTime('', DateTime.now().toFormat('yyyy-MM-dd'), '', '');
  }

  /**
   * Generates the list of fromTimes
   */
  private _generateTimes() {
    this._times = [...Array(24).keys()].map((key) => {
      const display = ClosedDateAndTime.timeToDisplay(key.toString());
      const value   = ('0' + key).slice(-2);
      return new Time(display, value);
    });
    this.fromTimes = [...this._times];
    this._setAvailableToTimes({ display: '', value: '-1' });
  }

  /**
   * Updates the list of available toTimes based on the fromTimes
   * @param time 
   */
  private _setAvailableToTimes(time: Time) {
    const val    = time && time.value ? parseInt(time.value, 10) : -1;
    this.toTimes = this._times.filter(item => parseInt(item.value, 10) > val);
    this.toTimes.push(new Time(ClosedDateAndTime.END_OF_DAY, ClosedDateAndTime.END_OF_DAY_TIME));

    const toVal = parseInt(this.toTime.value, 10);
    if (toVal <= val && this.closedDateAndTime) {
      this.toTime                   = new Time();
      this.closedDateAndTime.toTime = this.toTime.value;
    }
    this._changeRef.markForCheck();
  }

  /**
   * Set the editing value when the array list changes
   */
  private _watchChanges() {
    this._subscription = this._service.closedDatesAndTimes.subscribe((val) => {
      this.editing = val.length === 0;
      this._changeRef.markForCheck();
    });
  }
}
