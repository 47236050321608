import { ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonTextarea } from '@ionic/angular';
import { BasePageModal } from 'src/app/pages/base-page-modal/base-page-modal';
import { IModalDetails } from '../i-modal-details';
import { ModalStatics } from 'src/app/services/modal.service';


@Component({
  selector: 'app-modal-content-with-textarea',
  templateUrl: './modal-content-with-textarea.page.html',
  styleUrls: ['./modal-content-with-textarea.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ModalContentWithTextareaPage extends BasePageModal implements OnInit {

  @Input() modalDetails!: IModalDetails;

  safeHtml: SafeHtml | null = null;
  msg = '';

  @ViewChild('ionItem', { read: ElementRef }) private _ionItem!: ElementRef;
  @ViewChild(IonTextarea) private _textArea!: IonTextarea;
  @ViewChild('errorMsg') private _errorMsg!: ElementRef;

  constructor(
    injector: Injector,
    private _sanitizer: DomSanitizer,
    private _renderer: Renderer2,
  ) {
    super(injector);
  }

  ngOnInit() {
    const modalDetailsDefaults = { required: true };
    this.modalDetails.textarea = Object.assign({}, modalDetailsDefaults, this.modalDetails.textarea);
    this.safeHtml              = this._sanitizer.bypassSecurityTrustHtml(this.modalDetails.content);
  }

  async buttonClick(action: string) {
    if (action === ModalStatics.GO_BACK || !this.modalDetails.textarea?.required || this.modalDetails.textarea.required && this.msg) {
      this.modalCtrl.dismiss({
        action,
        msg: this.msg,
      });
    } else {
      this._renderer.addClass(this._ionItem.nativeElement, 'invalid');
      this._textArea.setFocus();
    }
  }
}
