import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import * as Parse from 'parse';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { ICalendarDate } from '../components-standalone/calendar/calendar.component';
import { PreferenceMatchCalculator } from '../helpers/preference-match-calculator';
import { CleaningHoursPipe } from '../pipes/cleaning-hours.pipe';
import { PlacePrefCacheService } from './place-pref-cache.service';
import { Place } from './place.service';
import { Preference } from './preference.service';
import { TimeSlot } from '../pages/booking-cart/components/cart-dates-and-times/booking-time-slots/time-slot';
import { User } from './user.service';
import { CalendarDate } from '../pages/booking-cart/components/cart-dates-and-times/booking-calendar/calendar-date';
import { LocationAddress } from '../interfaces/location-address';

/* ***********************************************************************
 * PUBLIC INTERFACES
 * **********************************************************************/
/**
 * IAppliedFilter interface
 * - Defines the data used in the Filter pills on the place-list page
 * - The id property is required for items that are in an array (kitchenTypes),
 *   so we know which one to remove when they click the X button
 */
export interface IAppliedFilter {
  display: string;
  prop:    string;
  id?:     string;
}

export interface IInsurance {
  id:    string;
  title: string;
  value: number;
}

/**
 * IKitchenType interface
 * - Defines the simple data for storing the kitchenType
 */
export interface IKitchenType {
  id:    string;
  title: string;
}

/**
 * IPlaceSearchInfo interface
 * - Defines the requirements of searching by date availability
 * - This saves us from having to run a fetch on Place in the API
 * - The API still needs to run a fetch against the PlaceRate data
 */
export interface IPlaceSearchInfo {
  id:          string;
  leadTime:    number;
  timeZone:    string;
  placeRateId: string;
}

/* ***********************************************************************
 * PRIVATE INTERFACES
 * **********************************************************************/
/**
 * ICalendarCache
 * - Defines the cache structure for calendar data
 * - The key is in the form of YEAR_MONTH
 */
interface ICalendarCache {
  [key: string]: CalendarDate[];
}

/**
 * IPlaceCache
 * - Defines the cache structure for place data
 * - The key is in the form of LATITUDE_LONGITUDE
 */
interface IPlaceCache {
  [key: string]: Place[];
}

/* ***********************************************************************
 * PUBLIC CLASSES
 * **********************************************************************/
export interface IPoint {
  lat: number;
  lng: number;
}
export interface IBounds {
  southWest: IPoint;
  northEast: IPoint;
}
/**
 * SearchLocation class
 * - Defines the structure of a search location
 * - There is a similar interface called LocationAddress, but since it is only
 *   an interface, it doesn't have a constructor
 */
export class SearchLocation implements LocationAddress {
  address   = '';
  latitude  = 0;
  longitude = 0;
  bounds: IBounds | null = null;

  constructor(address: string, latitude: number, longitude: number, bounds: IBounds | null = null) {
    this.address   = address;
    this.latitude  = latitude;
    this.longitude = longitude;
    this.bounds    = bounds;
  }
}

/**
 * SearchCalendarDate class
 * - Defines the structure of a calendar date
 * - ICalendarDate is common between the calendar data used in 
 *   search and booking
 */
export class SearchCalendarDate implements ICalendarDate {
  year                = 0;
  month               = 0;
  day                 = 0;
  slots: TimeSlot[]   = [];
  available           = false;
  selected            = false;
  isExtraLeadTimeDay  = false;
  maxConsecutiveHours = 0;
  isTotalTimeTooShort = false;

  constructor(
    year: number,
    month: number,
    day: number,
    slots: TimeSlot[],
    available: boolean,
    selected: boolean,
    isExtraLeadTimeDay: boolean,
    maxConsecutiveHours: number,
    isTotalTimeTooShort: boolean,
  ) {
    this.year                = year;
    this.month               = month;
    this.day                 = day;
    this.slots               = slots;
    this.available           = available;
    this.selected            = selected;
    this.isExtraLeadTimeDay  = isExtraLeadTimeDay;
    this.maxConsecutiveHours = maxConsecutiveHours;
    this.isTotalTimeTooShort = isTotalTimeTooShort;
  }
}

/**
 * RangeSelection class
 * - Defines a range selection for use with an IonRange with two thumbs
 */
export class RangeSelection {
  lower = 0;
  upper = 0;
  min   = 0;
  max   = 0;

  constructor(lower: number, upper: number, min: number, max: number) {
    this.lower = lower;
    this.upper = upper;
    this.min   = min;
    this.max   = max;
  }
}

/* ***********************************************************************
 * PRIVATE CLASSES
 * **********************************************************************/
/**
 * Filters class
 * - Defines the available filter properties
 * - Contains the methods to determine which filters are applied, the removal
 *   of filters, if a Place should be included based on the filters, the
 *   calculation of Price Range min/max, and the loading and saving of the
 *   data in local storage
 */
class Filters {
  static readonly DEFAULT_SEARCH_LOCATION = null;
  static readonly DEFAULT_DATE            = null;
  static readonly DEFAULT_DATE_PLACE_IDS  = [];
  static readonly DEFAULT_DATE_UPDATED_AT = null;
  static readonly DEFAULT_HACCP_CERTIFIED = false;
  static readonly DEFAULT_PRICE_RANGE     = new RangeSelection(0, 0, 0, 0);
  static readonly DEFAULT_PREF_MATCH      = 0;
  static readonly DEFAULT_DISTANCE        = 25;
  static readonly DEFAULT_INSURANCE       = [];
  static readonly DEFAULT_KITCHEN_TYPES   = [];

  searchLocation: SearchLocation     | null = Filters.DEFAULT_SEARCH_LOCATION;
  date:           SearchCalendarDate | null = Filters.DEFAULT_DATE;
  datePlaceIds:   string[]                  = [...Filters.DEFAULT_DATE_PLACE_IDS];
  priceRange:     RangeSelection            = { ...Filters.DEFAULT_PRICE_RANGE };
  prefMatch:      number                    = Filters.DEFAULT_PREF_MATCH;
  distance:       number                    = Filters.DEFAULT_DISTANCE;
  haccpCertified: boolean                   = Filters.DEFAULT_HACCP_CERTIFIED;
  insurance:      IInsurance[]              = [...Filters.DEFAULT_INSURANCE];
  kitchenTypes:   IKitchenType[]            = [...Filters.DEFAULT_KITCHEN_TYPES];
  dateUpdatedAt:  DateTime           | null = Filters.DEFAULT_DATE_UPDATED_AT;
  userId                                    = '';

  private _translatedNoInsurance               = '';
  private _translatedMillion                   = '';
  private _translatedTextMatch                 = '';
  private _translatedHaccp                     = '';
  private _translator: TranslateService | null = null;

  constructor(translator: TranslateService) {
    this._translator = translator;
    this._translateRequiredText();
  }

  /**
   * Used to generate a properly created Filters class
   * @param filterJSON 
   * @returns 
   */
  static fromJSON(filterJSON: any, translator: TranslateService) {
    const filters            = new Filters(translator);
    if (filterJSON) {
      filters.userId         = filterJSON.userId         || '';
      filters.searchLocation = filterJSON.searchLocation || Filters.DEFAULT_SEARCH_LOCATION;
      filters.prefMatch      = filterJSON.prefMatch      || Filters.DEFAULT_PREF_MATCH;
      filters.distance       = filterJSON.distance       || Filters.DEFAULT_DISTANCE;
      filters.haccpCertified = filterJSON.haccpCertified || Filters.DEFAULT_HACCP_CERTIFIED;
      filters.insurance      = filterJSON.insurance      || [...Filters.DEFAULT_INSURANCE];
      filters.kitchenTypes   = filterJSON.kitchenTypes   || [...Filters.DEFAULT_KITCHEN_TYPES];
      filters.datePlaceIds   = filterJSON.datePlaceIds   || [...Filters.DEFAULT_DATE_PLACE_IDS];
      const dateUpdatedAt    = filterJSON.dateUpdatedAt;
      filters.dateUpdatedAt  = dateUpdatedAt ? DateTime.fromMillis(dateUpdatedAt) : Filters.DEFAULT_DATE_UPDATED_AT;
      // Convert the date into a CalendarDate object
      if (filterJSON.date) {
        filters.date = new SearchCalendarDate(
          filterJSON.date.year,
          filterJSON.date.month,
          filterJSON.date.day,
          filterJSON.date.slots,
          filterJSON.date.available,
          filterJSON.date.selected,
          filterJSON.date.isExtraLeadTimeDay,
          filterJSON.date.maxConsecutiveHours,
          filterJSON.date.isTotalTimeTooShort,
        );
      }
      // Convert the priceRange into a RangeSelection object
      if (filterJSON.priceRange) {
        filters.priceRange = new RangeSelection(
          isNaN(filterJSON.priceRange.lower) ? 0 : filterJSON.priceRange.lower,
          isNaN(filterJSON.priceRange.upper) ? 0 : filterJSON.priceRange.upper,
          isNaN(filterJSON.priceRange.min)   ? 0 : filterJSON.priceRange.min,
          isNaN(filterJSON.priceRange.max)   ? 0 : filterJSON.priceRange.max,
        );
      } else {
        filters.priceRange = { ...Filters.DEFAULT_PRICE_RANGE };
      }
    }
    return filters;
  }

  get isFilteredByDate() {
    return this.date !== null
        && this.date.day > 0;
  }
  set isFilteredByDate(doNotUse) { }

  get isFilteredByPriceRange() {
    return this.priceRange !== null
        && (this.priceRange.lower !== this.priceRange.min
         || this.priceRange.upper !== this.priceRange.max
        );
  }
  set isFilteredByPriceRange(doNotUse) { }

  get isFilteredByPrefMatch() {
    return this.prefMatch !== null
        && this.prefMatch > 0;
  }
  set isFilteredByPrefMatch(doNotUse) { }

  get isFilteredByDistance() {
    return this.distance !== null
        && this.distance > 0;
  }
  set isFilteredByDistance(doNotUse) { }

  get isFilteredByHaccpCertified() {
    return this.haccpCertified;
  }
  set isFilteredByHaccpCertified(doNotUse) { }

  get isFilteredByInsurance() {
    return this.insurance !== null
        && this.insurance.length > 0;
  }
  set isFilteredByInsurance(doNotUse) { }

  get isFilteredByKitchenTypes() {
    return this.kitchenTypes !== null
        && this.kitchenTypes.length > 0;
  }
  set isFilteredByKitchenTypes(doNotUse) { }

  reset(places: Place[]) {
    this.date           = Filters.DEFAULT_DATE;
    this.datePlaceIds   = [...Filters.DEFAULT_DATE_PLACE_IDS];
    this.dateUpdatedAt  = Filters.DEFAULT_DATE_UPDATED_AT;
    this.prefMatch      = Filters.DEFAULT_PREF_MATCH;
    this.distance       = Filters.DEFAULT_DISTANCE;
    this.haccpCertified = Filters.DEFAULT_HACCP_CERTIFIED;
    this.insurance      = [...Filters.DEFAULT_INSURANCE];
    this.kitchenTypes   = [...Filters.DEFAULT_KITCHEN_TYPES];
    this.priceRange     = { ...Filters.DEFAULT_PRICE_RANGE };
    this.getPriceRangeMinAndMax(places);
  }

  /**
   * Converts the public properties into JSON
   * @returns 
   */
  save() {
    return {
      userId:         this.userId,
      searchLocation: this.searchLocation,
      date:           this.date,
      datePlaceIds:   this.datePlaceIds,
      priceRange:     this.priceRange,
      prefMatch:      this.prefMatch,
      distance:       this.distance,
      haccpCertified: this.haccpCertified,
      insurance:      this.insurance,
      kitchenTypes:   this.kitchenTypes,
      dateUpdatedAt:  this.dateUpdatedAt?.toJSDate().getTime() || null,
    };
  }

  /**
   * Determin if the Place should be included in the filteredPlaces array
   * @param place 
   * @param prefsCalc 
   * @param distanceUnit 
   * @returns 
   */
  include(place: Place, prefsCalc: PreferenceMatchCalculator, distanceUnit: string) {
    let shouldInclude = true;
    // Selected Date
    if (this.isFilteredByDate) {
      shouldInclude = this.datePlaceIds.includes(place.id);
    }
    // Selected Price Range
    if (shouldInclude && this.isFilteredByPriceRange) {
      const avgPrice = place.get('placeRate')?.get('avgRate') || -1;
      shouldInclude  = avgPrice >= this.priceRange.lower && avgPrice <= this.priceRange.upper;
    }
    // Selected Pref Match
    if (shouldInclude && this.isFilteredByPrefMatch) {
      shouldInclude = prefsCalc.matchesOverallWeighted * 100 >= this.prefMatch;
    }
    // Selected Distance
    if (shouldInclude && this.isFilteredByDistance) {
      const distance = parseInt(place.distance({ latitude: this.searchLocation?.latitude, longitude: this.searchLocation?.longitude }, distanceUnit) || '0', 10);
      shouldInclude  = distance <= this.distance;
    }
    // Selected Haccp Certified Kitchens
    if (shouldInclude && this.isFilteredByHaccpCertified) {
      const facility = place.facility;
      shouldInclude  = (facility?.get('filesHACCP') || []).length > 0;
    }
    // Selected Insurance
    if (shouldInclude && this.isFilteredByInsurance) {
      shouldInclude = this.insurance.some(insurance => place.insuranceRequired === insurance.value || insurance.value === 0 && !place.insuranceRequired);
    }
    // Selected Kitchen Types
    if (shouldInclude && this.isFilteredByKitchenTypes) {
      shouldInclude = place.categories.some(category => this.kitchenTypes.findIndex(kitchenType => kitchenType.id === category.id) > -1);
    }
    return shouldInclude;
  }

  /**
   * Returns an array of pills to display
   * @param distanceUnit 
   * @returns 
   */
  getAppliedFilterPills(distanceUnit: string) {
    const filterPills: IAppliedFilter[] = [];
    // Selected Price Range
    if (this.isFilteredByPriceRange) {
      filterPills.push({
        display: '$' + this.priceRange.lower + '-$' + this.priceRange.upper,
        prop:    SearchService.PROP_PRICE_RANGE,
      });
    }
    // Selected Pref Match
    if (this.isFilteredByPrefMatch) {
      filterPills.push({
        display: this.prefMatch + '% ' + this._translatedTextMatch,
        prop:    SearchService.PROP_PREF_MATCH,
      });
    }
    // Selected Distance
    if (this.isFilteredByDistance) {
      filterPills.push({
        display: '<' + this.distance + distanceUnit,
        prop:    SearchService.PROP_DISTANCE,
      });
    }
    // Selected Haccp Certified Kitchen
    if (this.isFilteredByHaccpCertified) {
      filterPills.push({
        display: this._translatedHaccp,
        prop: SearchService.PROP_HACCP,
      });
    }
    // Selected Insurance
    if (this.isFilteredByInsurance) {
      this.insurance.forEach((insurance, i) => {
        filterPills.push({
          display: insurance.value > 0 ? '$' + insurance.value + ' ' + this._translatedMillion : this._translatedNoInsurance,
          prop:    SearchService.PROP_INSURANCE,
          id:      i.toString(),
        });
      });
    }
    // Selected Kitchen Types
    if (this.isFilteredByKitchenTypes) {
      this.kitchenTypes.forEach((kitchenType) => {
        filterPills.push({
          display: kitchenType.title,
          prop:    SearchService.PROP_KITCHEN_TYPES,
          id:      kitchenType.id,
        });
      });
    }
    return filterPills;
  }

  /**
   * Removes an applied filter
   * @param appliedFilter 
   */
  removeFilter(appliedFilter: IAppliedFilter) {
    let index = -1;
    switch (appliedFilter.prop) {
      case SearchService.PROP_PRICE_RANGE: {
        const priceRange = this.priceRange || { ...Filters.DEFAULT_PRICE_RANGE };
        priceRange.lower = priceRange.min;
        priceRange.upper = priceRange.max;
        this.priceRange  = priceRange;
        break;
      }
      case SearchService.PROP_PREF_MATCH:
        this.prefMatch = Filters.DEFAULT_PREF_MATCH;
        break;
      case SearchService.PROP_DISTANCE:
        this.distance = Filters.DEFAULT_DISTANCE;
        break;
      case SearchService.PROP_HACCP:
        this.haccpCertified = Filters.DEFAULT_HACCP_CERTIFIED;
        break;
      case SearchService.PROP_INSURANCE:
        index = parseInt(appliedFilter.id || '-1', 10);
        this.insurance.splice(index, 1);
        break;
      case SearchService.PROP_KITCHEN_TYPES:
        index = this.kitchenTypes.findIndex(kitchenType => kitchenType.id === appliedFilter.id);
        this.kitchenTypes.splice(index, 1);
        break;
    }
  }

  /**
   * Loops over the place array to find the min/max value for the priceRange selection
   * @param places 
   */
  getPriceRangeMinAndMax(places: Place[] = []) {
    const isUnfiltered = !this.isFilteredByPriceRange;
    let min            = 100000;
    let max            = 0;
    places.forEach((place) => {
      const avgRate = place.get('placeRate')?.get('avgRate');
      if (avgRate && avgRate > 0) {
        min = Math.min(avgRate, min);
        max = Math.max(avgRate, max);
      }
    });
    this.priceRange.min   = min;
    this.priceRange.max   = max;
    this.priceRange.lower = isUnfiltered ? min : Math.min(max, Math.max(this.priceRange.lower, this.priceRange.min));
    this.priceRange.upper = isUnfiltered ? max : Math.max(min, Math.min(this.priceRange.upper, this.priceRange.max));
    return this.priceRange;
  }

  private async _translateRequiredText() {
    this._translatedNoInsurance = await firstValueFrom(this._translator!.get('INSURANCE_NONE'));
    this._translatedMillion     = (await firstValueFrom(this._translator!.get('MILLION')) || '').toLowerCase();
    this._translatedTextMatch   = await firstValueFrom(this._translator!.get('MATCH'));
    this._translatedHaccp       = await firstValueFrom(this._translator!.get('HACCP'));
  }
}

/**
 * SearchService
 * - Call the init method within your component to ensure the SearchService is
 *   ready to be used (saved filters are loaded, etc.)
 */
@Injectable({
  providedIn: 'root',
})
export class SearchService {

  static readonly PROP_SEARCH_LOCATION = 'searchLocation';
  static readonly PROP_DATE            = 'date';
  static readonly PROP_DATE_PLACE_IDS  = 'datePlaceIds';
  static readonly PROP_PRICE_RANGE     = 'priceRange';
  static readonly PROP_PREF_MATCH      = 'prefMatch';
  static readonly PROP_DISTANCE        = 'distance';
  static readonly PROP_HACCP           = 'haccpCertified';
  static readonly PROP_INSURANCE       = 'insurance';
  static readonly PROP_KITCHEN_TYPES   = 'kitchenTypes';

  static readonly SEARCH_BY_LOCATION   = 'location';
  static readonly SEARCH_BY_DATE       = 'date';
  static readonly SEARCH_BY_FILTER     = 'filter';

  static readonly PRICE_RANGE_MAX      = 150;
  private readonly _FILTER_STORAGE     = 'placeSearchFilters';

  searchBy: string | null = null;

  private _calendarCache: ICalendarCache = {};
  private _filters!: Filters;
  private _placeCache: IPlaceCache       = {};

  private _appliedFiltersSubject$ = new BehaviorSubject<IAppliedFilter[]>([]);
  private _filteredPlaces$        = new BehaviorSubject<Place[]>([]);
  private _filterUpdated$         = new Subject<{ filter: string; value: any }>();

  constructor(
    private _cleaningPipe: CleaningHoursPipe,
    private _prefs: Preference,
    private _placePrefCache: PlacePrefCacheService,
    private _storage: Storage,
    private _translate: TranslateService,
  ) {
    // Listen for logout events (to clear the selected filters)
    window.addEventListener('user:logout', () => {
      this._storage.remove(this._FILTER_STORAGE);
      this.resetSelectedDates();
    });
  }

  /**
   * Returns ALL places for the current latitude/longitude
   * @returns
   */
  get allPlaces(): Place[] {
    return [...this._placeCache[this._placeCacheKey] || []];
  }

  /**
   * Applies the filters to the list of places
   * - Returns a new array containing the matching places
   * @returns 
   */
  get filteredPlaces() {
    return this._filteredPlaces$.asObservable();
  }

  get filterUpdated() {
    return this._filterUpdated$.asObservable();
  }

  /**
   * Returns the applied filters subject as an observable so we can use an async pipe
   * in the HTML to dynamically display which ones are applied
   */
  get appliedFilters() {
    return this._appliedFiltersSubject$.asObservable();
  }

  /**
   * Returns true if the user has booking preferences set
   */
  get userHasPrefs(): boolean {
    return this._prefs.booking ? true : false;
  }

  /**
   * Set up the filters
   * - Listen for logout event (to clear filters)
   * - Load from localStorage
   */
  async init() {
    // Get any currently saved filters
    const filters = await this._storage.get(this._FILTER_STORAGE);
    this._filters = Filters.fromJSON(filters, this._translate);

    // If we have a new user, clear the fitlers
    const user = User.getCurrent();
    if (this._filters.userId !== user.id) {
      this._filters = new Filters(this._translate);
      this._filters.userId = user.id;
      await this._storage.set(this._FILTER_STORAGE, this._filters.save());
    }
  }

  /**
   * Clears the saved filters
   * - Keeps the userId, but clears the others
   */
  async clearFilters() {
    this._filters.reset(this.allPlaces);
    await this._saveFiltersThenFilterPlaces();
    this._filterUpdated$.next({ filter: '', value: null });
  }

  /**
   * Returns the value of a filter property
   * @param prop 
   * @returns 
   */
  getFilterProp(prop: string) {
    return this._filters[prop as keyof Filters];
  }

  /**
   * Returns true/false if the places are filtered by the property type
   * @param prop 
   * @returns 
   */
  getIsFilteredBy(prop: string) {
    return this._filters['isFilteredBy' + prop as keyof Filters];
  }

  /**
   * Sets a filter property value
   * - In the case of the datePlaceIds, also updates the time
   * @param prop 
   * @param val 
   */
  async setFilterProp(prop: string, val: any) {
    switch(prop) {
      case SearchService.PROP_DATE:
        this._filters.date          = val;
        this._filters.dateUpdatedAt = val ? DateTime.now() : null;
        break;
      case SearchService.PROP_DATE_PLACE_IDS:
        this._filters.datePlaceIds  = val;
        this._filters.dateUpdatedAt = val ? DateTime.now() : null;
        break;
      case SearchService.PROP_DISTANCE:
        this._filters.distance = val;
        break;
      case SearchService.PROP_HACCP:
        this._filters.haccpCertified = val;
        break;
      case SearchService.PROP_INSURANCE:
        this._filters.insurance = val;
        break;
      case SearchService.PROP_KITCHEN_TYPES:
        this._filters.kitchenTypes = val;
        break;
      case SearchService.PROP_PREF_MATCH:
        this._filters.prefMatch = val;
        break;
      case SearchService.PROP_PRICE_RANGE:
        if (val.upper === SearchService.PRICE_RANGE_MAX) {
          val.upper = this._filters.priceRange.max;
        }
        this._filters.priceRange = Object.assign(new RangeSelection(0, 0, 0, 0), this._filters.priceRange, val);
        break;
      case SearchService.PROP_SEARCH_LOCATION:
        this._filters.searchLocation = val;
        break;
      default: // Do nothing
    }
    await this._saveFiltersThenFilterPlaces();
    this._filterUpdated$.next({ filter: prop, value: val });
  }

  /**
   * Removes an applied filter and re-filters the places
   * @param appliedFilter 
   */
  async removeFilter(appliedFilter: IAppliedFilter) {
    this._filters.removeFilter(appliedFilter);
    await this._saveFiltersThenFilterPlaces();
    this._filterUpdated$.next({ filter: appliedFilter.prop, value: null });
  }

  /**
   * Resets the calendar's selected date cache
   */
  resetSelectedDates() {
    for (const prop in this._calendarCache) {
      if (this._calendarCache[prop]) {
        this._calendarCache[prop].forEach(item => item.selected = false);
      }
    }
  }

  /**
   * New query for loading places that can be used for local filtering
   * - The previous search queries requires all filterable fields to exist in the Place object
   * - The new filter process filters based on data contained in other tables, so requires a 
   *   more simplified get (returns more places)
   * @param latitude 
   * @param longitude 
   * @param unit 
   * @returns 
   */
  async getPlacesForLocalFiltering(searchLocation: SearchLocation) {
    // Update the filters
    const filteredLocation = this.getFilterProp(SearchService.PROP_SEARCH_LOCATION) as SearchLocation;
    const isNewLocation    = !filteredLocation || filteredLocation.address !== searchLocation.address;
    await this.setFilterProp(SearchService.PROP_SEARCH_LOCATION, searchLocation);
  
    // Get the places
    const key = this._placeCacheKey;
    if (!this._placeCache[key]) {
      this._placeCache[key] = await Parse.Cloud.run('getPlacesForLocalFiltering', {
        bounds:    searchLocation.bounds,
        latitude:  searchLocation.latitude,
        longitude: searchLocation.longitude,
        unit:      this._prefs.unit,
      });
    }

    // Check if we need to get a new list of matching place IDs based on the date
    this._updatePlaceIdsForDate(isNewLocation);
  
    // Get/Set the price range filter
    // This will also filter the places
    this.setFilterProp(SearchService.PROP_PRICE_RANGE, this._filters.getPriceRangeMinAndMax(this._placeCache[key]));
    return this._placeCache[key];
  }
  
  



  /**
   * Returns the selected calendar month
   * - Used by the calendar to draw the month UI
   * @param year 
   * @param month 
   * @param timeZone 
   * @returns 
   */
  async searchGetMonth(year: number, month: number, timeZone: string): Promise<SearchCalendarDate[]> {
    const key = year + '_' + month;
    return new Promise(async (resolve, reject) => {
      try {
        if (!this._calendarCache[key]) {
          try {
            const data: CalendarDate[] = await Parse.Cloud.run('searchGetMonth', { year, month, timeZone });
            this._calendarCache[key]   = data;
          } catch (error) {
            reject(error);
          }
        }
        resolve(this._calendarCache[key]);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Returns a list of place IDs that have availability on the selected day
   * - Used within the calendar component
   * @param year 
   * @param month 
   * @param day 
   * @returns 
   */
  async searchByPlaceDate(year: number, month: number, day: number) {
    const places    = this.allPlaces.map(place => ({
      id:             place.id,
      leadTime:       place.get('bookingLeadTime') || 48,
      timeZone:       place.get('timeZone'),
      placeRateId:    place.get('placeRate')?.id  || null,
      minBookingTime: place.get('minimumBooking') || 0,
    }));
    const totalTime = this._prefs.booking
      ? this._prefs.booking.productionTime + this._cleaningPipe.transform(this._prefs.booking.productionTime)
      : 5;
    return Parse.Cloud.run('searchByPlaceDate', {
      places,
      totalTime,
      year,
      month,
      day,
    });
  }

  /**
   * Returns the formatted date string to display in the search bar
   * @param date 
   * @returns 
   */
  async getFormattedDisplayDate(date: { year: number; month: number; day: number } | null) {
    if (date) {
      return DateTime.fromObject({
        year:  date.year,
        month: date.month,
        day:   date.day,
      }).toFormat('LLL d');
    } else {
      return await firstValueFrom(this._translate.get('ANYTIME'));
    }
  }

  /**
   * Updates the placeIds that have availability on the selected date
   * @param forFetchEvenIfNotOld 
   */
  private async _updatePlaceIdsForDate(forFetchEvenIfNotOld: boolean) {
    // Determine if we need to switch to a different date (because the selected date is old)
    const filteredDate = this.getFilterProp(SearchService.PROP_DATE) as SearchCalendarDate;
    if (filteredDate) {
      // Convert the selected day into a DateTime object
      const date = DateTime.fromObject({
        year:  filteredDate.year,
        month: filteredDate.month,
        day:   filteredDate.day,
      }).startOf('day');

      if (date < DateTime.now().startOf('day')) {
        await this.setFilterProp(SearchService.PROP_DATE, null);
        await this.setFilterProp(SearchService.PROP_DATE_PLACE_IDS, []);
      } else if (forFetchEvenIfNotOld || (this._filters.dateUpdatedAt?.diffNow('hours').hours || 0) > 4) {
        const placeIds = await this.searchByPlaceDate(
          this._filters.date?.year  || 0,
          this._filters.date?.month || 0,
          this._filters.date?.day   || 0,
        );
        await this.setFilterProp(SearchService.PROP_DATE_PLACE_IDS, placeIds.map((placeId: Place) => placeId.id));
      }
    }
  }

  /**
   * Returns the cache key
   */
  private get _placeCacheKey() {
    const searchLocation = this.getFilterProp(SearchService.PROP_SEARCH_LOCATION) as SearchLocation;
    return searchLocation?.latitude + '_' + searchLocation?.longitude;
  }

  /**
   * Saves the selected filters to the local storage, then filters the places and
   * emits the next set of pills to display
   */
  private async _saveFiltersThenFilterPlaces() {
    await this._storage.set(this._FILTER_STORAGE, this._filters.save());
    this._filterPlaces();
    this._appliedFiltersSubject$.next(this._filters.getAppliedFilterPills(this._prefs.unit));
  }

  /**
   * Filters the place list based on the user's filter settings
   */
  private _filterPlaces() {
    const places = this._placeCache[this._placeCacheKey] || [];
    const filteredPlaces =  places.filter((place) => {
      const prefsCalc = this._placePrefCache.fromCache(place.id, place, this._prefs.booking);
      return this._filters.include(place, prefsCalc, this._prefs.unit);
    });
    this._filteredPlaces$.next(filteredPlaces);
  }
}
