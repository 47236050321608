// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
}

ion-grid {
  background-color: var(--ion-color-light);
}

ion-button {
  height: 48px;
}
ion-button.text-mlarge {
  --padding-bottom: 12px;
}
ion-button[size=large] {
  --padding-bottom: 12px;
}

div.dates-cost-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.dates-cost-container div.cost-slot-container {
  display: flex;
  flex-direction: row;
}
div.dates-cost-container div.cost-slot-container div.cost-container,
div.dates-cost-container div.cost-slot-container div.slot-container {
  display: flex;
  flex-direction: column;
}

div.booking-cost-container {
  display: flex;
  flex-direction: column;
}

div.left-container {
  min-width: 64px;
}

div.left-bottom-container {
  width: 100%;
}

@media (min-width: 428px) {
  ion-grid.m-padding {
    padding: 16px 24px !important;
  }
}
@media (min-width: 678px) {
  ion-grid.m-padding {
    padding: 16px 32px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/booking-bar/booking-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,WAAA;EACA,UAAA;AACF;;AAEA;EACE,wCAAA;AACF;;AAEA;EACE,YAAA;AACF;AACE;EACE,sBAAA;AACJ;AAEE;EACE,sBAAA;AAAJ;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAFF;AAIE;EACE,aAAA;EACA,mBAAA;AAFJ;AAII;;EAEE,aAAA;EACA,sBAAA;AAFN;;AAOA;EACE,aAAA;EACA,sBAAA;AAJF;;AAOA;EACE,eAAA;AAJF;;AAMA;EACE,WAAA;AAHF;;AAMA;EACE;IACE,6BAAA;EAHF;AACF;AAMA;EACE;IACE,6BAAA;EAJF;AACF","sourcesContent":[":host {\n  display: block;\n  margin: 0 auto;\n  width: 100%;\n  z-index: 1;\n}\n\nion-grid {\n  background-color: var(--ion-color-light);\n}\n\nion-button {\n  height: 48px;\n\n  &.text-mlarge {\n    --padding-bottom: 12px;\n  }\n  \n  &[size=\"large\"] {\n    --padding-bottom: 12px;\n\n  }\n}\n\ndiv.dates-cost-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  div.cost-slot-container {\n    display: flex;\n    flex-direction: row;\n\n    div.cost-container,\n    div.slot-container {\n      display: flex;\n      flex-direction: column;\n    }\n  }\n}\n\ndiv.booking-cost-container {\n  display: flex;\n  flex-direction: column;\n}\n\ndiv.left-container {\n  min-width: 64px;\n}\ndiv.left-bottom-container {\n  width: 100%;\n}\n\n@media (min-width: 428px) {\n  ion-grid.m-padding {\n    padding: 16px 24px !important;\n  }\n}\n\n@media (min-width: 678px) {\n  ion-grid.m-padding {\n    padding: 16px 32px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
